import { Table } from "antd";
import React, { useState } from "react";
import FullScreenLoader from "../../../../components/loader/FullScreenLoader";
import { decryptData } from "../../../../utils/localStorageEncodeDecode";
import installationTableData from "./installationTableData.js";

const SLAB_BASED = 3;
const SINGLE_TARGET = 8;
const SINGLE_TARGET_WITH_UPPER_CAP = 4;
const UNCONDITIONAL_STATEMENT = 5;

const InstallationTable = ({
  selectedSchemeType,
  loading,
  dataSource,
  grandTotal,
  onSendEmail,
  onSelectedRows,
  handleChange,
}) => {
  const userInfo = decryptData(sessionStorage.getItem("userInfo"));
  const userRole = userInfo.userRole;

  const [order, setOrder] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");


  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    if (order == "") {
      setOrder("desc");
      setOrderBy(property);
      return;
    }
    if (order == "desc") {
      setOrder("asc");
      setOrderBy(property);
      return;
    }
    if (order == "asc") {
      setOrder("");
      setOrderBy("");
      return;
    }
  };

  const visibleRows = stableSort(dataSource, getComparator(order, orderBy));

  const columns = installationTableData(
    "Dealer wise Dashboard",
    userRole,
    "dashboard",
    handleRequestSort,
    selectedSchemeType,
  );

  const getSum =
    grandTotal &&
    Object.values(grandTotal).reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);

  const getValue = (field) => (getSum == 0 ? "" : field);

  const colsToBeadded = visibleRows.map((item) => ({
    p1: item?.p1,
    p2: item?.p2,
    p3: item?.p3,
    t1: item?.t1,
    t2: item?.t2,
    t3: item?.t3,
    r1: item?.r1,
    r2: item?.r2,
    r3: item?.r3,
    actual_Installation_Last_Year_Month:
      item?.actual_Installation_Last_Year_Month,
    installationTarget: item?.installationTarget,
    incPerTractor: item?.incPerTarget,
    payout: item?.p1,
    upperCap: item?.upperCap,
  }));

  const pageTotal = colsToBeadded.reduce((acc, obj) => {
    for (const key in obj) {
      if (acc.hasOwnProperty(key)) {
        acc[key] += obj[key];
      } else {
        acc[key] = obj[key];
      }
    }
    return acc;
  }, {});

  return (
    <>

      <Table
        loading={loading ? true : false}
        total={dataSource?.length || 1}
        summary={(pageData) => {
          return (
            <>
              <Table.Summary.Row
                style={{ fontWeight: "500", fontSize: "18px" }}
              >
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                {selectedSchemeType == SLAB_BASED && (
                  <Table.Summary.Cell index={8}></Table.Summary.Cell>
                )}
                <Table.Summary.Cell index={9}>
                  {getValue(pageTotal?.actual_Installation_Last_Year_Month)}
                </Table.Summary.Cell>
                {selectedSchemeType == SLAB_BASED && (
                  <>
                    <Table.Summary.Cell index={10}>
                      {getValue(pageTotal?.t1)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11}>
                      {getValue(pageTotal?.t2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12}>
                      {getValue(pageTotal?.t3)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={13}>
                      {Math.round(
                        getValue(pageTotal?.p1) / getValue(pageTotal?.t1)
                      ) || ""}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={14}>
                      {Math.round(
                        getValue(pageTotal?.p2) / getValue(pageTotal?.t2)
                      ) || ""}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={15}>
                      {Math.round(
                        getValue(pageTotal?.p3) / getValue(pageTotal?.t3)
                      ) || ""}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={16}>
                      {getValue(pageTotal?.p1)}{" "}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={17}>
                      {getValue(pageTotal?.p2)}{" "}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={18}>
                      {getValue(pageTotal?.p3)}{" "}
                    </Table.Summary.Cell>
                  </>
                )}

                {selectedSchemeType == SINGLE_TARGET && (
                  <>
                    <Table.Summary.Cell index={19}>
                      {getValue(pageTotal?.installationTarget)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={20}>
                      {Math.round(
                        getValue(pageTotal?.payout) /
                          getValue(pageTotal?.installationTarget)
                      ) || ""}{" "}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={21}>
                      {getValue(pageTotal?.payout)}
                    </Table.Summary.Cell>
                  </>
                )}

                {selectedSchemeType == SINGLE_TARGET_WITH_UPPER_CAP && (
                  <>
                    <Table.Summary.Cell index={22}>
                      {getValue(pageTotal?.installationTarget)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={23}>
                      {getValue(pageTotal?.upperCap)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={24}>
                      {Math.round(
                        getValue(pageTotal?.payout) /
                          getValue(pageTotal?.installationTarget)
                      ) || ""}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={25}>
                      {getValue(pageTotal?.payout)}
                    </Table.Summary.Cell>
                  </>
                )}

                {selectedSchemeType == UNCONDITIONAL_STATEMENT && (
                  <>
                    <Table.Summary.Cell index={26}>
                      {getValue(pageTotal?.installationTarget)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={27}>
                      {Math.round(
                        getValue(pageTotal?.p1) /
                          getValue(pageTotal?.installationTarget)
                      ) || ""}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={28}>
                      {getValue(pageTotal?.p1)}
                    </Table.Summary.Cell>
                  </>
                )}

                <Table.Summary.Cell index={29}></Table.Summary.Cell>
                {userRole == "SADM" && <Table.Summary.Cell index={30} />}
              </Table.Summary.Row>

            </>
          );
        }}

        columns={columns}
        onChange={handleChange}
        pagination={false}
        dataSource={visibleRows.map((item, ind) => ({ key: ind, ...item }))}
        scroll={{
          x: 2000,
          y: 1000,
        }}
      />
    </>
  );
};

export default InstallationTable;
