export const BASE_API_PATH = process.env.REACT_APP_BASE_API_PATH;
export const ENV_TIME = process.env.REACT_APP_ENV_TYPE_TIME||"false";
export const ENV_TYPE = process.env.REACT_APP_ENV_TYPE_PROD||"false";
export const STATE_WISE_DASHBOARD_LABEL = "Dealer wise Dashboard";
export const STATE_WISE_DASHBOARD = "State wise Dashboard";
export const CREATE_SCHEME_ROLES = ["ZADM", "AMGR", "SADM"];
  // Check if file header is valid for XLSX files
export const isValidFileHeader = (file) => {
    const validHeaders = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    return validHeaders.includes(file.type);
  };
  
  // Check if content type is valid for XLSX files
export const isValidContentType = (file) => {
    const validContentTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    return validContentTypes.includes(file.type);
  };
  
export const ALLOWED_PATHS = {
  AMGR: [
    "/dashboard",
    "/collection/allSchemes",
    "/collection/createNewScheme",
    "/collection/editScheme",
    "/collection/draftSchemes",
    "/collection/draftSchemes/edit",
    "/collection/allSchemes/edit",
    "/collection/schemeStatus",
    "/installation/allSchemes",
    "/installation/createNewScheme",
    "/installation/editScheme",
    "/installation/allSchemes/edit",
    "/installation/draftSchemes",
    "/installation/draftSchemes/edit",
    "/track",
    "/trackinstallation",
    "/modificationCollection",
    "/editModification",
    "/newScheme"
  ],
  SADM: [
    "/dashboard",
    "/collection/allSchemes",
    "/collection/createNewScheme",
    "/collection/editScheme",
    "/collection/draftSchemes",
    "/collection/draftSchemes/edit",
    "/collection/allSchemes/edit",
    "/collection/schemeStatus",
    "/schemeProgress",
    "/approvedschemeslog",
    "/schemeProgress/edit",
    "/installation/allSchemes",
    "/installation/createNewScheme",
    "/installation/editScheme",
    "/installation/allSchemes/edit",
    "/installation/draftSchemes",
    "/installation/draftSchemes/edit",
    // "/installation/awaitingApproval/edit",
    "/track",
    "/trackinstallation",
    "/modificationRequest",
    "/modificationCollection",
    "/installationModification",
    "/trackModification",
    "/editModification",
    "/editApprovalModification",
    "/newScheme"

  ],
  ZADM: [
    "/dashboard",
    "/collection/allSchemes",
    "/collection/createNewScheme",
    "/collection/editScheme",
    "/collection/draftSchemes",
    "/collection/draftSchemes/edit",
    "/collection/allSchemes/edit",
    "/collection/schemeStatus",
    "/schemeProgress",
    "/approvedschemeslog",
    "/schemeProgress/edit",
    "/installation/allSchemes",
    "/installation/createNewScheme",
    "/installation/editScheme",
    "/installation/allSchemes/edit",
    "/installation/draftSchemes",
    "/installation/draftSchemes/edit",
    // "/installation/awaitingApproval/edit",
    "/requestDeviation",
    "/requestDeviationLog",
    "/track",
    "/trackinstallation",
    "/modificationRequest",
    "/modificationCollection",
    "/installationModification",
    "/modificationAwatingApproval",
    "/awatingLogsModification",
    "/trackModification",
    "/editModification",
    "/editApprovalModification",
    "/holdApproval",
    "/newScheme"

  ],
  NSH: [
    "/dashboard",
    "/schemeProgress",
    "/approvedschemeslog",
    "/schemeProgress/edit",
    "/requestDeviation",
    "/requestDeviationLog",
    "/modificationRequest",
    "/modificationCollection",
    "/installationModification",
    "/modificationAwatingApproval",
    "/track",
    "/trackinstallation",
    "/awatingLogsModification",
    "/trackModification",
    "/editModification",
    "/holdApproval",
    "/newScheme",
    "/output-report",
  ],
  PMIS: [
    "/dashboard",
    "/requestDeviation",
    "/requestDeviationLog",
    "/modificationRequest",
    "/modificationCollection",
    "/track",
    "/trackinstallation",
    "/trackModification",
    "/merge",
    "/WithdrawalScheme",
    "/newScheme",
    "/output-report",
  ],
  COSM: [
    "/dashboard",
    "/requestDeviation",
    "/requestDeviationLog",
    "/modificationRequest",
    "/track",
    "/trackinstallation",
    "/trackModification",
    "/newScheme",
    "/output-report",
  ],
  Finance: [
    "/dashboard",
    "/generate-finance",
    "/modificationRequest",
    "/modificationCollection",
    "/track",
    "/trackinstallation",
    "/trackModification",
    "/newScheme",
    "/output-report",
  ],
};
