import { DatePicker, Form, Pagination, Select, Space, Table } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decryptData } from "../../utils/localStorageEncodeDecode";
import {
  getStateListApi,
  GetStatesbyZonalHeadIdApi,
  getSummaryReportApi,
} from "../../redux/stateData/stateDataSlice";
import dayjs from "dayjs";
import XLSX from "xlsx-js-style";
import { ReactComponent as DownArrow } from "../../assets/vector/downArrow.svg";
import FullScreenLoader from "../../components/loader/FullScreenLoader";
import ReportColorDetails from "../../components/table/ReportColorDetails";

const { RangePicker } = DatePicker;
const InitCollectionData = {
  currentYear: dayjs().year(),
  schemeType: 1,
  month: "",
  monthEnd: "",
  currentMonth: 0,
  selectedState: "",
  dealerLocation: "",
  schemeNumber: "",
  dealerCode: "",
  pageSize: "150",
  pageNumber: 1,
  sortingOrder: "asc",
  sortingType: "DealerCode",
};

const OutputReport = () => {
  const { Option } = Select;
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [dateValue, setDateValue] = useState(dayjs());
  const [collectionData, setCollectionData] = useState(InitCollectionData);

  const { userRole, userId } = decryptData(sessionStorage.getItem("userInfo"));

  const { stateList, reportData, loadingReport } = useSelector(
    (state) => state.stateData
  );
  console.log(reportData, "reportDatareportDatareportData");
  const dispatch = useDispatch();
  const getCellClass = (otherValues) => {
    if (otherValues?.perTrCollSameMonth <= otherValues?.sameMonth90)
      return "low-value";
    if (otherValues?.perTrCollSameMonth >= otherValues?.sameMonth110)
      return "high-value";
    return "";
  };

  const getCellClass1 = (otherValues) => {
    if (otherValues?.perTrCollLastMonth <= otherValues?.lastMonth90)
      return "low-value";
    if (otherValues?.perTrCollLastMonth >= otherValues?.lastMonth110)
      return "high-value";
    return "";
  };

  const getCellClass2 = (otherValues) => {
    if (otherValues?.perTrCollSecondLastMonth <= otherValues?.secondLastMonth90)
      return "low-value";
    if (
      otherValues?.perTrCollSecondLastMonth >= otherValues?.secondLastMonth110
    )
      return "high-value";
    return "";
  };

  const defaultColumns = useMemo(
    () => [
      {
        title: "Dealer Code",
        dataIndex: "dealerCode",
      },
      {
        title: "Dealer Name",
        dataIndex: "dealerName",
      },
      {
        title: "Per Tractor - Collection Incentive",
        dataIndex: "collection-incentive",
        children: [
          {
            title: "Scheme Month",
            dataIndex: "perTrCollSameMonth",
            onCell: (record, otherValues) => ({
              className: getCellClass(record),
            }),
          },
          {
            title: "Scheme Month-1",
            dataIndex: "perTrCollLastMonth",
            onCell: (record, otherValues) => ({
              className: getCellClass1(record),
            }),
          },
          {
            title: "Scheme Month -2",
            dataIndex: "perTrCollSecondLastMonth",
            onCell: (record, otherValues) => ({
              className: getCellClass2(record),
            }),
          },
        ],
      },
      {
        title: "Slab Archived - Collection Incentive",
        dataIndex: "collection-incentive",
        children: [
          {
            title: "Scheme Month",
            dataIndex: "achievedSlabSameMonth",
            render: (value) => value || "No Slab Achieved",
          },
          {
            title: "Scheme Month-1",
            dataIndex: "achievedSlabLastMonth",
            render: (value) => value || "No Slab Achieved",
          },
          {
            title: "Scheme Month -2",
            dataIndex: "achievedSlabSecondLastMonth",
            render: (value) => value || "No Slab Achieved",
          },
        ],
      },
      {
        title:
          "Per Tractor- State as Unit -Collec Archived - Collection Incentive",
        dataIndex: "collection-incentive",
        children: [
          {
            title: "Scheme Month",
            dataIndex: "perTrSameMonth",
          },
          {
            title: "Scheme Month-1",
            dataIndex: "perTrLastMonth",
          },
          {
            title: "Scheme Month -2",
            dataIndex: "perTrSecondLastMonth",
          },
        ],
      },
      // {
      //   title: "Scheme month @ state",
      //   dataIndex: "collection-incentive",
      //   children: [
      //     {
      //       title: "90%",
      //       dataIndex: "sameMonth90",
      //     },
      //     {
      //       title: "110%",
      //       dataIndex: "sameMonth110",
      //     },
      //   ],
      // },
      // {
      //   title: "Scheme month -1@ state",
      //   dataIndex: "collection-incentive",
      //   children: [
      //     {
      //       title: "90%",
      //       dataIndex: "lastMonth90",
      //     },
      //     {
      //       title: "110%",
      //       dataIndex: "lastMonth110",
      //     },
      //   ],
      // },
      // {
      //   title: "Scheme month -2@ state",
      //   dataIndex: "collection-incentive",
      //   children: [
      //     {
      //       title: "90%",
      //       dataIndex: "secondLastMonth90",
      //     },
      //     {
      //       title: "110%",
      //       dataIndex: "secondLastMonth110",
      //     },
      //   ],
      // },
      // {
      //   title: "Market Share (From  SDBp portal)",
      //   dataIndex: "collection-incentive",
      //   children: [
      //     {
      //       title: "Scheme Month-1",
      //       dataIndex: "scheme-Month",
      //     },
      //     {
      //       title: "Scheme Month-2",
      //       dataIndex: "scheme-Month",
      //     },
      //     {
      //       title: "YTD Till Date",
      //       dataIndex: "scheme-Month",
      //     },
      //   ],
      // },
    ],
    [reportData?.data?.lstitems1] // Dependencies array ensures memoization
  );

  const headerRowStyle = useMemo(
    () => ({
      style: {
        height: "50px", // Adjust header height
        backgroundColor: "#f5f5f5", // Change background color
        fontWeight: "bold", // Make text bold
        fontSize: "14px", // Adjust font size
        textAlign: "center", // Center-align text
      },
    }),
    [] // Memoize header styles
  );
  const tableScrollConfig = useMemo(() => {
    const windowHeight = window.innerHeight;
    const dynamicHeight = Math.min(windowHeight * 0.8, 600); // 80% of viewport height, max 600px
    return {
      x: 3000,
      y: dynamicHeight,
    };
  }, []);
  useEffect(() => {
    dispatch(getStateListApi());
  }, [dateValue]);

  useEffect(() => {
    if (
      Array?.isArray(stateList?.data) &&
      stateList?.data?.length &&
      selectedStateId &&
      collectionData?.month
    ) {
      dispatch(
        getSummaryReportApi({
          fyear: "FY" + dateValue.format("YY"),
          userid: userId,
          stateId:
            stateList.data?.filter(
              (data) => data?.stateName == selectedStateId
            )[0]?.stateID || "",
          userRole: userRole,
          month: dayjs(collectionData?.month).format("MM"),
          monthEnd: dayjs(collectionData?.month).format("MM"),
          pageSize: collectionData.pageSize,
          pageNumber: collectionData.pageNumber,
          sortingOrder: collectionData.sortingOrder,
          sortingType: collectionData.sortingType,
        })
      );
    }
  }, [collectionData, selectedStateId]);
  const disabledDate = (current) => {
    const today = dayjs().year(dateValue.year() - 1);
    const currentYear = today.year();
    const startFinancialYear = new Date(currentYear, 3, 1);
    const endFinancialYear = new Date(currentYear + 1, 2, 31);
    const currentDate = current instanceof Date ? current : new Date(current);
    return currentDate < startFinancialYear || currentDate > endFinancialYear;
  };

  const handleDownload = () => {
    const getCellClass = (otherValues) => {
      if (otherValues?.perTrCollSameMonth <= otherValues?.sameMonth90)
        return "ffecd2";
      if (otherValues?.perTrCollSameMonth >= otherValues?.sameMonth110)
        return "ff9696";
      return "";
    };

    let tab_text = `<table class="custom-table">
      <thead >
      <tr >
          <th rowspan="2">Dealer Code</th>
          <th rowspan="2">Dealer Name</th>
          <th colspan="3">Per Tractor - Collection Incentive</th>
          <th colspan="3">Slab Archived - Collection Incentive</th>
          <th colspan="3">Per Tractor- State as Unit -Collec Archived - Collection Incentive</th>
      </tr>
      <tr>
          <th>Scheme Month</th>
          <th>Scheme Month-1</th>
          <th>Scheme Month-2</th>
          <th>Scheme Month</th>
          <th>Scheme Month-1</th>
          <th>Scheme Month-2</th>
          <th>Scheme Month</th>
          <th>Scheme Month-1</th>
          <th>Scheme Month-2</th>
       </tr>
      </thead>`;

    const rows = [];
    reportData?.data?.lstitems1?.forEach((header) => {
      rows.push([
        header?.dealerCode || "",
        header?.dealerName || "",
        header?.perTrCollSameMonth || "0",
        header?.perTrCollLastMonth || "0",
        header?.perTrCollSecondLastMonth || "0",
        header?.achievedSlabSameMonth || "No Slab Achieved",
        header?.achievedSlabLastMonth || "No Slab Achieved",
        header?.achievedSlabSecondLastMonth || "No Slab Achieved",
        header?.perTrSameMonth || "0",
        header?.perTrLastMonth || "0",
        header?.perTrSecondLastMonth || "0",
      ]);
    });

    rows.forEach((row) => {
      tab_text += `<tr >
        <td>${row[0]}</td>
        <td>${row[1]}</td>
        <td class="${getCellClass({
          perTrCollSameMonth: row[2],
          sameMonth90: 90,
          sameMonth110: 110,
        })}">${row[2]}</td>
        <td class="${getCellClass({
          perTrCollSameMonth: row[3],
          sameMonth90: 90,
          sameMonth110: 110,
        })}">${row[3]}</td>
        <td class="${getCellClass({
          perTrCollSameMonth: row[4],
          sameMonth90: 90,
          sameMonth110: 110,
        })}">${row[4]}</td>
        <td>${row[5]}</td>
        <td>${row[6]}</td>
        <td>${row[7]}</td>
        <td>${row[8]}</td>
        <td>${row[9]}</td>
        <td>${row[10]}</td>
      </tr>`;
    });

    tab_text += "</table>";
    var elt = document.createElement("div");
    elt.innerHTML = tab_text;
    document.body.appendChild(elt);

    // Calculate max width for each column dynamically based on content
    const tbl = elt.getElementsByTagName("TABLE")[0];
    const colsWidth = [];

    // Loop through each column and find the maximum content length
    Array.from(tbl.rows).forEach((row) => {
      Array.from(row.cells).forEach((cell, idx) => {
        const cellLength = (cell.innerText || cell.textContent).length;
        if (!colsWidth[idx] || colsWidth[idx] < cellLength) {
          colsWidth[idx] = cellLength;
        }
      });
    });
    // Define headerStyle for cell styling
    const headerStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
      border: {
        top: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } },
        diagonal: { diagonalUp: true, diagonalDown: true },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    // Adjust column widths dynamically
    const columnWidths = colsWidth.map((length) => ({ wch: 20 }));

    // Apply dynamic column widths to the workbook
    const wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
    wb.Sheets[wb.SheetNames[0]]["!cols"] = columnWidths;
    // Apply headerStyle to all cells
    // Object.keys(wb?.Sheets?.Sheet1 || {})
    //   .filter(
    //     (x) =>
    //       x !== "!rows" &&
    //       x !== "!cols" &&
    //       x !== "!merges" &&
    //       x !== "!ref" &&
    //       x !== "!fullref"
    //   )
    //   .forEach((x) => {
    //     wb.Sheets[wb.SheetNames[0]][x]["s"] = headerStyle;
    //   });
    document.body.removeChild(elt);
    let nameFile = "state_wise_report.xlsx";
    // generate file
    XLSX.writeFile(wb, nameFile);
  };
  const formatDate = (date) => {
    return `FY-${dayjs(date).year()}`;
  };
  const disabledDatePicker = (current) => {
    const startYear = dayjs("2024-01-01");
    const endYear = dayjs().add(1, "year").endOf("year");
    return (
      current &&
      (current.isBefore(startYear, "day") || current.isAfter(endYear, "day"))
    );
  };

  return (
    <div>
      <div className="All_scheme_inner p-2">
        <Space className="all_Scheme_header">
          <div className="marginauto">
            <DatePicker
              picker="year"
              bordered={true}
              className="yearPicker"
              suffixIcon={<DownArrow />}
              disabledDate={disabledDatePicker}
              value={dateValue}
              onChange={(data) => {
                const newStartMonth = dayjs(`${data.year() - 1}-04-01`);
                setDateValue(data);
                setCollectionData((prev) => ({
                  ...prev,
                  month: newStartMonth,
                  monthEnd: newStartMonth,
                  pageNumber: 1,
                }));
              }}
              allowClear={false}
              format={formatDate}
              placeholder="Financial Year"
            />
          </div>
          {/* 
          <div className="month">
            <RangePicker
              mode={["month", "month"]}
              dateValue={dateValue}
              picker="month"
              onChange={(data) => {
                setCollectionData((prev) => ({
                  ...prev,
                  month: data && data[0] ? data[0].format("YYYY-MM") : null,
                  monthEnd: data && data[1] ? data[1].format("YYYY-MM") : null,
                  pageNumber: 1,
                }));
              }}
              value={
                collectionData.month && collectionData.monthEnd
                  ? [
                      dayjs(collectionData.month, "YYYY-MM"),
                      dayjs(collectionData.monthEnd, "YYYY-MM"),
                    ]
                  : null
              }
              disabled={collectionData.currentMonth != ""}
              disabledDate={disabledDate}
            />
          </div> */}
          <div className="month">
            <DatePicker
              onChange={(date) => {
                console.log(
                  date,
                  "datedatedate",
                  date ? dayjs(date).format("YYYY-MM") : null
                );
                setCollectionData((prev) => ({
                  ...prev,
                  month: date ? dayjs(date).format("YYYY-MM") : null,
                  // monthEnd: date ? dayjs(date).format("YYYY-MM") : null,
                  pageNumber: 1,
                }));
              }}
              className="start"
              placeholder="Running Month"
              picker="month"
              value={dayjs(collectionData.month || dateValue)}
              allowClear={false}
              disabledDate={disabledDate}
              // disabled={
              //   monthlyFilters.month != 0 || monthlyFilters.monthEnd != 0
              // }
            />

            {/* <RangePicker
              mode={["month", "month"]}
              dateValue={dateValue}
              picker="month"
              onChange={(data) => {
                setCollectionData((prev) => ({
                  ...prev,
                  month: data && data[0] ? data[0].format("YYYY-MM") : null,
                  monthEnd: data && data[1] ? data[1].format("YYYY-MM") : null,
                  pageNumber: 1,
                }));
              }}
              value={
                collectionData.month && collectionData.monthEnd
                  ? [
                      dayjs(collectionData.month, "YYYY-MM"),
                      dayjs(collectionData.monthEnd, "YYYY-MM"),
                    ]
                  : null
              }
              disabled={collectionData.currentMonth != ""}
              disabledDate={disabledDate}
            /> */}
          </div>
          <Select
            showSearch
            placeholder="Select State"
            optionFilterProp="children"
            onSelect={(data) => {
              setSelectedStateId(data);
            }}
            defaultValue={0}
            allowClear={false}
            value={selectedStateId}
            size="large"
          >
            {Array?.isArray(stateList?.data) &&
              stateList?.data?.map((option) => (
                <Option key={option.stateID} value={option.stateName}>
                  {option.stateName}
                </Option>
              ))}
          </Select>
          <button
            className="action_button c-white mx-1"
            disabled={!reportData?.data?.lstitems1?.length ? true : false}
            onClick={handleDownload}
          >
            Download
          </button>
        </Space>
      </div>

      <Form className="bg-white p-2">
        <Table
          // showSorterTooltip={false}
          // className="header"
          columns={defaultColumns}
          loading={loadingReport == "pending" ? true : false}
          dataSource={reportData?.data?.lstitems1 || []}
          bordered
          pagination={false}
          scroll={tableScrollConfig}
          size="large"
          // className="custom-table"
          // rowClassName={() => "no-hover"}
          styles={{
            rowHoverBg: "red",
            rowSelectedBg: "red",
            rowSelectedHoverBg: "red",
          }}
          onRow={() => ({
            onMouseEnter: (event) => event.stopPropagation(), // Prevent hover effects
            onMouseLeave: (event) => event.stopPropagation(), // Prevent hover effects
          })}
          onHeaderRow={() => headerRowStyle}
        />
        {loadingReport == "pending" && <FullScreenLoader />}
        <div className="d-flex justify-content-between align-items-center flex-row-reverse px-2">
          <Pagination
            className="paginationDesign"
            defaultCurrent={1}
            current={collectionData.pageNumber}
            onChange={(pageNumber, pageSize) =>
              setCollectionData({ ...collectionData, pageSize, pageNumber })
            }
            total={reportData?.data?.totalcount}
            showSizeChanger
            pageSize={collectionData.pageSize}
            pageSizeOptions={["150", "250", "350"]}
          />
          <ReportColorDetails />
        </div>
      </Form>
    </div>
  );
};
export default OutputReport;
