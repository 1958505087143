import { EyeOutlined } from "@ant-design/icons";
import { Tag, Space, Button } from "antd";
import dayjs from "dayjs";
import { TextLocalString } from "../../../../components/ToastifyShow";
import { render } from "@testing-library/react";

const SLAB_BASED = 3;
const SINGLE_TARGET = 8;
const SINGLE_TARGET_WITH_UPPER_CAP = 4;
const UNCONDITIONAL_STATEMENT = 5;

const installationTableData = (dashboardType, userRole, page, onHandleSort, selectedSchemeType) => {
  const getCols = (schemeType) => {
    if (schemeType == SLAB_BASED) {
      return [
        {
          title: "Targets",
          dataIndex: "TEP",
          width: "100px",
          children: [
            {
              title: "T1",
              dataIndex: "t1",
              width: "70px",
                render:(x,y)=>{
                  return(
                    !y?.topTarget?.split(",")?.includes("t1")?
                      <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
                  )
                }
              
            },
            {
              title: "T2",
              dataIndex: "t2",
              width: "70px",
              

              render:(x,y)=>{
                return(
                  !y?.topTarget?.split(",")?.includes("t2")?
                    <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
                )
              }
            },
            {
              title: "T3",
              dataIndex: "t3",
              width: "70px",


              render:(x,y)=>{
                return(
                  !y?.topTarget?.split(",")?.includes("t3")?
                    <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
                )
              }
            },
          ],
        },
        {
          title: "Inc. per Tractor",
          dataIndex: "TEP",
          width: "100px",
          children: [
            {
              title: "r1",
              dataIndex: "r1",
              width: "70px",

              render:(x,y)=>{
                return(
                  !y?.topTarget?.split(",")?.includes("t1")?
                    <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
                )
              }
            },
            {
              title: "r2",
              dataIndex: "r2",
              width: "70px",

              render:(x,y)=>{
                return(
                  !y?.topTarget?.split(",")?.includes("t2")?
                    <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
                )
              }
            },
            {
              title: "r3",
              dataIndex: "r3",
              width: "70px",

              render:(x,y)=>{
                return(
                  !y?.topTarget?.split(",")?.includes("t3")?
                    <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
                )
              }
            },
          ],
        },
        {
          title: "Payout",
          dataIndex: "TEP",
          width: "100px",
          children: [
            {
              title: "p1",
              dataIndex: "p1",
              width: "70px",

              render:(x,y)=>{
                return(
                  !y?.topTarget?.split(",")?.includes("t1")?
                    <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
                )
              }
            },
            {
              title: "p2",
              dataIndex: "p2",
              width: "70px",

              render:(x,y)=>{
                return(
                  !y?.topTarget?.split(",")?.includes("t2")?
                    <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
                )
              }
            },
            {
              title: "p3",
              dataIndex: "p3",
              width: "70px",

              render:(x,y)=>{
                return(
                  !y?.topTarget?.split(",")?.includes("t3")?
                    <div className="h-24 input-disabled"></div>:<div >{TextLocalString(x,true)}</div>
                )
              }
            },
          ],
        },
      ]
    }
    if (schemeType == SINGLE_TARGET) {
      return [
        {
          title: "INSTALLATION TARGET",
          dataIndex: "installationTarget",
          width: "100px",
          render:(x)=>TextLocalString(x)
        },
        {
          title: "INC. PER TRACTOR",
          dataIndex: "incPerTarget",
          width: "100px",  render:(x)=>TextLocalString(x)
        },
        {
          title: "PAYOUT",
          dataIndex: "p1",
          width: "90px",  render:(x)=>TextLocalString(x)
        },
      ]
    }
    if (schemeType == SINGLE_TARGET_WITH_UPPER_CAP) {
      return [
        {
          title: "INSTALLATION TARGET",
          dataIndex: "installationTarget",
          width: "100px",  render:(x)=>TextLocalString(x)
        },
        {
          title: "UPPER CAP",
          dataIndex: "upperCap",
          width: "90px",  render:(x)=>TextLocalString(x)
        },
        {
          title: "INC. PER TRACTOR",
          dataIndex: "incPerTarget",
          width: "100px",  render:(x)=>TextLocalString(x)
        },
        {
          title: "PAYOUT",
          dataIndex: "p1",
          width: "90px",  render:(x)=>TextLocalString(x)
        },
      ]
    }else {
      return [
        {
          title: "INSTALLATION EXPECTED",
          dataIndex: "installationTarget",
          width: "100px",  render:(x)=>TextLocalString(x)
        },
        {
          title: "INC. PER TRACTOR",
          dataIndex: "incPerTarget",
          width: "100px",  render:(x)=>TextLocalString(x)
        },
        {
          title: "PAYOUT",
          dataIndex: "p1",
          width: "90px",  render:(x)=>TextLocalString(x)
        },
      ]
    }
  }

  let TopSlabShow=(x)=>x == SLAB_BASED?[{
    title: (
      <div>
        TOP SLAB
      </div>
    ),
    dataIndex: "topTarget",
    width: "80px",
    // sorter: true,
    defaultSortOrder: "ascend",
    key: "topTarget",
    render:(x)=>x?.toUpperCase()||""
  }]:[]
  let columnsList = [
    {
      title: (
        <div>
          {/* onClick={() => onHandleSort('schemeNo')} */}
          Scheme <br /> No
        </div>
      ),
      dataIndex: "schemeNo",
      width: "120px",
      fixed: "left",
      sorter: true,
      key: "SchemeNo",
      // sortDirections:['ascend', 'descend'],
      defaultSortOrder: "ascend",
      render: (_, item) => {
        //schemeTypeId
        let color = " ";
        const dataItem=item?.holdStatus==9?item?.holdStatus:item.status
        switch (dataItem) {
          case 0:
            color = "#FCE838";
            break;
            // #Inprogress
          case 1:
            color = "#FCE838";
            break;
            // #Approved
          case 2:
            color = "#51DE4E";
            break;
              // #Withdraw
          case 3:
            color = "#FFAE00";
            break;
              // #Cancel
          case 4:
            color = "#FF3232";
            break;
             // #Reject
          case 5:
            color = "#FF3232";
            break;
             //Deviation
          case 7:
              color = "#bcb38d";
              break;
             // #AutoRejected;
         case 8:
              color = "rgb(255, 0, 255)";
              break;
              case 9:
                color = "#9999ff"; //hold
                break;
          default:
            color = "#FCE838";
            break;
        }
        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={color}
          >
            {item?.schemeNo}
          </Tag>
        );
      },
    },
    {
      title: (
        <div onClick={() => onHandleSort('dealerCode')}>
          Dealer <br /> Code
        </div>
      ),
      dataIndex: "dealerCode",
      width: "130px",
      fixed: "left",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerCode",
    },
    {
      title: (
        <div onClick={() => onHandleSort('dealerLocation')}>
          Dealer <br /> Location
        </div>
      ),
      dataIndex: "dealerLocation",
      fixed: "left",
      width: "150px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerLocation",
    },
    {
      title: (
        <div onClick={() => onHandleSort('dealerName')}>
          Dealer <br /> Name
        </div>
      ),
      dataIndex: "dealerName",
      width: "130px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerName"
    },
    {
      title: "State",
      dataIndex: "stateName",
      width: "90px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "StateName",
    },
    {
      title: (
        <div>
          Territory <br /> Manager
        </div>
      ),
      dataIndex: "territoryManager",
      width: "150px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "TerritoryManager",
    },
   ...TopSlabShow(selectedSchemeType),

    {
      title: (
        <div>
          Start <br /> Month
        </div>
      ),
      dataIndex: "startMonth",
      width: "80px",
      render: (_, item) => dayjs(item.startMonth).format("MMM'YY")
    },
    {
      title: (
        <div>
          End <br /> Month
        </div>
      ),
      dataIndex: "endMonth",
      width: "70px",
      render: (_, item) => dayjs(item.endMonth).format("MMM'YY")
    },
    {
      title: "Actual Installation Last Year Month",
      dataIndex: "actual_Installation_Last_Year_Month",
      width: "150px",
    },
    ...getCols(selectedSchemeType),
    {
      title: "Remarks",
      dataIndex: "reason",
      width: "100px",
    },
    
  ];

  return  columnsList
};

export default installationTableData;
