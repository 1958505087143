import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Alert from "@mui/material/Alert";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./login.css";
import loginImage from "../../assets/images/login.png";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, logout } from "../../redux/authUser/authSlice";
import { useNavigate } from "react-router-dom";
import { ReactComponent as HideShow } from "../../assets/vector/hideShow.svg";
import Link from "@mui/material/Link";
import { message } from "antd";
import FullScreenLoader from "../../components/loader/FullScreenLoader";
import ToastifyShow from "../../components/ToastifyShow";

const theme = createTheme();

export default function SignInSide() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loadingLogin } = useSelector((state) => state.user);

  const data = useSelector((state) => state.user);
  const [Username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loadingShow, setLoadingShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [handleUserName, sethandleUserName] = useState(false);
  const [handlePassword, sethandlePassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Perform logout logic here
      // For example, make an API call to log the user out

      // Assuming you have a logout action in your redux store
      event.preventDefault();
      // Uncomment the next line if you want to show a confirmation message
      event.returnValue = "Are you sure you want to leave?";
      dispatch(logout());
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Cleanup: Remove the event listener when the component is unmounted
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!Username) {
      sethandleUserName(true);
    } else {
      sethandleUserName(false);
    }
    if (!password) {
      sethandlePassword(true);
    } else {
      sethandleUserName(false);
    }
    if (Username && password) {
      try {
        localStorage.clear()
        setLoadingShow(true);
        await dispatch(loginUser({ Username, password }))
          .then((x) => {
            if (x?.error?.message == "Rejected"||x?.payload?.lockOutEnabled == "true") {
              throw x?.payload;
            } else if (x?.payload?.data) {
              navigate("/dashboard");
              setLoadingShow(false);
            }
          })
          .catch((x) => {
            setLoadingShow(false);
            throw x;
          });
        setLoadingShow(false);
      } catch (e) {

        setLoadingShow(false);
        ToastifyShow((e?.lockOutEnabled=="true"||e=="disabled for 5 min")?"You have entered an incorrect username or password more than 3 times. As a security measure, your account has been temporarily locked for 5 minutes. Please try again later.":typeof e == "string" ? e : e?.message,"error")
        // message.warning(e?.lockOutEnabled=="true"?"You have entered an incorrect password more than 3 times. As a security measure, your account has been temporarily locked for 5 minutes. Please try again later.":typeof e == "string" ? e : e?.message);
      }
      // Cannot read properties of undefined (reading 'error')
      setLoadingShow(false);
    }
  };

  const handleInputChange = (e) => {
    const userInput = e.target.value;

    // Use a regular expression or any other validation logic
    const sanitizedInput = userInput.replace(/[^0-9]/g, "");

    setUsername(sanitizedInput);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          className=" home"
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          {loadingShow && <FullScreenLoader />}
          <Box
            className="sw-login-wrapper"
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              className="heading welcomingtext"
              component="h1"
              variant="h5"
            >
              Hi! Welcome
            </Typography>

            <Typography className="h2 welcomingtext-para">
              Please enter your details to access the portal
            </Typography>

            <Box
              className=" box"
              component="form"
              noValidate
              sx={{ mt: 1 }}
              autoComplete="off"
            >
              <TextField
                className="login-text-field token-box"
                margin="normal"
                required
                fullWidth
                id="Username"
                placeholder=" Token Number"
                name="Username"
                //   autoComplete="Username"
                autoComplete="off"
                value={Username}
                // helperText="Please enter the user name."
                onChange={handleInputChange}
                autoFocus
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "58px",
                    width: "458px",
                  },
                }}
              />
              {handleUserName && (
                <Typography
                  sx={{
                    color: "red",
                  }}
                >
                  {" "}
                  please enter the username
                </Typography>
              )}
              <TextField
                className="login-text-field1"
                margin="normal"
                required
                fullWidth
                name="password"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                //   autoComplete="current-password"
                autoComplete="off"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "58px",
                    width: "458px",
                    "&:hover fieldset": {
                      borderColor: "#E4E4E4",
                    },
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <HideShow
                        style={{ cursor: "default" }}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </HideShow>
                    </InputAdornment>
                  ),
                }}
              />
              {handlePassword && (
                <Typography
                  sx={{
                    color: "red",
                  }}
                >
                  {" "}
                  please enter the Password
                </Typography>
              )}
              <Grid container className="remember-wrap">
                <Grid className="remember">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="remember"
                        style={{
                          color: "#D8D8D8",
                        }}
                      />
                    }
                    label="Remember me?"
                  />
                </Grid>

                {/* <Grid item xs className="forget-pass">
                  <Link href="#" variant="body2">
                    Forgot Password
                  </Link>
                </Grid> */}
              </Grid>
              <Grid className="login-btn">
                <Button
                  className="login"
                  type="submit"
                  fullWidth
                  onClick={handleSubmit}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disableElevation
                >
                  LOGIN NOW
                </Button>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${loginImage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
    </ThemeProvider>
  );
}
