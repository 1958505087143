import { React, useCallback, useEffect, useMemo } from "react";
import { useNavigate, Outlet } from 'react-router-dom';
import { ReactComponent as DashboardIcon } from "../../assets/vector/dashboard.svg";
import { ReactComponent as CollectionIcon } from "../../assets/vector/collection.svg";
import { ReactComponent as InstallationIcon } from "../../assets/vector/installation.svg";
import { ReactComponent as Deviation } from "../../assets/vector/deviation.svg";
import { ReactComponent as SwarajIcon } from "../../assets/vector/swarajIcon.svg";
import { ReactComponent as CollapseIcon } from "../../assets/vector/collapseIcon.svg";
import { ReactComponent as ExpandIcon } from "../../assets/vector/expandIcon.svg";
import { ReactComponent as AwaitingIcon } from "../../assets/vector/awating.svg";
import { ReactComponent as InstallationIcon1 } from "../../assets/vector/truck-moving-solid.svg";
import { ReactComponent as MergeIcon } from "../../assets/vector/merge.svg";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import {
    EditOutlined,
} from '@ant-design/icons';
import Swaraj from "../../assets/images/swaraj.jpg";
import { Layout, Menu, Button, theme, Modal, Result, Tooltip } from 'antd';
import { useState } from 'react';
import { Col, Row } from 'antd';
import SchemeHeader from "../header/index.js";
import './index.css';
import dayjs from "dayjs";
import { expiredSADMDate, getExpiredStatusAMGR, getExpiredStatusSADM, getExpiredStatusZADM } from "../header/schemeExpiredDetails";
import { decryptData } from "../../utils/localStorageEncodeDecode";
import OutputReport from "../../pages/output/OutputReport.jsx";
import { getDecryptedSessionData } from "../../utils/protectedRoutes.jsx";

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label:  <Tooltip placement="right" title={label} >{label}</Tooltip>,
        type,
    };
}

// submenu keys of first level
const rootSubmenuKeys = ['dashboard', 'collection', 'installation', 'table', 'modificationCollection', 'installationModification', 'trackModification', 'awatingLogsModification', 'merge'];

const { Header, Sider, Content } = Layout;


const Template = () => {
    const navigate = useNavigate();
    const [openKeys, setOpenKeys] = useState(['dashboard']);
    const [collapsed, setCollapsed] = useState(true);
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { userRole } = getDecryptedSessionData();
    const items = useMemo(()=>[
        getItem('Dashboard', 'dashboard', <DashboardIcon />),
        
        !["NSH", "PMIS", "COSM", "Finance"].includes(userRole) && getItem('Collection', 'collection', <CollectionIcon />, [
            getItem('All Schemes', 'collection/allSchemes'),
            getItem('Create New Scheme', 'collection/createNewScheme'),
            getItem('Draft Schemes', 'collection/draftSchemes'),
        ]),

        !["NSH", "PMIS", "COSM", "Finance"].includes(userRole) && getItem('Installation', 'installation', <InstallationIcon />, [
            getItem('All Schemes', 'installation/allSchemes'),
            getItem('Create New Scheme', 'installation/createNewScheme'),
            getItem('Draft Schemes', 'installation/draftSchemes'),
        ]),
        !["AMGR", "COSM", "Finance", "PMIS"].includes(userRole) && getItem('Scheme Progress', '/schemeProgress', <AwaitingIcon />, [
            getItem('For My Approval', 'schemeProgress'),
            ["NSH", "ZADM", "PMIS", "SADM"].includes(userRole) && getItem('Approved by Me', '/approvedschemeslog'),
        ]),
        getItem('Track - All Schemes', 'Track', <InstallationIcon1 />, [
            getItem('Collection Based', 'track'),
            getItem('Installation Based', 'trackinstallation'),
        ]),
        ["NSH", "PMIS", "Finance","COSM"].includes(userRole) && getItem('Output Report', 'output-report', <DriveFolderUploadIcon />),
        ["NSH", "SADM", "ZADM", "COSM", "Finance", "PMIS"].includes(userRole) && getItem('Modify', 'modificationCollection', <EditOutlined />, [
            !["NSH", "COSM", "Finance", "PMIS"].includes(userRole) && getItem('Collection Based', 'modificationCollection'),
            !["NSH", "COSM", "Finance", "PMIS"].includes(userRole) && getItem('Installation Based', 'installationModification'),
            !["AMGR", "COSM", "Finance", "PMIS", "SADM"].includes(userRole) && getItem('Approve Modification', 'modificationAwatingApproval'),
            // ![ "AMGR", "COSM", "Finance","PMIS", "SADM"].includes(userRole) &&  getItem('Modification Awaiting  ', 'awatingLogsModification'),
            !["AMGR"].includes(userRole) && getItem('Track Modification', 'trackModification'),


        ]),
        // ["NSH","ZADM"].includes(userRole) && getItemP('Awaiting Logs Modification', 'awatingLogsModification', <EditOutlined />, [
        //     getItem('Track Modification', 'trackModification'),

        // ]),
    
        ["NSH","ZADM"].includes(userRole) && getItem('Hold Approval', 'holdApproval', <MoveUpIcon />),
        ["NSH", "ZADM", "PMIS", "COSM"].includes(userRole) && getItem('Deviation Request', 'requestDeviation', <Deviation />, [
            getItem('Deviation Request', 'requestDeviation'),
            ["PMIS", "ZADM", "COSM", "NSH"].includes(userRole) && getItem('Deviation Log', 'requestDeviationLog'),
        ]),
  
        ["Finance"].includes(userRole) && getItem('Input', 'generate-finance', <DashboardIcon />),
        ["PMIS"].includes(userRole) && getItem('Merge/Unmerge', 'merge', <MergeIcon />),
        ["PMIS"].includes(userRole) && getItem('Withdrawal', 'WithdrawalScheme', <PublishedWithChangesIcon />),
        
    ],[userRole])

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    
    const dayExpired = useMemo(() => {
        if (userRole === "SADM") {
            return getExpiredStatusSADM();
        } else if (userRole === "AMGR") {
            return getExpiredStatusAMGR();
        } else {
            return getExpiredStatusZADM();
        }
    }, [userRole]);
    
    const getDefaultSelectedKey = useCallback(
        (pathname) => {
            return items.find((item) => pathname.includes(item.key))?.key || "dashboard";
        },
        [items]
    );
    
    const currentPath = window.location.pathname; // Replace with the actual method to get the current path
    
    const defaultSelectedKey = useMemo(
        () => getDefaultSelectedKey(currentPath),
        [currentPath, getDefaultSelectedKey]
    );
    
    const handleCollapse = () => setCollapsed(!collapsed);
    return (
        <>
            <Row >
                <Col span={4}>
                    <Layout>
                        <Sider trigger={null} collapsible collapsed={collapsed} 
                        // style={{ width: '250px', height: '100%' }}
                        style={{
                            height: '100%',
                            transition: 'width 0.3s ease', // Smooth animation
                        }}    
                        collapsedWidth={80} // Width when collapsed
                        width={250} // Width when expanded
    
                        >
                              {!collapsed ? (
                        <div className="logo_sec">
                            <Button
                                type="text"
                                icon={<CollapseIcon />}
                                onClick={handleCollapse}
                                style={{ position: 'relative' }}
                            />
                            <img src={Swaraj} alt="Swaraj Logo" />
                            <span>Scheme Management System</span>
                        </div>
                    ) : (
                        <div className="logo_sec">
                            <Button
                                type="text"
                                icon={<ExpandIcon />}
                                onClick={handleCollapse}
                                style={{ position: 'relative'}}
                            />
                            <SwarajIcon />
                        </div>
                    )}
                            <Menu
                                className="navigation"
                                onClick={({ key }) => {
                                    if (key === "logOut") {
                                    } else if (dayExpired && (key == "installation/createNewScheme" || key == "collection/createNewScheme")) {
                                        setIsModalOpen(true)
                                    }
                                    else {
                                        navigate(key);
                                    }
                                }}
                                defaultSelectedKeys={defaultSelectedKey}
                                mode="inline"
                                openKeys={openKeys}
                                onOpenChange={onOpenChange}
                                items={items}
                            />
                        </Sider>
                    </Layout>
                </Col>
                <Col span={20}>
                    <div className="dashboard-collapse">
                        <Layout>

                            <Content style={{ padding: 0, background: colorBgContainer, marginLeft: collapsed ? 70 : 245, transition: 'margin 0.2s' }} >
                                <SchemeHeader />
                                <Outlet />
                            </Content>
                        </Layout>
                    </div>
                </Col>
                <Modal
                    title="Scheme Creation Expired"
                    open={isModalOpen}
                    okText="Go Back"
                    onOk={() => { setIsModalOpen(false); navigate("/dashboard") }}
                    okButtonProps={{style: {margin: '0px'}}}
                    cancelButtonProps={{ style: { display: 'none' } }} 
                    closable={false}
                    style={{padding : '0px', marginTop: '-70px', marginBottom: '0px'}}
                    maskClosable={false}
                    destroyOnClose={true}
                >
                    
                    <Result
                        status="500"
                        title="Scheme creation window is expired now"
                        style={{  height: '406px', padding: '0px' , marginTop: '-12px' , marginBottom : '-20px'}}
                    />
                </Modal>
            </Row>

        </>

    )
}

export default Template;
