import React, { useEffect, useState, useContext, useRef } from "react";
import "./InstallationCreateScheme.css";
import { Button, Table, Divider, Col, Row, Form, Input } from "antd";
//import headerComponent from "../createSchemes/HeaderComponent";
// import ThemeButton from "../../../components/button/buttons";
import { DownloadOutlined, SaveOutlined } from "@ant-design/icons";
//import { Avatar, Space, Badge } from "antd";
import { ReactComponent as HideIcon } from "../../../assets/vector/hide.svg";
import { ReactComponent as MergeIcon } from "../../../assets/vector/merge.svg";
import { ReactComponent as UnmergeIcon } from "../../../assets/vector/unmerge.svg";
import { ReactComponent as ChangeScheme } from "../../../assets/vector/changeScheme.svg";
import { ReactComponent as UnHideIcon } from "../../../assets/vector/unHide.svg";
import { useDispatch, useSelector } from "react-redux";
import { installationSchemeList } from "../../../redux/installation/SchemeList";
import ValidationDifferentSchemeError from "../../../components/modal/alertModal/ValidationDifferentSchemeError";
import {
  add,
  fetchgenerateScheme,
} from "../../../redux/installation/PostScheme";
import dayjs from "dayjs";
import ChangeSchemeModal from "../../../components/changeSchemeModal";
import SMDialog from "../../../components/modal/alertModal/CommonModal";
import AlertModal from "../../../components/modal/alertModal/AlertModal";
import StatusModal from "../../../components/modal/alertModal/StatusModal";
import {
  getCurrentFiscalYear,
  getCurrentFiscalYear1,
} from "../../../utils/dateFormatter";
import { slabBasedSchema } from "../../../utils/schemeValidations";
import ValidationErrorsModal from "../../../components/modal/alertModal/ValidationErrorsModal";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
//import type { ColumnsType } from 'antd/es/table'
import { Select } from "antd";

//functional Component Start
import useTableSort from "./useTableSort";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import ToastifyShow from "../../../components/ToastifyShow";

const SlabBasedScheme = ({
  startDate,
  endDate,
  dealerCode,
  allListData,
  schemeType,
  formik,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const EditableContext = React.createContext(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editRow, setEditingRow] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [showHide, setShowUnhide] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [openSchemeModal, setOpenSchemeModal] = useState(false);
  // const [schemeType, setSchemeType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [changeSchemeDate, setchangeSchemeDate] = useState([]);
  const [slabSchemeList, setSlabSchemeList] = useState([]);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [openDraftModal, setOpenDraftModal] = useState(false);
  const [dataHandle, setDataHandle] = useState([]);

  const [dataDraftHandle, setDataDraftHandle] = useState([]);
  const [errorItem, setErrorItem] = useState({
    firstItem: null,
    secondItem: null,
  });
  const [schemeIdNo, setSchemeIdNo] = useState([]);
  const selectedRow = JSON.parse(sessionStorage.getItem("rowData"));
  const schemeIds = selectedRow?.map((schemeKey) => schemeKey.scheme.schemeId);
  const [openErrorDialog, setOpenErrorDialog] = useState({
    open: false,
    errorsFields: [],
  });
  const [submitData1, setSubmitData1] = useState({});
  const currentFiscalYear = getCurrentFiscalYear1(startDate);
  React.useEffect(() => {
    if (slabSchemeList && slabSchemeList.length) {
      const formattedData = formatData(slabSchemeList);
      dispatch(installationSchemeList(formattedData));
    }
  }, [startDate, endDate, dealerCode, slabSchemeList]);

  const handleModal = () => {
    setOpenModal(!openModal);
  };
  const handleErrorModal = (firstItem, secondItem, x) => {
    setOpenErrorModal(x);
    setErrorItem({
      firstItem,
      secondItem,
    });
  };
  const handleSaveModal = () => {
    setOpenSaveModal(!openSaveModal);
  };
  const handleSuccessModal = () => {
    setOpenSuccessModal(!openSuccessModal);
  };
  const handleSelectModal = () => {
    setOpenSelectModal(!openSelectModal);
  };
  const handleDraftModal = () => {
    setOpenDraftModal(!openDraftModal);
  };
  const listData = useSelector((state) => state.installationSchemeList.data);

  /* DISCLAIMER: REMOVE IF THERE IS ANY ISSUE WHILE CHECKBOX SELECTION */
  useEffect(() => {
    if (listData?.length && dealerCode?.length) {
      const selectedKeys = listData
        .filter((listItem) => dealerCode.includes(listItem.code))
        .map((listItem) => listItem.key);
      setSelectedRowKeys(selectedKeys);
    }
  }, [dealerCode, listData]);

  const { lockedStates } = useSelector((state) => state?.stateData);
  const schemeLockedStatus = lockedStates?.[0]
    ? {
        t1: true,
        t2: true,
        t3: lockedStates?.[0]?.t3I ? true : false,
      }
    : {
        t1: true,
        t2: true,
        t3: false,
      };

  const topTargetArr = [
    {
      name: "T1",
      value: "t1",
      disabled: !schemeLockedStatus?.t1 ? true : false,
    },
    {
      name: "T2",
      value: "t2",
      disabled: !schemeLockedStatus?.t2 ? true : false,
    },
    {
      name: "T3",
      value: "t3",
      disabled: !schemeLockedStatus?.t3 ? true : false,
    },
  ];

  const getDropdown = () =>
    schemeLockedStatus?.t1
      ? [
          {
            title: "Top Slab",
            width: "150px",
            render: (x, y, index) => {
              const handleChange = (value) => {
                let dropdownValues = formik?.values?.[index]?.dropdownscheme;
                let checkValue1 = value?.includes("t1");
                let checkValue2 = value?.includes("t2");
                let checkValue3 = value?.includes("t3");
                const handleDropDownScheme = () => {
                  return checkValue3
                    ? ["t1", "t2", "t3"]
                    : checkValue2
                    ? ["t1", "t2"]
                    : checkValue1
                    ? ["t1"]
                    : [];
                };
                const handleDropdownSchemes = handleDropDownScheme();
                formik.setFieldValue(index, {
                  ...x,
                  dropdownscheme: handleDropDownScheme() || null,
                  ...(handleDropdownSchemes.includes("t2")
                    ? {
                        BooksCollectionTargetT3T2: null,
                        r3: null,
                        t3: null,
                      }
                    : handleDropdownSchemes.includes("t1")
                    ? {
                        r2: null,
                        r3: null,
                        t2: null,
                        t3: null,
                        BooksCollectionTargetT2T1: null,
                      }
                    : {}),
                  BooksCollectionTargetT3T2: null,
                });
              };
              return (
                <Select
                  onChange={handleChange}
                  value={y?.dropdownscheme}
                  mode="multiple"
                  autoClearSearchValue="multiple"
                  className="w-100"
                  allowClear
                  onDeselect={false}
                  showArrow={false}
                  removeIcon={false}
                >
                  {topTargetArr
                    ?.filter((x) => !x?.disabled)
                    ?.map((x) => (
                      <Select.Option value={x?.value}>{x?.name}</Select.Option>
                    ))}
                </Select>
              );
            },
          },
        ]
      : [];
  const getTargetT1 = () =>
    schemeLockedStatus?.t1
      ? [
          {
            title: "T1",
            dataIndex: "t1",
            editable: true,
            width: "90px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.t1}
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? ""
                    : "Type here"
                }
                value={formik?.values?.[index]?.t1 || ""}
                onBlur={() => calculateFormula(index)}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    t1: value,
                  });
                }}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? true
                    : false
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
      : [];
  const getTargetT2 = () =>
    schemeLockedStatus?.t2
      ? [
          {
            title: "T2",
            dataIndex: "t2",
            editable: true,
            width: "90px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.t2}
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? ""
                    : "Type here"
                }
                value={formik?.values?.[index]?.t2 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    t2: value,
                  });
                }}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? true
                    : false
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
      : [];
  const getTargetT3 = () =>
    schemeLockedStatus?.t3
      ? [
          {
            title: "T3",
            dataIndex: "t3",
            editable: true,
            width: "90px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.t3}
                placeholder="Type here"
                value={formik?.values?.[index]?.t3 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    t3: value,
                  });
                }}
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? "input-disabled"
                    : "input1"
                }
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? true
                    : false
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
      : [];
  const getPayoutRateR1 = () =>
    schemeLockedStatus?.t1
      ? [
          {
            title: "R1",
            dataIndex: "r1",
            editable: true,
            width: "90px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.r1}
                value={formik?.values?.[index]?.r1 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                  if (value.length > 5) {
                    value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                  }
                  formik.setFieldValue(index, {
                    ...y,
                    r1: value,
                  });
                }}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? true
                    : false
                }
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
      : [];
  const getPayoutRateR2 = () =>
    schemeLockedStatus?.t2
      ? [
          {
            title: "R2",
            dataIndex: "r2",
            editable: true,
            width: "90px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.r2}
                value={formik?.values?.[index]?.r2 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                  if (value.length > 5) {
                    value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                  }
                  formik.setFieldValue(index, {
                    ...y,
                    r2: value,
                  });
                }}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? true
                    : false
                }
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
      : [];
  const getPayoutRateR3 = () =>
    schemeLockedStatus?.t3
      ? [
          {
            title: "R3",
            dataIndex: "r3",
            editable: true,
            width: "90px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.r3}
                value={formik?.values?.[index]?.r3 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                  if (value.length > 5) {
                    value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                  }
                  formik.setFieldValue(index, {
                    ...y,
                    r3: value,
                  });
                }}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? true
                    : false
                }
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
      : [];
  const getPayoutP1 = () =>
    schemeLockedStatus?.t1
      ? [
          {
            title: "P1",
            dataIndex: "p1",
            width: "90px",
          },
        ]
      : [];
  const getPayoutP2 = () =>
    schemeLockedStatus?.t2
      ? [
          {
            title: "P2",
            dataIndex: "p2",
            width: "90px",
          },
        ]
      : [];
  const getPayoutP3 = () =>
    schemeLockedStatus?.t3
      ? [
          {
            title: "P3",
            dataIndex: "p3",
            width: "90px",
          },
        ]
      : [];
  const calculateFormula = (index) => {
    let y = formik?.values?.[index];
    let { t1, t2, t3, r1, r2, r3, dropdownscheme } = formik?.values?.[index];
    formik?.setFieldValue(index, {
      ...y,
      p1: +(parseInt(t1 || 0) * parseInt(r1 || 0)),
      p2: +(parseInt(t2 || 0) * parseInt(r2 || 0)),
      p3: +(parseInt(t3 || 0) * parseInt(r3 || 0)),
    });
    const handleDropDownSchemeCheckIndex = () => {
      let { t1, t2, t3 } = dropdownscheme.reduce(
        (a, v) => ({ ...a, [v]: v }),
        {}
      );
      return t1 && !t2 && !t3
        ? "t1"
        : t1 && t2 && !t3
        ? "t2"
        : t1 && t2 && t3
        ? "t3"
        : "";
    };
    let validationSoftTarget = handleDropDownSchemeCheckIndex();
    if (
      r2 != null &&
      r1 != null &&
      parseInt(r2) <= parseInt(r1) &&
      (validationSoftTarget == "t2" || validationSoftTarget == "t3")
    ) {
      handleErrorModal("R2", "R1", true);
    }
    if (
      r3 != null &&
      r2 != null &&
      parseInt(r3) <= parseInt(r2) &&
      validationSoftTarget == "t3"
    ) {
      handleErrorModal("R3", "R2", true);
    }

    if (
      t2 != null &&
      t1 != null &&
      parseInt(t2) <= parseInt(t1) &&
      (validationSoftTarget == "t2" || validationSoftTarget == "t3")
    ) {
      handleErrorModal("T2", "T1", true);
    }
    if (
      t3 != null &&
      t2 != null &&
      parseInt(t3) <= parseInt(t2) &&
      validationSoftTarget == "t3"
    ) {
      handleErrorModal("T3", "T2", true);
    }
  };

  // const [sortData, setSort] = useTableSort(formik?.values)
  // Columns for table
  const defaultColumns = [
    {
      title: (
        <div>
          Dealer <br /> Code
        </div>
      ),
      dataIndex: "code",
      fixed: "left",
      width: "180px",
      sorter: true,
    },
    {
      title: (
        <div>
          Dealer <br /> Name
        </div>
      ),
      dataIndex: "name",
      fixed: "left",
      width: "180px",
    },
    {
      title: "State",
      dataIndex: "state",
      width: "120px",
    },
    {
      title: (
        <div>
          Dealer <br /> Location
        </div>
      ),
      dataIndex: "Location",
      width: "190px",
    },
    {
      title: (
        <div>
          Territory <br /> Manager
        </div>
      ),
      dataIndex: "Manager",
      width: "200px",
    },
    ...getDropdown(),
    {
      title: (
        <div>
          Start <br /> Month
        </div>
      ),
      dataIndex: "StartMonth",
      width: "160px",
    },
    {
      title: (
        <div>
          End <br /> Month
        </div>
      ),
      dataIndex: "EndMonth",
      width: "150px",
    },
    {
      title: "Actual Installation Last Year Month",
      dataIndex: "actual_Installation_Last_Year_Month",
      width: "260px",
    },
    {
      title: "Targets",
      dataIndex: "Target",
      editable: true,
      width: "180px",
      children: [...getTargetT1(), ...getTargetT2(), ...getTargetT3()],
    },
    {
      title: "inc. Per Tractor",
      dataIndex: "IPT",
      width: "180px",
      editable: true,
      children: [
        ...getPayoutRateR1(),
        ...getPayoutRateR2(),
        ...getPayoutRateR3(),
      ],
    },
    {
      title: "Payout",
      dataIndex: "Payout",
      editable: true,
      width: "180px",
      children: [...getPayoutP1(), ...getPayoutP2(), ...getPayoutP3()],
    },
    {
      title: "Remarks",
      dataIndex: "reason",
      editable: true,
      width: "120px",
      render: (x, y, index) => (
        <input
          name={formik?.values?.[index]?.reason}
          placeholder="Type here"
          value={formik?.values?.[index]?.reason}
          onBlur={() => {
            calculateFormula(index);
          }}
          onChange={(e) => {
            let value = e.target?.value;
            const sanitizedValue = value
              ?.replace("<script>", "")
              ?.replace("</script>", "")
              .replace(/[^a-zA-Z0-9\s]/g, "");
            formik.setFieldValue(index, {
              ...y,
              reason: sanitizedValue,
            });
          }}
          className="input1"
          style={{ width: "90%", padding: "5px" }}
        />
      ),
    },
  ];

  const handleDropDownSchemeCheck = () => {
    let { t1, t2, t3 } = schemeLockedStatus;
    return t1 && !t2 && !t3
      ? ["t1"]
      : t1 && t2 && !t3
      ? ["t1", "t2"]
      : t1 && t2 && t3
      ? ["t1", "t2", "t3"]
      : "";
  };
  useEffect(() => {
    if (listData?.length != 0) {
      let data = async () => {
        formik.setValues(getFilterData(listData));
        // formik?.values?.length!=0&&formik?.values?.map((x,index)=>{
        // calculateFormula(index)
        //  })
      };
      data();
    }
  }, [listData]);

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    if (
      showHide &&
      selectedRowKeys.length > 0 &&
      selectedRowKeys.includes({ ...props }["data-row-key"])
    )
      return;
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };

    const save = async () => {
      try {
        //
        const values = await form.validateFields();
        toggleEdit();
        handleSave(
          {
            ...record,
            ...values,
          },
          values
        );
      } catch (errInfo) {}
    };
    const handleInputChange = (e, dataIndex) => {
      const isInteger = /^[1-9]+$/;
      const { name, value } = e.target;

      if (name == "Remarks") {
        form.setFieldsValue({
          [dataIndex]: e.target.value,
        });
        return;
      }

      if (
        (e.target.value !== "" && e.target.value == 0) ||
        e.target.value < 0 ||
        !Number.isInteger(+e.target.value)
      ) {
        handleModal();
        setDirty(true);
        form.resetFields();
        return;
      }
      if (
        e.target.value !== "" &&
        e.target.value !== 0 &&
        isInteger.test(+e.target.value)
      ) {
        form.setFieldsValue({
          [dataIndex]: e.target.value,
        });
      } else if (e.target.value == 0 && e.target.value != "") {
        handleModal();
        setDirty(!dirty);
        form.resetFields();
      }
    };

    let childNode = children;
    if (editable && record.status !== 1 && record.status !== 2) {
      // if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            type={dataIndex !== "reason" && "number"}
            name={title}
            onChange={(value) => {
              handleInputChange(value, dataIndex);
            }}
          />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
            height: 20,
            display: "block",
            // background: 'red',
            border: "1px solid #d9d9d9",
            borderRadius: "5px",
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
  };

  const handleSave = (row, form) => {
    // if (row.t2 != null && row.t1 != null && +row.t2 <= +row.t1) {
    //   handleErrorModal("T2", "T1");
    //   return;
    // }

    // if (row.r2 != null && row.r1 != null && +row.r2 <= +row.r1) {
    //   handleErrorModal("R2", "R1");
    //   return;
    // }
    const newData = [...listData];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = { ...newData[index], ...form };

    // if (item.t1 && item.r1) {
    //   item.p1 = parseInt(item.t1) * parseInt(item.r1);

    // }
    // if (item.t2 && item.r2) {
    //   item.p2 = parseInt(item.t2) * parseInt(item.r2);
    // }

    newData.splice(index, 1, {
      ...row,
      ...item,
    });
    dispatch(installationSchemeList(newData));
  };
  const mapColumns = (col) => {
    if (!col.editable) {
      return col;
    }
    const newCol = {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }
    return newCol;
  };
  const columns = defaultColumns.map((col) => {
    return mapColumns(col);
  });
  const draftData = (e) => {
    e.preventDefault();
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const userName = userData.userName;
    const finalData = [];
    let data = [];
    formik?.values.forEach((x) => {
      data.push({
        dealerCode: x.code,
        state: x.stateId,
        dealerId: +x.key,
        dealerLocation: x.Location,
        dealerName: x.name,
        StartMonth: startDate,
        EndMonth: endDate,
        reason: x.reason,
        isSAP: true,
        isActive: true,
        schemePlanStartMonth: startDate,
        schemePlanEndMonth: endDate,
        schemeTypeId: 3,
        t1: +x.t1,
        t2: +x.t2,
        t3: +x.t3,
        p1: x?.p1,
        p2: x?.p2,
        p3: x?.p3,
        r1: +x.r1,
        r2: +x.r2,
        r3: +x.r3,
        fyear: currentFiscalYear,
        userid: +userId,
        username: userName,
        TopTarget: x?.dropdownscheme?.toString(),
      });
    });
    const handleDropDownSchemeCheckIndex = (x) => {
      let { t1, t2, t3 } = x.reduce((a, v) => ({ ...a, [v]: v }), {});

      let data =
        t1 && !t2 && !t3
          ? "t1"
          : t1 && t2 && !t3
          ? "t2"
          : t1 && t2 && t3
          ? "t3"
          : "";
      return data;
    };

    let checkValidation =
      schemeType == 3
        ? formik?.values?.filter((x) =>
            handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t1"
              ? !x.t1 || !x.r1
              : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t2"
              ? !x.t1 ||
                !x.t2 ||
                !x.r1 ||
                !x.r2 ||
                parseInt(x?.t2 || 0) <= parseInt(x?.t1 || 0) ||
                parseInt(x?.r2 || 0) <= parseInt(x?.r1 || 0) ||
                !x.TEPvol
              : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t3"
              ? !x.t1 ||
                !x.t2 ||
                !x.t3 ||
                !x.r1 ||
                !x.r2 ||
                !x.r3 ||
                parseInt(x?.t3 || 0) <= parseInt(x?.t2 || 0) ||
                parseInt(x?.t2 || 0) <= parseInt(x?.t1 || 0) ||
                parseInt(x?.r3 || 0) <= parseInt(x?.r2 || 0) ||
                parseInt(x?.r2 || 0) <= parseInt(x?.r1 || 0) ||
                !x.TEPvol
              : !x.t1 || !x.t2 || !x.t3 || !x.r1 || !x.r2 || !x.r3
          )
        : formik?.values?.filter((x) =>
            handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t1"
              ? !x.t1 || !x.r1
              : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t2"
              ? !x.t1 || !x.t2 || !x.r1 || !x.r2
              : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t3"
              ? !x.t1 || !x.t2 || !x.t3 || !x.r1 || !x.r2 || !x.r3
              : !x.t1 || !x.t2 || !x.t3 || !x.r1 || !x.r2 || !x.r3
          );
    let errorDetails =
      schemeType == 3
        ? Object.entries(checkValidation?.[0] ? checkValidation?.[0] : {})
            ?.filter((x) =>
              checkValidation?.[0]?.dropdownscheme?.includes("t3")
                ? !x[1] &&
                  (x[0] == "t1" ||
                    x[0] == "t2" ||
                    x[0] == "t3" ||
                    x[0] == "r1" ||
                    x[0] == "r2" ||
                    x[0] == "r3")
                : checkValidation?.[0]?.dropdownscheme?.includes("t2")
                ? !x[1] &&
                  (x[0] == "t1" || x[0] == "t2" || x[0] == "r1" || x[0] == "r2")
                : checkValidation?.[0]?.dropdownscheme?.includes("t1")
                ? !x[1] && (x[0] == "t1" || x[0] == "r1")
                : !x[0]
            )
            .map((x) =>
              x[0] == "t1"
                ? "T1"
                : x[0] == "t2"
                ? "T2"
                : x[0] == "t3"
                ? "T3"
                : x[0] == "r1"
                ? "R1"
                : x[0] == "r2"
                ? "R2"
                : x[0] == "r3"
                ? "R3"
                : x[0]
            )
        : Object.entries(checkValidation?.[0] ? checkValidation?.[0] : {})
            ?.filter((x) =>
              checkValidation?.[0]?.dropdownscheme?.includes("t3")
                ? !x[1] &&
                  (x[0] == "t1" ||
                    x[0] == "t2" ||
                    x[0] == "t3" ||
                    x[0] == "r1" ||
                    x[0] == "r2" ||
                    x[0] == "r3")
                : checkValidation?.[0]?.dropdownscheme?.includes("t2")
                ? !x[1] &&
                  (x[0] == "t1" || x[0] == "t2" || x[0] == "r1" || x[0] == "r2")
                : checkValidation?.[0]?.dropdownscheme?.includes("t1")
                ? !x[1] && (x[0] == "t1" || x[0] == "r1")
                : !x[0]
            )
            .map((x) =>
              x[0] == "t1"
                ? "T1"
                : x[0] == "t2"
                ? "T2"
                : x[0] == "t3"
                ? "T3"
                : x[0] == "r1"
                ? "R1"
                : x[0] == "r2"
                ? "R2"
                : x[0] == "r3"
                ? "R3"
                : x[0]
            );
    let extraValidation = [];

    if (schemeType == 3) {
      formik?.values?.map((x, index) => {
        // if (checkValidation?.[0]?.code) {
        //   formik?.values?.filter((x) => x?.code == checkValidation?.[0]?.code).map((x, index) => {

        let topTargetDetails = x?.dropdownscheme;
        let errors = [];
        if (topTargetDetails?.length == 0) {
          errors.push("Please select Top Target");
        }
        if (
          topTargetDetails?.includes("t3") &&
          parseInt(x?.t3) <= parseInt(x?.t2)
        ) {
          errors.push("T3 should be greater than T2");
        }
        if (
          topTargetDetails?.includes("t2") &&
          parseInt(x?.t2) <= parseInt(x?.t1)
        ) {
          errors.push("T2 should be greater than T1");
        }
        if (
          topTargetDetails?.includes("t3") &&
          parseInt(x?.r3) <= parseInt(x?.r2)
        ) {
          errors.push("R3 should be greater than R2");
        }
        if (
          topTargetDetails?.includes("t2") &&
          parseInt(x?.r2) <= parseInt(x?.r1)
        ) {
          errors.push("R2 should be greater than R1");
        }
        if (!x.r1) {
          errors.push("Please enter R1");
        }
        if (!x.r2 && topTargetDetails?.includes("t2")) {
          errors.push("Please enter R2");
        }
        if (!x.r3 && topTargetDetails?.includes("t3")) {
          errors.push("Please enter R3");
        }
        if (!x.t1) {
          errors.push("Please enter T1");
        }
        if (!x.t2 && topTargetDetails?.includes("t2")) {
          errors.push("Please enter T2");
        }
        if (!x.t3 && topTargetDetails?.includes("t3")) {
          errors.push("Please enter T3");
        }
        if (errors.length > 0) {
          extraValidation[x?.code] = errors;
        }
      });

      if (Object?.keys?.(extraValidation)?.length != 0) {
        showErrorDialog(extraValidation);
      } else {
        handleSuccessModal();
        setDataHandle(data);
      }
    }
  };

  const formatData = (result) => {
    return result.map((item, index) => {
      // let formattedStartDate = selectedRowKeys.includes(item.admDealerId)
      //   ? changeSchemeDate?.startDate
      //   : startDate;
      let formattedStartDate = dayjs(startDate).format("MMM'YY");

      // let formattedEndDate = selectedRowKeys.includes(item.admDealerId)
      //   ? changeSchemeDate?.endDate
      //   : endDate;
      let formattedEndDate = dayjs(endDate).format("MMM'YY");
      return {
        status: item.status,
        key: item.admDealerId,
        code: item.dealerCode,
        name: item.dealerName,
        Location: item.cityName,
        state: item.stateName,
        Manager: item.territoryName,
        StartMonth: formattedStartDate,
        EndMonth: formattedEndDate,
        Target: item.installationTarget,
        stateId: item.stateId,
        t1: item.t1,
        t2: item.t2,
        t3: item.t3,
        r1: item.r1,
        r2: item.r2,
        r3: item.r3,
        reason: item.reason,
        p1: item?.p1,
        p2: item?.p2,
        p3: item?.p3,
        dropdownscheme: item?.dropdownscheme || [],
        // reason:
        // key: item.DealerCode,
        // code: item.DealerCode ,
        // name: item.DealerName,
        // Location: item.DealerLocation,
        // state: item.State,
        // Manager: item.TerritoryManager,
        // industry: item.Feb21.Industry,
        // b: 35,
        // i: 45,
        // c: 45,
        // target: "T1",
        nos: null,
        actual_Installation_Last_Year_Month:
          item?.actual_Installation_Last_Year_Month,
        // days: item.Feb22OpeningStock.Days,
        // nov21: item.Nov21,
        // dec21: item.Dec21,
        // lac: 32,
        BooksCollectionTargetT1: null,
        BooksCollectionTargetT2: null,
        // BooksCollectionTargetT2T1: 5,
        BooksCollectionTargetT3: null,
        // BooksCollectionTargetT3T2: 5,
        PayoutRatep1: null,
        PayoutRatep2: null,
        PayoutRatep3: null,
        // payoutR1: 33000,
        // payoutR2: 52000,
        // payoutR3: 42000,
        TEPvol: null,
        // TEPpertr: 6000,
        // TEPamount: 33000,
        // LMSA: "T3",
        // LCQ: 55,
        // LMSA1: "T2",
        // LCQ1: 33000,
      };
    });
  };

  useEffect(() => {
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const userRole = userData.userRole;
    const staticAmId = 3468;
    const data = formatData(allListData);
    setSlabSchemeList(allListData);
    dispatch(installationSchemeList(data));
  }, [allListData]);

  const handleChangeScheme = (data) => {
    setOpenSchemeModal((openSchemeModal) => !openSchemeModal);
    if (data?.startDate) {
      setchangeSchemeDate(data);
      // API CALL FOR UPDATE
      // SELECTED RECORD UPDATE
    }
  };

  // Table header
  const tableHeader = () => {
    const handleHide = () => {
      setShowUnhide(!showHide);
    };

    return (
      <Row className="table_sub_buttons">
        {/* <Col> */}
        {/* <Button
            icon={showHide === true ? <UnHideIcon /> : <HideIcon />}
            type="text"
            style={{
              margin: "10px",
              color: showHide === true ? "#FF3232" : "",
            }}
            onClick={handleHide}
          >
            {showHide === true ? "Show" : "Hide"}
          </Button>
        </Col>
        <Col>
          <Button type="text" style={{ margin: "10px" }}>
            <MergeIcon />
            Merge
          </Button>
        </Col>
        <Col>
          <Button type="text" style={{ margin: "10px" }}>
            <UnmergeIcon />
            Unmerge
          </Button>
        </Col> */}
        <Col>
          <Button
            onClick={handleChangeScheme}
            type="text"
            style={{ margin: "10px" }}
          >
            <ChangeScheme />
            Change Scheme
          </Button>
        </Col>
      </Row>
    );
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const handlePayloadValidation = (payload) => {
    if (!payload.reason || !payload.reason) {
      return;
    }
  };

  const submitData = (e) => {
    e.preventDefault();
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const userName = userData.userName;
    const finalData = [];
    const handleDropDownSchemeCheckIndex = (x) => {
      let { t1, t2, t3 } = x.reduce((a, v) => ({ ...a, [v]: v }), {});

      let data =
        t1 && !t2 && !t3
          ? "t1"
          : t1 && t2 && !t3
          ? "t2"
          : t1 && t2 && t3
          ? "t3"
          : "";
      return data;
    };

    let checkValidation =
      schemeType == 3
        ? formik?.values?.filter((x) =>
            handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t1"
              ? {
                  t1: +x.t1 || 0,
                  t2: 0,
                  t3: 0,
                  p1: +x.p1 || 0,
                  p2: 0,
                  p3: 0,
                  r1: +x?.r1 || 0,
                  r2: 0,
                  r3: 0,
                }
              : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t2"
              ? {
                  t1: +x.t1 || 0,
                  t2: +x.t2 || 0,
                  t3: 0,
                  p1: +x.p1 || 0,
                  p2: +x.p2 || 0,
                  p3: 0,
                  r1: +x?.r1 || 0,
                  r2: +x?.r2 || 0,
                  r3: 0,
                }
              : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t3"
              ? {
                  t1: +x.t1 || 0,
                  t2: +x.t2 || 0,
                  t3: +x.t3 || 0,
                  p1: +x.p1 || 0,
                  p2: +x.p2 || 0,
                  p3: +x.p3 || 0,
                  r1: +x?.r1 || 0,
                  r2: +x?.r2 || 0,
                  r3: +x?.r3 || 0,
                }
              : {
                  t1: 0,
                  t2: 0,
                  t3: 0,
                  r1: 0,
                  r2: 0,
                  r3: 0,
                  p1: 0,
                  p2: 0,
                  p3: 0,
                }
          )
        : formik?.values?.filter((x) =>
            handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t1"
              ? !x.t1 || !x.r1
              : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t2"
              ? !x.t1 || !x.t2 || !x.r1 || !x.r2
              : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t3"
              ? !x.t1 || !x.t2 || !x.t3 || !x.r1 || !x.r2 || !x.r3
              : !x.t1 || !x.t2 || !x.t3 || !x.r1 || !x.r2 || !x.r3
          );
    let errorDetails =
      schemeType == 3
        ? Object.entries(checkValidation?.[0] ? checkValidation?.[0] : {})
            ?.filter((x) =>
              checkValidation?.[0]?.dropdownscheme?.includes("t3")
                ? !x[1] &&
                  (x[0] == "t1" ||
                    x[0] == "t2" ||
                    x[0] == "t3" ||
                    x[0] == "r1" ||
                    x[0] == "r2" ||
                    x[0] == "r3")
                : checkValidation?.[0]?.dropdownscheme?.includes("t2")
                ? !x[1] &&
                  (x[0] == "t1" || x[0] == "t2" || x[0] == "r1" || x[0] == "r2")
                : checkValidation?.[0]?.dropdownscheme?.includes("t1")
                ? !x[1] && (x[0] == "t1" || x[0] == "r1")
                : !x[0]
            )
            .map((x) =>
              x[0] == "t1"
                ? "T1"
                : x[0] == "t2"
                ? "T2"
                : x[0] == "t3"
                ? "T3"
                : x[0] == "r1"
                ? "R1"
                : x[0] == "r2"
                ? "R2"
                : x[0] == "r3"
                ? "R3"
                : x[0]
            )
        : Object.entries(checkValidation?.[0] ? checkValidation?.[0] : {})
            ?.filter((x) =>
              checkValidation?.[0]?.dropdownscheme?.includes("t3")
                ? !x[1] &&
                  (x[0] == "t1" ||
                    x[0] == "t2" ||
                    x[0] == "t3" ||
                    x[0] == "r1" ||
                    x[0] == "r2" ||
                    x[0] == "r3")
                : checkValidation?.[0]?.dropdownscheme?.includes("t2")
                ? !x[1] &&
                  (x[0] == "t1" || x[0] == "t2" || x[0] == "r1" || x[0] == "r2")
                : checkValidation?.[0]?.dropdownscheme?.includes("t1")
                ? !x[1] && (x[0] == "t1" || x[0] == "r1")
                : !x[0]
            )
            .map((x) =>
              x[0] == "t1"
                ? "T1"
                : x[0] == "t2"
                ? "T2"
                : x[0] == "t3"
                ? "T3"
                : x[0] == "r1"
                ? "R1"
                : x[0] == "r2"
                ? "R2"
                : x[0] == "r3"
                ? "R3"
                : x[0]
            );
    //dealerLocation
    //dealerName
    let data = [];
    formik?.values.forEach((item) => {
      data.push({
        dealerCode: item.code,
        dealerId: +item.key,
        dealerLocation: item.Location,
        dealerName: item.name,
        StartMonth: startDate
          ? dayjs(startDate).startOf("month").format("YYYY-MM-DD HH:mm:ss")
          : startDate,
        EndMonth: endDate
          ? dayjs(endDate).endOf("month").format("YYYY-MM-DD HH:mm:ss")
          : endDate,
        isSAP: true,
        isActive: true,
        state: item.stateId,
        status: 1,
        isSubmitted: 1,
        actual_Installation_Last_Year_Month:
          item?.actual_Installation_Last_Year_Month,
        fyear: currentFiscalYear,
        schemeType: "Slabbased",
        schemeTypeId: schemeType,
        userid: +userId,
        username: userName,
        t1: +item.t1 || 0,
        t2: +item.t2 || 0,
        t3: +item.t3 || 0,
        r1: +item.r1 || 0,
        r2: +item.r2 || 0,
        r3: +item.r3 || 0,
        p1: +item.p1 || 0,
        p2: +item.p2 || 0,
        p3: +item.p3 || 0,
        reason: item.reason,
        TopTarget: item?.dropdownscheme?.toString(),
      });
    });
    let extraValidation = [];

    if (schemeType == 3) {
      const filteredValue = formik?.values?.filter((selectedSchemesFil) =>
        selectedRowKeys.includes(selectedSchemesFil?.key)
      );
      if (filteredValue?.length != 0) {
        filteredValue?.map((x, index) => {
          // if (checkValidation?.[0]?.code) {
          //   formik?.values?.filter((x) => x?.code == checkValidation?.[0]?.code).map((x, index) => {
          let topTargetDetails = x?.dropdownscheme;
          let errors = [];
          if (topTargetDetails?.length == 0) {
            errors.push("Please select Top Target");
          }

          if (
            topTargetDetails?.includes("t3") &&
            parseInt(x?.t3) <= parseInt(x?.t2)
          ) {
            errors.push("T3 should be greater than T2");
          }
          if (
            topTargetDetails?.includes("t2") &&
            parseInt(x?.t2) <= parseInt(x?.t1)
          ) {
            errors.push("T2 should be greater than T1");
          }
          if (
            topTargetDetails?.includes("t3") &&
            parseInt(x?.r3) <= parseInt(x?.r2)
          ) {
            errors.push("R3 should be greater than R2");
          }
          if (
            topTargetDetails?.includes("t2") &&
            parseInt(x?.r2) <= parseInt(x?.r1)
          ) {
            errors.push("R2 should be greater than R1");
          }
          if (!x.r1) {
            errors.push("Please enter R1");
          }
          if (!x.r2 && topTargetDetails?.includes("t2")) {
            errors.push("Please enter R2");
          }
          if (!x.r3 && topTargetDetails?.includes("t3")) {
            errors.push("Please enter R3");
          }
          if (!x.t1) {
            errors.push("Please enter T1");
          }
          if (!x.t2 && topTargetDetails?.includes("t2")) {
            errors.push("Please enter T2");
          }
          if (!x.t3 && topTargetDetails?.includes("t3")) {
            errors.push("Please enter T3");
          }

          if (errors.length > 0) {
            extraValidation[x?.code] = errors;
          }
        });
        const selectedData1 = data?.filter((selectedSchemesFil) =>
          selectedRowKeys.includes(selectedSchemesFil?.dealerId)
        );

        if (Object?.keys?.(extraValidation)?.length != 0) {
          showErrorDialog(extraValidation);
        } else {
          handleSuccessModal();
          setDataHandle(selectedData1);
        }
      } else {
        ToastifyShow("Please select the scheme", "error");
      }
    }
  };
  const getFilterData = (listData) => {
    return listData
      .map((item, index) => {
        if (dealerCode.includes(item.code)) {
          return {
            ...item,
            r1: item?.r1 || "",
            r2: item?.r2 || "",
            r3: item?.r3 || "",
            t1: item?.t1 || "",
            t2: item?.t2 || "",
            t3: item?.t3 || "",
            dropdownscheme:
              handleDropDownSchemeCheck()?.includes("t3") &&
              item?.dropdownscheme?.includes("t3")
                ? ["t1", "t2", "t3"]
                : item?.dropdownscheme?.includes("t2")
                ? ["t1", "t2"]
                : item?.dropdownscheme?.includes("t1")
                ? ["t1"]
                : item?.dropdownscheme?.length == 0 &&
                  handleDropDownSchemeCheck()
                ? handleDropDownSchemeCheck()
                : [],
          };
        }
      })
      .filter((item) => item != undefined);
  };

  const showErrorDialog = (errFields) => {
    setOpenErrorDialog((openErrorDialog) => ({
      open: true,
      errorsFields: errFields ? errFields : [],
    }));
  };

  const closeErrorDialog = () => {
    setOpenErrorDialog({ open: false, errorsFields: [] });
  };

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);

    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (event) => {
    event.preventDefault();
    return (event.returnValue = "");
  };

  const hasSelected = selectedRowKeys.length > 0;
  return (
    <div className="table_scheme_wrap slab-uncondionaling">
      <Divider />
      <grid>
        <Row>
          <Col flex="auto">
            <h2
              className="unconditional"
              loading={loading}
              style={{ fontSize: "18px", padding: "20px", paddingLeft: "0px" }}
            >
              Slab Based Scheme
            </h2>
          </Col>
          <Col style={{ display: "flex", alignItems: "center" }}>
            {/* <ThemeButton /> */}
          </Col>
        </Row>

        <span>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </grid>
      <Form>
        <Table
          style={{ paddingLeft: "0px", paddingRight: "40px" }}
          // title={tableHeader}
          showSorterTooltip={false}
          pagination={false}
          summary={(pageData) => {
            const fieldSum = {
              T1: 0,
              T2: 0,
              T3: 0,
              R1: 0,
              R2: 0,
              R3: 0,
              P1: 0,
              P2: 0,
              P3: 0,
              totalAmount: 0,
            };
            pageData.forEach(({ t1, t2, t3, r1, r2, r3, p1, p2, p3 }) => {
              fieldSum.T1 += t1 ? parseInt(t1) : 0;
              fieldSum.T2 += t2 ? parseInt(t2) : 0;
              fieldSum.T3 += t3 ? parseInt(t3) : 0;
              // fieldSum.R1 = r1 ? parseInt(p1) / parseInt(t1) : 0;
              // fieldSum.R2 = r2 ? parseInt(p2) / parseInt(t2) : 0;
              fieldSum.R1 += r1 ? parseInt(r1) : 0;
              fieldSum.R2 += r2 ? parseInt(r2) : 0;
              fieldSum.R3 += r3 ? parseInt(r3) : 0;
              fieldSum.P1 += p1 ? parseInt(p1) : 0;
              fieldSum.P2 += p2 ? parseInt(p2) : 0;
              fieldSum.P3 += p3 ? parseInt(p3) : 0;
            });
            return (
              <Table.Summary.Row
                style={{ fontWeight: "600", fontSize: "18px" }}
              >
                <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8}></Table.Summary.Cell>
                <Table.Summary.Cell index={9}></Table.Summary.Cell>
                <Table.Summary.Cell index={10}>
                  {fieldSum.T1 ? fieldSum.T1 : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={11}>
                  {fieldSum.T2 ? fieldSum.T2 : ""}
                </Table.Summary.Cell>
                {schemeLockedStatus?.t3 && (
                  <Table.Summary.Cell index={12}>
                    {fieldSum.T3 ? fieldSum.T3 : ""}
                  </Table.Summary.Cell>
                )}
                <Table.Summary.Cell index={13}>
                  {fieldSum.P1 && fieldSum.T1
                    ? Math.round(fieldSum.P1 / fieldSum.T1)
                    : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={14}>
                  {fieldSum.P2 && fieldSum.T2
                    ? Math.round(fieldSum.P2 / fieldSum.T2)
                    : ""}
                </Table.Summary.Cell>
                {schemeLockedStatus?.t3 && (
                  <Table.Summary.Cell index={15}>
                    {fieldSum.P3 && fieldSum.T3
                      ? Math.round(fieldSum.P3 / fieldSum.T3)
                      : ""}
                  </Table.Summary.Cell>
                )}
                <Table.Summary.Cell index={16}>
                  {fieldSum.P1 ? fieldSum.P1 : ""}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={17}>
                  {fieldSum.P2 ? fieldSum.P2 : ""}
                </Table.Summary.Cell>
                {schemeLockedStatus?.t3 && (
                  <Table.Summary.Cell index={18}>
                    {fieldSum.P3 ? fieldSum.P3 : ""}
                  </Table.Summary.Cell>
                )}
                <Table.Summary.Cell index={19}></Table.Summary.Cell>
                <Table.Summary.Cell index={20}></Table.Summary.Cell>
                <Table.Summary.Cell index={21}></Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
          // components={components}
          rowSelection={{
            selectedRowKeys,
            onChange: (newSelectedRowKeys) => {
              setSelectedRowKeys(newSelectedRowKeys);
              setSchemeIdNo(selectedRow);
            },
            getCheckboxProps: (record) => ({
              disabled: record.status === 1 || record.status === 2,
              // Column configuration not to be checked
              name: record.name,
            }),
          }}
          rowClassName={() => "editable-row"}
          columns={columns}
          dataSource={formik?.values}
          scroll={{
            x: 1000,
            y: 400,
          }}
          bordered
          size="middle"
        />
      </Form>

      <div style={{ padding: "0px 0px 0px 0px" }}>
        <Row
          className="draft-button-wrap"
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {/* <Col>
            <Button
              type="default"
              shape="circle"
              icon={<DownloadOutlined style={{ paddingRight: "32px" }} />}
              style={{ margin: "14px", background: "#E4E4E4" }}
            />{" "}
          </Col> */}
          <Col>
            <Button
              className="draft-btn"
              type="default"
              shape="round"
              style={{
                margin: "10px",
                background: "#FCE838",
                fontWeight: "700",
                textTransform: "uppercase",
                border: "none",
                padding: "20px",
              }}
              size="large"
              onClick={draftData}
            >
              save as draft
            </Button>
          </Col>
          <Col>
            <Button
              type="default"
              shape="round"
              style={{
                margin: "10px",
                background: "#FCE838",
                fontWeight: "700",
                textTransform: "uppercase",
                border: "none",
                padding: "20px",
              }}
              size="large"
              onClick={submitData}
              disabled={listData.every((item) => item.nos == "0")}
            >
              Save & Submit
            </Button>{" "}
          </Col>
        </Row>
      </div>

      <ChangeSchemeModal
        openModal={openSchemeModal}
        onModalClose={handleChangeScheme}
      />
      {dirty && <AlertModal openModal={openModal} handleModal={handleModal} />}

      {/* <SMDialog open={openSaveModal} onCancel={handleSaveModal}>
        <p className="custom-warning">Warning !</p>
        <p>Please fill the required fields</p>
      </SMDialog> */}

      <StatusModal
        open={openSuccessModal}
        onOk={() => {
          dispatch(fetchgenerateScheme(dataHandle));
          setOpenSuccessModal(false);
          setTimeout(() => navigate("/installation/allSchemes"), 1500);
        }}
        onCancel={() => {
          setOpenSuccessModal(false);
        }}
      >
        <p>Are you sure you want to save</p>
      </StatusModal>
      {/* <SMDialog open={openSuccessModal} onCancel={handleSuccessModal}>
        <p className="custom-warning">Success</p>
        <p>Scheme Successfully Generated</p>
      </SMDialog> */}

      <StatusModal
        open={openDraftModal}
        onOk={() => {
          dispatch(fetchgenerateScheme(dataDraftHandle));
          handleDraftModal();
        }}
        onCancel={handleDraftModal}
      >
        <p>Are you sure to save as Draft</p>
      </StatusModal>

      {/* <SMDialog open={openDraftModal} onCancel={handleDraftModal}>
        <p className="custom-warning">Success</p>
        <p> Scheme Draft Successfully</p>
      </SMDialog> */}

      <SMDialog open={openErrorModal} onCancel={handleErrorModal}>
        <p className="custom-warning">Warning !</p>
        <p>
          {errorItem.firstItem} should be greater than {errorItem.secondItem}
        </p>
      </SMDialog>
      <SMDialog open={openSelectModal} onCancel={handleSelectModal}>
        <p className="custom-warning">Warning !</p>
        <p>Please Select The Dealer</p>
      </SMDialog>
      {/* 
      <ValidationErrorsModal
        open={openErrorDialog.open}
        onClose={closeErrorDialog}
        errorsFields={openErrorDialog?.errorsFields}
        
      /> */}

      <ValidationDifferentSchemeError
        open={openErrorDialog.open}
        onClose={closeErrorDialog}
        errorsFields={openErrorDialog?.errorsFields}
        // SchemeID={codeError}
      />
    </div>
  );
};
export default SlabBasedScheme;
