import { Button, Table, Row, Col, Tag, Pagination } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import StateHead from "../../pages/dashboard/stateHead";
import ActionButton from "../actionButton/actionButton";
// import AMGRSchemeList from "../../AMdashboard/AMGRSchemeList.js"
import { areaMngrData } from "../../redux/areaManager/DashboardList";
import dayjs from "dayjs";
import axiosAPI from "../../services/axiosApi";
import { getCurrentFiscalYear, getCurrentFiscalYear1 } from "../../utils/dateFormatter";
import { STATE_WISE_DASHBOARD_LABEL } from "../../config";
import { getStateWiseDataApi } from "../../redux/stateData/stateDataSlice";
import { schemeListApproveApi } from "../../redux/schemaGenerate/schemaGenerateSlice";
import ToastifyShow, { TextLocalString } from "../ToastifyShow";
import TableColorDetails from "./TableColorDetails";
import { decryptData } from "../../utils/localStorageEncodeDecode";
import FullScreenLoader from "../loader/FullScreenLoader";



const UnconditionalInstallation = ({
  selectedSchemeType,
  onSetCardData,
  selectedStateId,
  selectedStatus,
  dashboardType,
  monthlyFilters,
  setDataSource,
  dataSource,
  yearShow,
  startMonth
}) => {
  const dispatch = useDispatch();
  const { stateWiseData, loadingState } = useSelector(
    (state) => state.stateData
  );
  // const currentFiscalYear = getCurrentFiscalYear(dayjs(yearShow));
const InitCollectionData = {
  currentYear: yearShow,
  // collectionType: 'Collection',
  pageSize: 150,
  pageNumber: 1,
  sortingOrder:"ascend",
  sortingType:"DealerCode"
};

  const { loadingScheme, schemeApprove } = useSelector(
    (state) => state.schemagenerate
  );
  const [toggleCheck,setToggleCheck]=useState(false)
  const [isLoading,setIsLoading]=useState(false)
  // const [selectedSchemeType, setSelectedSchemeType] = useState(1)
  const [loading, setLoading] = useState(false);
  // const listData = useSelector((state)=> state.generatedScheme.data)
  const [mngrdata, setMngrdata] = useState([]);
  const currentYear = dayjs().year();
  const listAreaMngrData = useSelector(
    (state) => state.areaMngrSchemeList.data
  );
  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const {userRole,userId} = decryptData(sessionStorage.getItem("userInfo"))||{};


  const columns = [
    {
      title: "Scheme No.",
      dataIndex: "scheme",
      width: "130px",
      fixed: "left",
      defaultSortOrder: "ascend",
      sorter: true,
      key:"SchemeNo",
      render: (item) => {
        
        let color = " ";
        const dataItem=item?.holdStatus==9?item?.holdStatus:item.status
        switch (dataItem) {
          case 0:
            color = "#FCE838"; //inProgress
            break;
          case 1:
            color = "#FCE838"; //inProgress
            break;
          case 2:
            color = "#51DE4E"; //Approved
            break;
          case 3:
            color = "#9999ff"; //hold
            break;
          case 4:
            color = "#FF3232"; //Cancel
            break;
          case 5:
            color = "#FF3232"; //Reject
            break;
          case 7:
            color = "#bcb38d"; //Deviation
            break;
          case 8:
            color = "rgb(255, 0, 255)"; //Auto Reject
            break;
            case 9:
              color = "#9999ff"; //hold
              break;
          default:
            color = "#FCE838";
            break;
        }
        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={color}
          >
            {item.schemeNo}
          </Tag>
        );
      },
    },
    {
      title: "Dealer Code",
      dataIndex: "code",
      fixed: "left",
      width: "140px",
      sorter: true,
      defaultSortOrder: "ascend",
      key:"DealerCode",
  
    },
    {
      title: "Dealer Name",
      dataIndex: "name",
      fixed: "left",
      width: "140px",
      sorter: true,
      defaultSortOrder: "ascend",
      key:"DealerName",
  
    },
    {
      title: "State",
      dataIndex: "state",
      width: "70px",
      sorter: true,
      defaultSortOrder: "ascend",
      key:"StateName",
    },
    {
      title: "Dealer Location",
      dataIndex: "Location",
      width: "160px",
      onFilter: (value, record) => record.Location.indexOf(value) === 0,
      sorter: true,
      defaultSortOrder: "ascend",
      key:"DealerLocation",
  
    },
  
    {
      title: "Territory Manager",
      dataIndex: "Manager",
      width: "180px",
      sorter: true,
      defaultSortOrder: "ascend",
      key:"TerritoryManager",
    },
    {
      title: "Start Month",
      dataIndex: "StartMonth",
      //   sorter: true,
      width: "70px",
    },
    {
      title: "End Month",
      dataIndex: "EndMonth",
      //   sorter: true,
      width: "70px",
    },
    {
      title: "Actual Installation Last Year Month",
      dataIndex: "actual_Installation_Last_Year_Month",
      //   sorter: true,
      width: "170px",
    },
    {
      title: "Installation Expected",
      dataIndex: "Target",
      width: "150px",render:(x)=>TextLocalString(x)
    },
    {
      title: "inc. Per Tractor",
      dataIndex: "IPT",
      width: "150px",render:(x)=>TextLocalString(x)
    },
    {
      title: "Payout",
      dataIndex: "P1",
      width: "70px",render:(x)=>TextLocalString(x)
    },
    {
      title: "Remarks",
      dataIndex: "reason",
      width: "120px",
    },
    {
      title: "Hold Remarks",
      dataIndex: "holdReason",
      width: "120px",
    }
  ];
  const stateWiseColumns = [
    {
        title: "Actual Installation",
        dataIndex: "actualInstallation",
        width: "100px",
    },
    {
        title: "Payout 1",
        dataIndex: "payout1",
        width: "100px",
    },
    {
        title: "Payout 2",
        dataIndex: "payout2",
        width: "100px",
    },
    {
        title: "Payout 3",
        dataIndex: "payout3",
        width: "100px",
    },
    {
        title: "Payout 4",
        dataIndex: "payout4",
        width: "100px",
    }
];
if (dashboardType === STATE_WISE_DASHBOARD_LABEL) {
  columns.push(...stateWiseColumns);
}  
  const fetchGeneratedScheme = async (demoParams) => {
    setIsLoading(true)
   await axiosAPI.post(`Dashboard/DashboardDetail`, demoParams).then((x)=>{
    setMngrdata(x?.data?.data||{});
      setIsLoading(false) 
    }).catch((err)=>{
      setIsLoading(false) 
    })
    // setMngrdata(result?.data?.data);

    // dispatch(areaMngrData(result.data));
  };
  useEffect(() => {
    if (Array?.isArray(schemeApprove?.data)?.length != 0) {
      setMngrdata(schemeApprove?.data?.data);
    }
  }, [schemeApprove]);
  useEffect(() => {
    if (
      Array?.isArray(stateWiseData?.lisitems) &&
      stateWiseData?.lisitems?.length != 0
    ) {
      setMngrdata(stateWiseData?.lisitems);
    } else {
      setIsLoading(false);
      setMngrdata([]);
    }
  }, [stateWiseData]);

  useEffect(() => {
    const currentFiscalYear = getCurrentFiscalYear1(dayjs(startMonth).format());
    const demoParams = {
      fyear: currentFiscalYear,
      schemeType: selectedSchemeType,
      state: selectedStateId,
      status: selectedStatus,
      userid: userId,
      userRole: userRole,
      pageNumber: collectionData.pageNumber || 1,
      pageSize: collectionData.pageSize || 150,
      sortingOrder:collectionData.sortingOrder=="ascend"?"ASC":"DESC",
      sortingType:collectionData.sortingType||"DealerCode"
    };
    if (selectedStatus === "") {
      delete demoParams.status;
    }
    if (dashboardType === STATE_WISE_DASHBOARD_LABEL) {
      // const fetchStateWiseReport = async (demoParams) => {
      //   demoParams = {
      //     ...demoParams,
      //     fYear: currentFiscalYear,
      //     stateId: selectedStateId,
      //     year:(typeof monthlyFilters?.year=="string"&&monthlyFilters?.year)?monthlyFilters?.year-1:yearShow?yearShow-1:dayjs().year()-1,
      //     enforcedMonth: monthlyFilters?.enforcedMonth,
      //     month: monthlyFilters?.month||4,
      //     endMonth: monthlyFilters?.monthEnd||3,
      //     userid: userId,
      //     userRole: userRole,
      //     pageNumber: collectionData.pageNumber,
      //     pageSize: collectionData.pageSize,
      //     sortingOrder:collectionData.sortingOrder=="ascend"?"ASC":"DESC",
      //     sortingType:collectionData.sortingType||"DealerCode"
      //   };
      //   delete demoParams.fyear;
      //   delete demoParams.state;
      //   // setSelectedSchemeType(demoParams.schemeType)
      //   await dispatch(getStateWiseDataApi(demoParams));
      //   // const result = await axiosAPI.post(`Dashboard/StateWiseReports`,demoParams);
      //   // setMngrdata(result?.data?.data)
      //   // dispatch(areaMngrData(result?.data?.data));
      // };
      // fetchStateWiseReport(demoParams);
      const fetchStateWiseReport = async (demoParams) => {
        let lastTwoDigits = yearShow % 100;
        demoParams = {
          ...demoParams,
          fYear: "FY" + lastTwoDigits,
          stateId: selectedStateId,
          year:monthlyFilters?.month && [1, 2, 3].includes(parseInt(monthlyFilters.month))
          ? "20" + lastTwoDigits
          : lastTwoDigits
          ? "20" + (parseInt(lastTwoDigits) - 1):
            typeof monthlyFilters?.year == "string"
              ? monthlyFilters?.year
              : yearShow - 1,
          enforcedMonth: monthlyFilters?.enforcedMonth,
          month: monthlyFilters?.month || 0,
          endMonth: monthlyFilters?.monthEnd || 0,
          userid: userId,
          userRole: userRole,
          PageNumber: collectionData.pageNumber,
          PageSize: collectionData.pageSize,
          sortingOrder:
            collectionData.sortingOrder == "ascend" ? "ASC" : "DESC",
          sortingType: collectionData.sortingType || "DealerCode",
        };
        delete demoParams.fyear;
        delete demoParams.state;
        await dispatch(getStateWiseDataApi(demoParams));
      };
      //schemeListApprove

      fetchStateWiseReport(demoParams);
    } else {
      // if (userRole == "ZADM") {
      //   let apiData = {
      //     fYear: currentFiscalYear,
      //     stateId: selectedStateId,
      //     year: currentYear,
      //     schemeType: selectedSchemeType,
      //     userid: userId,
      //     userRole: userData?.userRole,
      //     pageNumber: collectionData.pageNumber || 1,
      //     pageSize: collectionData.pageSize || 10
      //   };
      //   dispatch(schemeListApproveApi(apiData))
      //   let result = axiosAPI.post(`Dashboard/DashboardDetail`, demoParams);
      //   if (result?.data?.data) {
      //     let { submittedCount, pendingCount, approvedCount } = result?.data?.data
      //     userRole != "ZADM" && onSetCardData({
      //       pendingCount: pendingCount || 0,
      //       approvedCount: approvedCount || 0,
      //       submittedCount: submittedCount || 0
      //     });
      //   }
      // } else {
      fetchGeneratedScheme(demoParams);
      // }
    }
  }, [
    selectedSchemeType,
    selectedStateId,
    selectedStatus,
    dashboardType,
    monthlyFilters,
    collectionData,
    startMonth
  ]);

  // useEffect(() => {
  //     setMngrdata({
  //         ...mngrdata,
  //         schemelist: listAreaMngrData.schemelist,
  //     });
  // }, [listAreaMngrData]);
  let schemeList=mngrdata?.schemelist||[]
  useEffect(() => {
    const data = [],
      schemeListData = userRole == "ZADM"&&mngrdata?.lisitems
      ?mngrdata?.lisitems:
      userRole == "ZADM"
          ? schemeList
          : dashboardType === STATE_WISE_DASHBOARD_LABEL
          ? mngrdata
          : schemeList;
    onSetCardData({
      pendingCount: mngrdata?.pendingCount || 0,
      approvedCount: mngrdata?.approvedCount || 0,
      submittedCount: mngrdata?.submittedCount || 0,
      rejectedCount: mngrdata?.rejectedCount || 0,
      totalApprovedCount: mngrdata?.totalApprovedCount || 0,
      totalPendingCount: mngrdata?.totalPendingCount || 0,
      totalSubmittedCount: mngrdata?.totalSubmittedCount || 0,
      totalRejectedCount: mngrdata?.totalRejectedCount || 0,
      autoRejectCount: mngrdata?.totalAutoRejectCount || 0,
    });
    if (schemeListData?.length == 0) {
      setDataSource([]);
      // userRole != "ZADM" &&
      // onSetCardData({
      //   pendingCount: 0,
      //   approvedCount: 0,
      //   submittedCount: 0,
      //   totalApprovedCount:0,
      //   totalPendingCount:0,
      //   totalSubmittedCount:0
      // });
      return;
    }
    schemeListData?.map((item, index) => {
      for (let i = 0; i < 1; i++) {
        let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
        let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");

        data.push({
          status: item.status,
          key: item.schemeId,
          scheme: item,
          code: item.dealerCode,
          name: item.dealerName,
          Location: item.dealerLocation,
          state: item.stateName,
          Manager: item.territoryManager,
          StartMonth:
            formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
          EndMonth:
            formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
          // industry: 102,
          b: item.billing,
          c: item.collection,
          i: item.installation,
          Target: item.installationTarget,
          IPT: item.incPerTarget,
          P1: item.p1,
          // nos: 50,
          // days: 0,
          // nov21: null,
          // dec21: null,
          // lac: 66,
          BooksCollectionTargetT1: item.t1,
          BooksCollectionTargetT2: item.t2,
          BooksCollectionTargetT2T1: item.t2_T1,
          BooksCollectionTargetT3: item.t3,
          BooksCollectionTargetT3T2: item.t3_T2,
          PayoutRatep1: item.r1,
          PayoutRatep2: item.r2,
          PayoutRatep3: item.r3,
          payoutR1: item.p1,
          payoutR2: item.p2,
          payoutR3: item.p3,
          reason: item.reason,
          holdReason:item.holdReason,
          TEPvol: item.volume,
          TEPpertr: item.perTr,
          TEPamount: item.amount,
          actual_Installation_Last_Year_Month:item?.actual_Installation_Last_Year_Month,
          actualInstallation:item.actualInstallation,
            payout1:item.payout,
            payout2:item.payout2,
            payout3:item.payout3,
            payout4:item.payout4,
            holdStatus:item?.holdStatus
          // LMSA: 'T2',
          // LCQ: 55,
          // LMSA1: 'T1',
          // LCQ1: 18000
        });
      }
      setDataSource(data);
    });
    // userRole != "ZADM" &&
    // onSetCardData({
    //   pendingCount: mngrdata?.pendingCount||0,
    //   approvedCount: mngrdata?.approvedCount||0,
    //   submittedCount: mngrdata?.submittedCount||0,
    //   totalApprovedCount:mngrdata?.totalApprovedCount||0,
    //   totalPendingCount:mngrdata?.totalPendingCount||0,
    //   totalSubmittedCount:mngrdata?.totalSchemeSubmitted||0,
    // });
  }, [mngrdata, dashboardType]);
  const handleChange = (pagination, filters, sorter) => {
    setCollectionData({...collectionData,"sortingOrder":sorter?.order,"sortingType":sorter?.columnKey})
    // getApiData()
  }

  // const userInfo = decryptData(sessionStorage.getItem("userInfo"));
  // const userRole = userInfo.userRole;

  // const captalizeObjKeys = (obj) =>
  //   Object.fromEntries(
  //     Object.entries(obj).map(([key, value]) => [key.toUpperCase(), value])
  //   );



  return (
    <>
      {/* <button
        className="action_button"
        style={{ color: "white" }}
        onClick={handleDownload}
      >
        download
      </button> */}
      <Table
        // size="small"
        loading={
          loadingScheme == "pending" || loadingState == "pending" ? true : false
        }
        summary={(pageData) => {
          const fieldSum = {
            installationExpected: 0,
            incPerTarget: 0,
            P1: 0,
            actualInstallation:0,
            payout1:0,
            payout2:0,
            payout3:0,
            payout4:0
          };
          pageData.forEach(({ Target, IPT, P1,payout1,payout2,payout3,payout4 ,actualInstallation}) => {
            fieldSum.installationExpected += Target ? parseInt(Target) : 0;
            fieldSum.incPerTarget += IPT ? parseInt(IPT) : 0;
            fieldSum.P1 += P1 ? parseInt(P1) : 0;
            fieldSum.actualInstallation += actualInstallation ? parseInt(actualInstallation) : 0;
            fieldSum.payout1 += payout1 ? parseInt(payout1) : 0;
            fieldSum.payout2 += payout2 ? parseInt(payout2) : 0;
            fieldSum.payout3 += payout3 ? parseInt(payout3) : 0;
            fieldSum.payout4 += payout4 ? parseInt(payout4) : 0;
          });
          return (
            <Table.Summary.Row style={{ fontWeight: "600", fontSize: "18px" }}>
              <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
              <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
              <Table.Summary.Cell index={7}></Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>
              <Table.Summary.Cell index={9}>
                {fieldSum.installationExpected
                  ? fieldSum.installationExpected
                  : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={10}>
                {Math.round(fieldSum.P1 / fieldSum.installationExpected)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={11}>
                {fieldSum.P1 ? fieldSum.P1 : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>

              {dashboardType === STATE_WISE_DASHBOARD_LABEL && (
                <>
                  <Table.Summary.Cell index={51}>
                    {fieldSum.actualInstallation
                      ? fieldSum.actualInstallation
                      : 0}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={57}>
                    {fieldSum?.payout1 || 0}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={58}>
                  {fieldSum?.payout2 || 0}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={59}>
                  {fieldSum?.payout3 || 0}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={60}>
                  {fieldSum?.payout4 || 0}
                  </Table.Summary.Cell>
                </>
              )}
            </Table.Summary.Row>
          );
        }}
        pagination={false}
        columns={columns}
        onChange={handleChange}
        dataSource={dataSource}
        scroll={{
          x: 2000,
          y: 800,
        }}
        bordered
      />
      <div className="d-flex flex-column">
        <TableColorDetails />

        <Pagination
          defaultCurrent={1}
          showSizeChanger
          className="paginationDesign"
          // style={{ position: "absolute", right: "12px", marginLeft: "500px" }}
          current={collectionData.pageNumber}
          onChange={(pageNumber, pageSize) =>
            setCollectionData({ ...collectionData, pageNumber, pageSize })
          }
          total={
            stateWiseData?.totalcount || schemeApprove?.data?.totalCountScheme
          }
          pageSize={collectionData.pageSize}
pageSizeOptions={['150', '250', '350']}
        />
      </div>      {isLoading&&<FullScreenLoader />}
    </>
  );
};
export default UnconditionalInstallation;
