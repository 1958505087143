import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  EditOutlined,
  DownloadOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Button, Space, Dropdown, message, DatePicker } from "antd";
import { Link } from "react-router-dom";
import "./actionButton.css";
import { ReactComponent as Communicate } from "../../assets/vector/communicate.svg";
import { ReactComponent as Download } from "../../assets/vector/download.svg";
import { ReactComponent as Deviation } from "../../assets/vector/deviation.svg";
import RoleModal from "../modal/roleModal/index";
import { DeviationModal } from "../modal/deviationModal";
import "../statusBox/status.css";
import axiosAPI from '../../services/axiosApi';
import { getCurrentFiscalYear } from "../../utils/dateFormatter";
import ToastifyShow from "../ToastifyShow";
import { decryptData } from "../../utils/localStorageEncodeDecode";

const currentFiscalYear = getCurrentFiscalYear();

const ActionButton = ({

  selectType,
  status = 0,
  handleStatus,
  schemeProgress = false,
  TargetOnly=false,
  showAction = true,
  onGetData,
  handleRequest,
  handleDownload1
}) => {
  const location = useLocation();
  const [items, setItems] = useState([]);
  const [selectedSchemeType, setSelectedSchemeType] = useState(1);

  const [openModal, setOpenModal] = useState(false);
  const [openDeviationModal, setDeviationModal] = useState(false);
  const [openRoleModal, setOpenRoleModal] = useState(false);

  const [test, setTest] = useState(false);
  // const [closeModal,setCloseModal] =useState(true);
  const {UserInfo,userRole,userId} = decryptData(sessionStorage.getItem("userInfo"));
  const showActionINAdmin=   !["NSH", "PMIS", "COSM"].includes(userRole) ;


  const handleDownload = () => {
    const fyear = currentFiscalYear;
    const isDraft = 0;
    const schemeType = selectedSchemeType;
    const payload = {
      userid: userId,
      status: 2,
      // "stateId": 0,
      // "stateHeadId": 0,
      // "areaManagerId": 0,
      // "zonalHeadId": 58,
      // "runningMonth": 0,
      userRole,
      fyear,
      schemeType,
      // "state": 0,
      "pageSize": 150,
      "pageNumber": 1,
      // "dealerLocation": "",
      // "schemeNumber": "",
      // "dealerCode": "",
      // isDraft
    }
    axiosAPI.post(`Dashboard/GetDashboardExcel`, payload)
      .then((result) => {
        const data = result.data.data.downloadExcels || result.data.data.singleTargetWithUppers
          || result.data.data.singleTargets || result.data.data.slabbasedExcels
          || result.data.data.unConditionalInstallations;
        if (data?.length == 0) {
          ToastifyShow("Something went wrong", "error")
          return false
        }
        const headers = Object.keys(data[0]).join("\t");
        const rows = data?.map((obj) =>
          Object.values(obj)
            .map((val) => {
              if (typeof val === "string") {
                // Replace line breaks with CHAR(10) function

                return val.replace(/\n/g, "CHAR(10)");
              } else {
                return val;
              }
            })
            .join("\t")
        );

        const csv = `${headers}\n${rows.join("\n")}`;
        const url = window.URL.createObjectURL(
          new Blob([csv], { type: "application/vnd.ms-excel" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${Date.now()}.xls`);
        document.body.appendChild(link);
        link.click(); // const data = formatData(result.data.data); // dispatch(schemeList(data));
      });
  };

  const communicatePage = [
    {
      label: <div onClick={() => handleRoleModal()}>communicate</div>,
      key: "6",
      icon: <Communicate />,
    },
    {
      label: <span className="link" onClick={() => handleDownload()}>
        Download
      </span>,
      key: "7",
      icon: <Download />,
    },
  ];

  const dashBoardPage = [
    // {
    //   label: "Edit",
    //   key: "1",
    //   icon: <EditOutlined />,
    // },
    {
      label: (<span className="link" onClick={() => handleDownload1()}>
        Download
      </span>
      ),
      key: "2",
      icon: <DownloadOutlined />,
    },
  ];

  const handleRoleModal = () => {
    setOpenRoleModal(!openRoleModal);

  }
  const handleDeviationModal = () => {
    setDeviationModal(!openDeviationModal);
  };

  const subPage =
    [
      {
        label: location.pathname?.includes("schemeProgress") ? (
          <Link className="link" to="/schemeProgress/edit">
            Edit
          </Link>
        ) : (
          "Edit"
        ),
        key: "1",
        icon: <EditOutlined />,
      },
      {
        label: <div style={{ color: "#51DE4E" }} onClick={() => handleStatus(2)}>Approve</div>,
        key: "2",
        icon: <CheckOutlined className="approved-icon" />,
      },
      {
        label: <div style={{ color: "#FF3232" }} onClick={() => handleStatus(5)}>Reject</div>,
        key: "3",
        icon: <CloseOutlined className="reject-icon" />,
      },
      // {
      //   label: <div style={{color:"black"}} onClick={() => handleDownload()}>Download</div> ,
      //   key: "4",
      //   icon: <DownloadOutlined className="downloader-icon" />,
      // },
    ];

  const handleModal = () => {
    setOpenModal(!openModal);
  };
  const handleOk = (amount, quantity, remarks) => {
    setTest(!test);
    setDeviationModal(false);

    setOpenModal(false);
    handleRequest(amount, quantity, remarks);
  };

  const handleCommunicateModal = () => {
    axiosAPI.post(`Dashboard/MailTemplate`)
      .then(data => {
      })
  }

  const deviationRequest = [
    {
      label: "Request",
      key: "1",
      icon: <Deviation style={{ width: "5px" }} />,
      onClick: () => handleModal(),
    },
    // {
    //   label: "Download",
    //   key: "2",
    //   icon: <DownloadOutlined />,
    // },
  ];

  

  const handleSchemeDropdown = (schemeType) => {
    setSelectedSchemeType(schemeType);
    const demoParams = {
      fyear: currentFiscalYear,
      schemeType: schemeType,
      userid: userId,
      userRole: userRole,
    };
    onGetData(demoParams);
  };

  const schemeTypeData = [
    {
      key: "1",
      label: <a onClick={() => handleSchemeDropdown(1)}>Collection Scheme - With Delivery Plan</a>,
    },
    {
      key: "2",
      label: (
        <a onClick={() => handleSchemeDropdown(2)}>Collection Scheme - Without Delivery Plan</a>
      ),
      disabled: false,
    },
    {
      key: "3",
      label: <a onClick={() => handleSchemeDropdown(3)}>Installation - Slab Based Scheme</a>,
    },
    {
      key: "4",
      label: <a onClick={() => handleSchemeDropdown(8)}>Installation - Single Target Scheme</a>,
    },
    {
      key: "5",
      label: (
        <a onClick={() => handleSchemeDropdown(4)}>
          Installation - Single Target With Upper Cap Scheme
        </a>
      ),
    },
    {
      key: "6",
      label: (
        <a onClick={() => handleSchemeDropdown(5)}>
          Installation - Unconditional Scheme
        </a>
      ),
    },
  ];

  const getSchemeType = (schemeType) => {
    if (schemeType == 1) {
      return "Collection Scheme - With Delivery Plan";
    }
    if (schemeType == 2) {
      return "Collection Scheme - Without Delivery Plan";
    }
    if (schemeType == 3) {
      return "Installation - Slab Based Scheme";
    }
    if (schemeType == 8) {
      return "Installation - Single Target Scheme";
    }
    if (schemeType == 4) {
      return "Installation - Single Target With Upper Cap Scheme";
    }
    if (schemeType == 5) {
      return "Installation - Unconditional Scheme";
    }
  };

  useEffect(() => {
    if(location.pathname?.includes("requestDeviationLog")){
      setSelectedSchemeType(1)
    }
  }, [location.pathname])
  
  useEffect(() => {
    if (location.pathname?.includes("dashboard")) {
      setItems(dashBoardPage);
    } else if (location.pathname?.includes("/collection/schemeStatus")) {
      setItems(communicatePage);
    } else if (location.pathname?.includes("requestDeviation")) {
      setItems(deviationRequest);
    } else {
      setItems(subPage);
    }
  }, [location.pathname, handleStatus, selectedSchemeType]);


  return (
    <div className="demo">

      <Dropdown
        className="action_button"
        menu={{ items: TargetOnly?schemeTypeData?.filter((data)=>data.key!="6"&&data.key!="5"): schemeTypeData}}
        trigger={["click"]}
      >
        <Button className="collection-slab-based">
          <Space className="button_Type">
            {getSchemeType(selectedSchemeType)}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>

      {!["/approvedschemeslog", "requestDeviationLog ", "/trackModification", "/dashboard"].includes(location?.pathname) && showActionINAdmin && showAction && (
        <Dropdown
          className="action_button"
          menu={{
            items,
          }}
          trigger={["click"]}
        >
          <Button>
            <Space className="button_Type">
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      )}


      {openRoleModal && (
        <RoleModal
          openModal={openRoleModal}
          handleModal={handleRoleModal}
          handleOk={handleCommunicateModal}

        />
      )}
      

      { openModal && (
        <DeviationModal
          openModal={openModal}
          handleModal={handleModal}
          handleRequest={handleOk}
        />
      )}
    </div>
  );
};

export default ActionButton;
