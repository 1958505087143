import { decryptData } from "./localStorageEncodeDecode";

export const getDecryptedSessionData = (key) => {
  try {
    const encryptedData = sessionStorage.getItem("userInfo");
    if (!encryptedData) {
      console.warn(`No data found for key: ${key}`);
      return null;
    }

    const decryptedData = decryptData(encryptedData);
    if (typeof decryptedData !== "object" || decryptedData === null) {
      console.error(
        `Decryption failed or data is not an object for key: ${key}`
      );
      return null;
    }

    return decryptedData;
  } catch (error) {
    console.error(
      `Error retrieving or decrypting sessionStorage data for key: ${key}`,
      error
    );
    return null;
  }
};
