import React, { useEffect, useState, useContext, useRef } from "react";
import "./InstallationCreateScheme.css";
import { Button, Table, Divider, Col, Row, Form, Input } from "antd";
import { DownloadOutlined, SaveOutlined } from "@ant-design/icons";
import { ReactComponent as HideIcon } from "../../../assets/vector/hide.svg";
import { ReactComponent as MergeIcon } from "../../../assets/vector/merge.svg";
import { ReactComponent as UnmergeIcon } from "../../../assets/vector/unmerge.svg";
import { ReactComponent as ChangeScheme } from "../../../assets/vector/changeScheme.svg";
import { ReactComponent as UnHideIcon } from "../../../assets/vector/unHide.svg";
import { useDispatch, useSelector } from "react-redux";
import { installationSchemeList } from "../../../redux/installation/SchemeList";
import {
  add,
  fetchgenerateScheme,
} from "../../../redux/installation/PostScheme";
import dayjs from "dayjs";
import ChangeSchemeModal from "../../../components/changeSchemeModal";
import SMDialog from "../../../components/modal/alertModal/CommonModal";
import AlertModal from "../../../components/modal/alertModal/AlertModal";
import StatusModal from "../../../components/modal/alertModal/StatusModal";
import { getCurrentFiscalYear, getCurrentFiscalYear1 } from "../../../utils/dateFormatter";
import { slabBasedSchema } from "../../../utils/schemeValidations";
import ValidationErrorsModal from "../../../components/modal/alertModal/ValidationErrorsModal";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import useTableSort from "./useTableSort";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import ValidationDifferentSchemeError from "../../../components/modal/alertModal/ValidationDifferentSchemeError";
import ToastifyShow from "../../../components/ToastifyShow";


//functional Component Start
const currentFiscalYear = getCurrentFiscalYear()
const SingleTarget = ({
  startDate,
  endDate,
  dealerCode,
  allListData,
  schemeType,
}) => {
  const formik = useFormik({
    initialValues: [],
  });

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const EditableContext = React.createContext(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editRow, setEditingRow] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [showHide, setShowUnhide] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [openSchemeModal, setOpenSchemeModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [changeSchemeDate, setchangeSchemeDate] = useState([]);
  const [singleTargetList, setSingleTargetList] = useState([]);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [openDraftModal, setOpenDraftModal] = useState(false);
  const [dataHandle, setDataHandle] = useState([]);
  const [codeError, setCodeError] = useState([]);
  const [dataDraftHandle, setDataDraftHandle] = useState([])
  const [errorItem, setErrorItem] = useState({
    firstItem: null,
    secondItem: null,
  });
  const [schemeIdNo, setSchemeIdNo] = useState([]);
  const [openErrorDialog, setOpenErrorDialog] = useState({ open: false, errorsFields: [] });
  const selectedRow = JSON.parse(sessionStorage.getItem("rowData"));
  const schemeIds = selectedRow?.map((schemeKey) => schemeKey.scheme.schemeId);
  React.useEffect(() => {
    if (singleTargetList && singleTargetList.length) {
      const formattedData = formatData(singleTargetList);
      dispatch(installationSchemeList(formattedData));
    }
  }, [startDate, endDate, dealerCode, singleTargetList]);
  const [sortData, setSort] = useTableSort(formik?.values)
  const currentFiscalYear = getCurrentFiscalYear1(startDate)
  const handleModal = () => {
    setOpenModal(!openModal);
  };
  const handleErrorModal = (firstItem, secondItem, x) => {
    setOpenErrorModal(x);
    setErrorItem({
      firstItem,
      secondItem,
    });
  };
  const handleSaveModal = () => {
    setOpenSaveModal(!openSaveModal);
  };
  const handleSuccessModal = () => {
    setOpenSuccessModal(!openSuccessModal);
  };
  const handleSelectModal = () => {
    setOpenSelectModal(!openSelectModal);
  };
  const handleDraftModal = () => {
    setOpenDraftModal(!openDraftModal);
  }
  const listData = useSelector((state) => state.installationSchemeList.data);

  /* DISCLAIMER: REMOVE IF THERE IS ANY ISSUE WHILE CHECKBOX SELECTION */
  useEffect(() => {
    //;
    if (listData?.length && dealerCode?.length) {
      const selectedKeys = listData.filter(listItem => dealerCode.includes(listItem.code)).map(listItem => listItem.key);
      setSelectedRowKeys(selectedKeys);
    }
  }, [dealerCode, listData]);


  const calculateFormula = (index) => {
    let y = formik?.values[index];
    let { Target, IPT } = formik?.values[index];

    if (
      Target != null &&
      IPT != null &&
      parseInt(IPT) <= parseInt(Target)
    ) {
      handleErrorModal("IPT", "Target", true);
    }

    if (Target && IPT) {
      formik?.setFieldValue(index, {
        ...y,
        payout: parseInt(Target) * parseInt(IPT)
      });
    }

  };

  // Columns for table
  const defaultColumns = [
    {
      title: <div onClick={() => setSort('code')} >Dealer Code</div>,
      dataIndex: "code",
      fixed: "left",
      width: "180px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerCode",
    },
    {
      title: "Dealer Name",
      dataIndex: "name",
      fixed: "left",
      width: "180px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerName"
    },
    {
      title: "State",
      dataIndex: "state",
      width: "120px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "StateName",
    },
    {
      title: "Dealer Location",
      dataIndex: "Location",
      width: "190px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerLocation",
    },

    {
      title: "Territory Manager",
      dataIndex: "Manager",
      width: "200px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "TerritoryManager",
    },
    {
      title: "Start Month",
      dataIndex: "StartMonth",
      width: "160px",
    },
    {
      title: "End Month",
      dataIndex: "EndMonth",
      width: "160px",
    },
    {
      title: "Actual Installation Last Year Month",
      dataIndex: "actual_Installation_Last_Year_Month",
      width: "260px",
    },
    {
      title: "Installation Target",
      dataIndex: "Target",
      editable: true,
      width: "190px",
      render: (x, y, index) => (
        <input
          name={formik?.values?.[index]?.Target}
          placeholder="Type here"
          value={formik?.values?.[index]?.Target}
          onBlur={() =>
            calculateFormula(index)
          }
          onChange={(e) => {
            let value = e.target?.value.replace(/[^\d]/g, "");
            formik.setFieldValue(index, {
              ...y,
              Target: value,
            });
          }}
          className="input1"
          style={{ width: "90%", padding: "5px" }}
        />
      ),
    },


    {
      title: "inc. Per Tractor",
      dataIndex: "IPT",
      editable: true,
      width: "190px",
      render: (x, y, index) => (
        <input
          name={formik?.values?.[index]?.IPT}
          placeholder="Type here"
          value={formik?.values?.[index]?.IPT}
          onBlur={() =>
            calculateFormula(index)
          }
          onChange={(e) => {
            let value = e.target?.value.replace(/[^\d]/g, "");
            formik.setFieldValue(index, {
              ...y,
              IPT: value,
            });
          }}
          className="input1"
          style={{ width: "90%", padding: "5px" }}
        />
      ),
    },
    {
      title: "Payout",
      dataIndex: "payout",
      width: "120px",
    },
    {
      title: "Remarks",
      dataIndex: "reason",
      editable: true,
      width: '120px',
      render: (x, y, index) => (
        <input
          name={formik?.values?.[index]?.reason}
          placeholder="Type here"
          value={formik?.values?.[index]?.reason}
          onBlur={() =>
            calculateFormula(index)
          }
          onChange={(e) => {
            let value = e.target?.value;
            const sanitizedValue = value
              ?.replace("<script>", "")
              ?.replace("</script>", "")
             .replace(/[^a-zA-Z0-9\s]/g, "");
            formik.setFieldValue(index, {
              ...y,
              reason: sanitizedValue,
            });
          }}
          className="input1"
          style={{ width: "90%", padding: "5px" }}
        />
      ),
    }
  ];


  useEffect(() => {
    formik.setValues(getFilterData(listData)).then((x) => {
      formik?.values?.map((x, index) => {
        calculateFormula(index)
      })
    })
  }, [listData])

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    if (
      showHide &&
      selectedRowKeys.length > 0 &&
      selectedRowKeys.includes({ ...props }["data-row-key"])
    )
      return;
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };

    const save = async () => {
      try {
        //;

        const values = await form.validateFields();
        toggleEdit();
        handleSave(
          {
            ...record,
            ...values,
          },
          values
        );
      } catch (errInfo) { }
    };
    const handleInputChange = (e, dataIndex) => {
      //;
      const isInteger = /^[1-9]+$/;
      const { name, value } = e.target;

      if (name == "Remarks") {
        form.setFieldsValue({
          [dataIndex]: e.target.value,
        });
        return;
      }

      if (
        (e.target.value !== "" && e.target.value == 0) ||
        e.target.value < 0 ||
        !Number.isInteger(+e.target.value)
      ) {
        handleModal();
        setDirty(true);
        form.resetFields();
        return;
      }
      if (
        e.target.value !== "" &&
        e.target.value !== 0 &&
        isInteger.test(+e.target.value)
      ) {
        form.setFieldsValue({
          [dataIndex]: e.target.value,
        });
      } else if (e.target.value == 0 && e.target.value != "") {
        handleModal();
        setDirty(!dirty);
        form.resetFields();
      }
    };

    let childNode = children;
    if (editable && record.status !== 1 && record.status !== 2) {
      // if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            type={dataIndex !== "reason" && "number"}
            name={title}
            onChange={(value) => {
              handleInputChange(value, dataIndex);
            }}
          />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
            height: 20,
            display: "block",
            // background: 'red',
            border: "1px solid #d9d9d9",
            borderRadius: "5px",
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
  };

  const handleSave = (row, form) => {
    //;

    // if (row.t2 != null && row.t1 != null && +row.t2 <= +row.t1) {
    //   handleErrorModal("T2", "T1");
    //   return;
    // }

    // if (row.r2 != null && row.r1 != null && +row.r2 <= +row.r1) {
    //   handleErrorModal("R2", "R1");
    //   return;
    // }
    const newData = [...listData];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = { ...newData[index], ...form };

    // if (item.target && item.r1) {
    //   item.p1 = parseInt(item.target) * parseInt(item.r1);

    // }
    // if (item.IPT && item.r2) {
    //   item.p2 = parseInt(item.IPT) * parseInt(item.r2);
    // }

    newData.splice(index, 1, {
      ...row,
      ...item,
    });
    dispatch(installationSchemeList(newData));
  };
  const mapColumns = (col) => {
    if (!col.editable) {
      return col;
    }
    const newCol = {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }
    return newCol;
  };
  const columns = defaultColumns.map((col) => {
    return mapColumns(col);
  });

  const draftData = (e) => {
    e.preventDefault();
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const userName = userData.userName;
    const finalData = [];

    let checkValidation = formik?.values?.filter(
      (x) =>
        !x?.Target ||
        !x?.IPT ||
        !x?.reason
    );

    //;

    let errorDetails = Object.entries(
      checkValidation?.[0] ? checkValidation?.[0] : {}
    )?.filter(
      (x, index) =>
        !x[1] &&
        (x[0] === "Target" ||
          x[0] === "IPT" ||
          x[0] === "reason")
    )?.map((x) =>
      x[0] === "Target" ? "Target" : x[0] === "IPT" ? "inc. Per Tractor" : x[0] === "reason" ? "Reason" : ""
    );


    let data = []
    formik?.values.forEach((dataItem) => {
      data.push({
        dealerCode: dataItem.code,
        state: dataItem.stateId,
        dealerId: +dataItem.key,
        dealerLocation:dataItem.Location,
        dealerName:dataItem.name,
        // StartMonth: selectedRowKeys.includes(dataItem.key)
        //   ? changeSchemeDate.startDate
        //   : startDate,
        StartMonth: startDate,
        EndMonth: endDate,
        // EndMonth: selectedRowKeys.includes(dataItem.key)
        //   ? changeSchemeDate.endDate
        //   : endDate,
        reason: dataItem.reason,
        incPerTarget: +dataItem.IPT,
        installationTarget: +dataItem.Target,
        p1: +dataItem.payout,
        // status: 1,
        // delayPlan: 0,
        // billing: +dataItem.feb22b,
        // installation: +dataItem.feb22i,
        // collection: +dataItem.feb22c,
        isSAP: true,
        isActive: true,
        schemePlanStartMonth: startDate,
        schemePlanEndMonth: endDate,
        schemeTypeId: 8,
        // state: 19,
        // t1: +dataItem.t1,
        // t2: +dataItem.t2,
        // t3: +dataItem.BooksCollectionTargetT3,
        // t2_T1: dataItem.BooksCollectionTargetT2T1,
        // t3_T2: dataItem.BooksCollectionTargetT3T2,
        // payout: +dataItem.payout,
        // p2: dataItem.p2,
        // p3: dataItem.payoutR3,
        // r1: +dataItem.r1,
        // r2: +dataItem.r2,
        // r3: +dataItem.PayoutRatep3,
        // volume: +dataItem.TEPvol,
        // perTr: dataItem.TEPpertr,
        // amount: dataItem.TEPamount,
        // isSubmitted: 0,
        fyear: currentFiscalYear,
        //schemeType: schemeType,
        userid: +userId,
        username: userName,
      })
    })
    let extraValidation = []
    formik?.values?.map((x, index) => {
      let topTargetDetails = x?.dropdownscheme
      let errors = [];

      if (!x.IPT) {
        errors.push("Please enter Inc. Per Tractor");
      }
      if (!x.Target) {
        errors.push("Please enter Installation Target");
      }

      if (errors.length > 0) {
        extraValidation[x?.code] = errors;
      }
    });
    setCodeError(Object?.keys(extraValidation || {}));

    if (Object?.keys?.(extraValidation)?.length != 0) {
      showErrorDialog(extraValidation);
    }
    else {
      handleSuccessModal();
      setDataHandle(data);
    }
  };

  const formatData = (result) => {

    return result.map((item, index) => {
      // let formattedStartDate = selectedRowKeys.includes(item.admDealerId)
      //   ? changeSchemeDate?.startDate
      //   : startDate;
      let formattedStartDate = dayjs(startDate).format("MMM'YY");

      // let formattedEndDate = selectedRowKeys.includes(item.admDealerId)
      //   ? changeSchemeDate?.endDate
      //   : endDate;
      let formattedEndDate = dayjs(endDate).format("MMM'YY");

      return {
        status: item.status,
        key: item.admDealerId,
        code: item.dealerCode,
        name: item.dealerName,
        Location: item.cityName,
        state: item.stateName,
        Manager: item.territoryName,
        StartMonth: formattedStartDate,
        EndMonth: formattedEndDate,
        // Target: item.installationTarget,
        payout: item.payout !== undefined ? item.payout : '',
        stateId: item.stateId,
        Target: item.Target || "",
        IPT: item.IPT || "",
        reason: item.reason || "",
        actual_Installation_Last_Year_Month: item?.actual_Installation_Last_Year_Month,
        // reason:
        // key: item.DealerCode,
        // code: item.DealerCode ,
        // name: item.DealerName,
        // Location: item.DealerLocation,
        // state: item.State,
        // Manager: item.TerritoryManager,
        // industry: item.Feb21.Industry,
        // b: 35,
        // i: 45,
        // c: 45,
        // target: "T1",
        nos: null,
        // days: item.Feb22OpeningStock.Days,
        // nov21: item.Nov21,
        // dec21: item.Dec21,
        // lac: 32,
        BooksCollectionTargetT1: null,
        BooksCollectionTargetT2: null,
        // BooksCollectionTargetT2T1: 5,
        BooksCollectionTargetT3: null,
        // BooksCollectionTargetT3T2: 5,
        PayoutRatep1: null,
        PayoutRatep2: null,
        PayoutRatep3: null,
        // payoutR1: 33000,
        // payoutR2: 52000,
        // payoutR3: 42000,
        TEPvol: null,
        // TEPpertr: 6000,
        // TEPamount: 33000,
        // LMSA: "T3",
        // LCQ: 55,
        // LMSA1: "T2",
        // LCQ1: 33000,
      };
    });
  };

  useEffect(() => {
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const userRole = userData.userRole;
    const staticAmId = 3468;
    const data = formatData(allListData);
    setSingleTargetList(allListData);
    dispatch(installationSchemeList(data));
  }, [allListData]);

  const handleChangeScheme = (data) => {
    //;
    setOpenSchemeModal((openSchemeModal) => !openSchemeModal);
    if (data?.startDate) {
      setchangeSchemeDate(data);
      // API CALL FOR UPDATE
      // SELECTED RECORD UPDATE
    }
  };

  // Table header
  const tableHeader = () => {
    const handleHide = () => {
      setShowUnhide(!showHide);
    };

    return (
      <Row className="table_sub_buttons">
        {/* <Col> */}
        {/* <Button
            icon={showHide === true ? <UnHideIcon /> : <HideIcon />}
            type="text"
            style={{
              margin: "10px",
              color: showHide === true ? "#FF3232" : "",
            }}
            onClick={handleHide}
          >
            {showHide === true ? "Show" : "Hide"}
          </Button>
        </Col>
        <Col>
          <Button type="text" style={{ margin: "10px" }}>
            <MergeIcon />
            Merge
          </Button>
        </Col>
        <Col>
          <Button type="text" style={{ margin: "10px" }}>
            <UnmergeIcon />
            Unmerge
          </Button>
        </Col> */}
        <Col>
          <Button
            onClick={handleChangeScheme}
            type="text"
            style={{ margin: "10px" }}
          >
            <ChangeScheme />
            Change Scheme
          </Button>
        </Col>
      </Row>
    );
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const handlePayloadValidation = (payload) => {
    if (!payload.reason || !payload.reason) {
      return;
    }
  };

  const submitData = (e) => {
    e.preventDefault();
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const userName = userData.userName;
    const finalData = [];

    let data = [];
    formik?.values.forEach((dataItem) => {
      data.push({
        dealerCode: dataItem.code,
        dealerId: +dataItem.key,
        dealerLocation:dataItem.Location,
        dealerName:dataItem.name,
        StartMonth: startDate
          ? dayjs(startDate).startOf('month').format('YYYY-MM-DD HH:mm:ss')
          : startDate,
        EndMonth: endDate
          ? dayjs(endDate).endOf('month').format('YYYY-MM-DD HH:mm:ss')
          : endDate,
        isSAP: true,
        isActive: true,
        state: dataItem.stateId,
        status: 1,
        isSubmitted: 1,
        fyear: currentFiscalYear,
        schemeTypeId: schemeType,
        userid: +userId,
        username: userName,
        payout: dataItem.payout ? parseInt(dataItem.payout) : 0,
        incPerTarget: +dataItem.IPT,
        installationTarget: +dataItem.Target,
        p1: +dataItem.payout ? parseInt(dataItem.payout) : 0,
        reason: dataItem.reason,
      });
    });
    let extraValidation = []
    const filteredValue=formik?.values?.filter((selectedSchemesFil)=>selectedRowKeys.includes(selectedSchemesFil?.key))
   if(filteredValue?.length!=0){    
    filteredValue?.map((x, index) => {

      let errors = [];

      if (!x.IPT) {
        errors.push("Please enter Inc. Per Tractor");
      }
      if (!x.Target) {
        errors.push("Please enter Installation Target");
      }

      if (errors.length > 0) {
        extraValidation[x?.code] = errors;
      }
    });
    setCodeError(Object?.keys(extraValidation || {}));

    if (Object?.keys?.(extraValidation)?.length != 0) {
      showErrorDialog(extraValidation);
    }
    else {
      const selectedData1=data?.filter((selectedSchemesFil)=>selectedRowKeys.includes(selectedSchemesFil?.dealerId))
      handleSuccessModal();
      setDataHandle(selectedData1);
    }
  }else{
    ToastifyShow("Please select the scheme","error")
  }
  };
  const getFilterData = (listData) => {
    // 
    return listData
      .map((item) => {
        if (dealerCode.includes(item.code)) {
          return { ...item, Target: item?.Target || "", IPT: item?.IPT || "" };
        }
      })
      .filter((item) => item != undefined);
  };

  const showErrorDialog = (errFields) => {
    setOpenErrorDialog(openErrorDialog => ({
      open: true,
      errorsFields: errFields ? errFields : []
    }));
  };

  const closeErrorDialog = () => {
    setOpenErrorDialog({ open: false, errorsFields: [] });
  };

  useEffect(() => {
    if (sortData?.length > 0) {
      window.addEventListener('beforeunload', alertUser)
    }
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  })

  const alertUser = (event) => {
    event.preventDefault()
    return event.returnValue = ''
  }

  const hasSelected = selectedRowKeys.length > 0;
  return (
    <div className="table_scheme_wrap slab-uncondionaling">
      <Divider />
      <grid>
        <Row>
          <Col flex="auto">
            <h2 className="unconditional"
              loading={loading}
              style={{ fontSize: "18px", padding: "20px", paddingLeft: "0px" }}
            >
              Single Target Scheme
            </h2>
          </Col>
          <Col style={{ display: "flex", alignItems: "center" }}>
            {/* <ThemeButton /> */}
          </Col>
        </Row>

        <span>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </grid>
      <Form>
        <Table
          style={{ paddingLeft: "0px", paddingRight: "40px" }}
          // title={tableHeader}
          pagination={false}
          showSorterTooltip={false}
          summary={(pageData) => {
            const fieldSum = {
              Target: 0,
              IPT: 0,
              payout: 0,
            }
            pageData.forEach(({
              Target,
              IPT,
              payout,
            }) => {
              fieldSum.Target += Target ? parseInt(Target) : 0;
              fieldSum.IPT += IPT ? parseInt(IPT) : 0;
              fieldSum.payout += payout ? parseInt(payout) : 0
            });
            return (
              <Table.Summary.Row style={{ fontWeight: '600', fontSize: '18px' }}>
                <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8}></Table.Summary.Cell>
                <Table.Summary.Cell index={9}>
                  {fieldSum.Target ? fieldSum.Target : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10}>
                  {fieldSum.payout && fieldSum.Target ?
                    Math.round(fieldSum.payout / fieldSum.Target) : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={11}>
                  {fieldSum.payout ? fieldSum.payout : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={12}></Table.Summary.Cell>
              </Table.Summary.Row>
            )
          }}
          // components={components}
          rowSelection={{
            selectedRowKeys,
            onChange: (newSelectedRowKeys) => {
              setSelectedRowKeys(newSelectedRowKeys);
              setSchemeIdNo(selectedRow);

            },
            getCheckboxProps: (record) => ({
              disabled: record.status === 1 || record.status === 2,
              // Column configuration not to be checked
              name: record.name,
            }),
          }}
          rowClassName={() => "editable-row"}
          columns={columns}
          // dataSource={getFilterData(listData)}
          dataSource={sortData}
          scroll={{
            x: 900,
            y: 200,
          }}
          bordered
          size="middle"
        />
      </Form>


      <div style={{ padding: "0px 0px 0px 0px" }}>
        <Row
          className="draft-button-wrap"
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {/* <Col>
            <Button
              type="default"
              shape="circle"
              icon={<DownloadOutlined style={{ paddingRight: "32px" }} />}
              style={{ margin: "14px", background: "#E4E4E4" }}
            />{" "}
          </Col> */}
          <Col>
            <Button
              className="draft-btn"
              type="default"
              shape="round"
              style={{
                margin: "10px",
                background: "#FCE838",
                fontWeight: "700",
                textTransform: "uppercase",
                border: "none",
                padding: "20px",
              }}
              size="large"
              onClick={draftData}
            >
              save as draft
            </ Button>
          </Col>
          <Col>
            <Button
              type="default"
              shape="round"
              style={{
                margin: "10px",
                background: "#FCE838",
                fontWeight: "700",
                textTransform: "uppercase",
                border: "none",
                padding: "20px",
              }}
              size="large"
              onClick={submitData}
              disabled={listData.every((item) => item.nos == "0")}
            >
              Save & Submit
            </Button>{" "}
          </Col>
        </Row>
      </div>

      <ChangeSchemeModal
        openModal={openSchemeModal}
        onModalClose={handleChangeScheme}
      />
      {dirty && <AlertModal openModal={openModal} handleModal={handleModal} />}

      <StatusModal open={openSuccessModal} onOk={() => {
        dispatch(fetchgenerateScheme(dataHandle));
        handleSuccessModal()
        setTimeout(() => navigate("/installation/allSchemes"), 1500)
      }}
        onCancel={handleSuccessModal}>
        <p>Are you sure you want to save</p>
      </StatusModal>
      {/* <SMDialog open={openSuccessModal} onCancel={handleSuccessModal}>
        <p className="custom-warning">Success</p>
        <p>Scheme Successfully Generated</p>
      </SMDialog> */}


      <StatusModal open={openDraftModal} onOk={() => {
        dispatch(fetchgenerateScheme(dataDraftHandle));
        handleDraftModal()
      }} onCancel={handleDraftModal}>
        <p>Are you sure to save as Draft</p>
      </StatusModal>

      <SMDialog open={openErrorModal} onCancel={handleErrorModal}>
        <p className="custom-warning">Warning !</p>
        <p>{errorItem.firstItem} should be greater than {errorItem.secondItem}</p>
      </SMDialog>
      <SMDialog open={openSelectModal} onCancel={handleSelectModal}>
        <p className="custom-warning">Warning !</p>
        <p>Please Select The Dealer</p>
      </SMDialog>

      <ValidationDifferentSchemeError
        open={openErrorDialog.open}
        onClose={closeErrorDialog}
        errorsFields={openErrorDialog?.errorsFields}
      />

    </div>
  );
};
export default SingleTarget;
