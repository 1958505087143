import React, { useEffect, useState } from "react";
import { Modal, Input, Button } from "antd";
import { TextField, styled, Box } from "@mui/material";

const StyledTextField = styled(TextField)(() => ({
  maxWidth: 195,
  textAlign: "center",
  background: "#F7F7F7",
  borderRadius: "58px",
  margin: "auto",
  fontSize: 16,
  color: "#464646",
  fontWeight: 400,
  "& legend": { display: "none" },
  "& fieldset": { top: 0, border: "none" },
  "& .MuiInputBase-input": {
    paddingLeft: "auto",
  },
}));

const DeviationModal = ({ openModal, handleModal, handleRequest, actualTargetT1, actualTargetT2, actualTargetT3,schemeType,deviationData }) => {
  const { TextArea } = Input;
  const [amount, setAmount] = useState("");
  const [quantity, setQuantity] = useState("");
  const [remarks, setRemarks] = useState("");
  const [loading, setLoading] = useState(false);
  const [targetTypeValue, setTargetTypeValue] = useState('');

  useEffect(() => {
    if (!amount && !quantity) {
      setTargetTypeValue('');
    }
  }, [amount, quantity])

  const handleChange = (value, type, targetType) => {
    if(type === 'amount'){
      setAmount(value)
    } else {
      setQuantity(value)
    }
    setTargetTypeValue(targetType);
  }
  console.log(deviationData?.topTarget?.split(",")?.includes,"deviationData")

  return (
    <div>
      <Modal
      className="request-modal-popup-alert"
        centered
        open={openModal}
        // onOk={handleModal}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        // onCancel={handleModal}
        footer={[
          <Button
            className="dev_btn"
            key="back"
            onClick={handleModal}
            style={{ color: "#FFFFFF" }}
          >
            CANCEL
          </Button>,
          <Button
            className="dev_btn"
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => handleRequest(amount, quantity, remarks)}
            style={{ color: "#333333" }}
            // disabled={!amount || !quantity || !remarks}
          >
            REQUEST
          </Button>,
        ]}
      >
        <div className="modal_wrap request-dev-popup">
          <div className="modal_req_head requst-dev-heading">
            <h1>Request Deviation</h1>
            <h4>Please fill details below</h4>
            <div className="d-flex">
              <div>
                Scheme No
              </div>
              <div className="pl-2">
                {deviationData?.schemeNo}
              </div>
            </div>
            <div className="d-flex">
              <div>
                Location
              </div>
              <div className="pl-2">
                {deviationData?.Location}
              </div>
            </div>
         
          </div>
       { (schemeType === 1 || schemeType ===2) &&(
        <div>
          <Box display="flex" justifyContent="space-between">
            <StyledTextField
              disabled
              placeholder="T1"
              value={actualTargetT1}
              // onChange={(e) => setQuantity(e.target.value)}
            />
            <StyledTextField
              required
              placeholder="Revised Target 1"
              onChange={(e) => handleChange(e.target.value, 'quantity', 'T1')
                // (e) => setQuantity(e.target.value)
                }
              disabled={deviationData?.topTarget?.split(",")?.includes("t1")?false:true}
              type="number"
            />

            <StyledTextField
              required
              placeholder="Revised Payout 1"
              onChange={(e) => handleChange(e.target.value, 'amount', 'T1')
                // (e) => setAmount(e.target.value)
                }
                disabled={deviationData?.topTarget?.split(",")?.includes("t1")?false:true}
              type="number"
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <StyledTextField
              disabled
              placeholder="T2"
              value={actualTargetT2}
              // onChange={(e) => setQuantity(e.target.value)}
            />
            <StyledTextField
              required
              placeholder="Revised Target 2"
              // value={quantity}
              onChange={
                (e) => handleChange(e.target.value, 'quantity', 'T2')
                // (e) => setQuantity(e.target.value)
                }
                disabled={deviationData?.topTarget?.split(",")?.includes("t2")?false:true}
              type="number"
            />

            <StyledTextField
              required
              placeholder="Revised Payout 2"
              // value={amount}
              onChange={
                (e) => handleChange(e.target.value, 'amount', 'T2')
                // (e) => setAmount(e.target.value)
                }
                disabled={deviationData?.topTarget?.split(",")?.includes("t2")?false:true}
              type="number"
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <StyledTextField
              disabled
              placeholder="T3"
              value={actualTargetT3}
              // onChange={(e) => setQuantity(e.target.value)}
            />
            <StyledTextField
              required
              placeholder="Revised Target 3"
              // value={quantity}
              onChange={
                (e) => handleChange(e.target.value, 'quantity', 'T3')
                // (e) => setQuantity(e.target.value)
                }
                disabled={deviationData?.topTarget?.split(",")?.includes("t3")?false:true}
              type="number"
            />

            <StyledTextField
              required
              placeholder="Revised Payout 3"
              // value={amount}
              onChange={
                // (e) => setAmount(e.target.value)
                (e) => handleChange(e.target.value, 'amount', 'T3')
              }
              disabled={deviationData?.topTarget?.split(",")?.includes("t3")?false:true}
              type="number"
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <StyledTextField
              disabled
              placeholder="T4"
              value={actualTargetT3}
              // onChange={(e) => setQuantity(e.target.value)}
            />
            <StyledTextField
              required
              placeholder="Revised Target 4"
              // value={quantity}
              onChange={
                (e) => handleChange(e.target.value, 'quantity', 'T4')
                // (e) => setQuantity(e.target.value)
                }
                disabled={deviationData?.topTarget?.split(",")?.includes("t4")?false:true}
              type="number"
            />

            <StyledTextField
              required
              placeholder="Revised Payout 4"
              // value={amount}
              onChange={
                // (e) => setAmount(e.target.value)
                (e) => handleChange(e.target.value, 'amount', 'T4')
              }
              disabled={deviationData?.topTarget?.split(",")?.includes("t4")?false:true}
              type="number"
            />
          </Box>

          <Box display="flex" justifyContent="space-between">
            <StyledTextField
              disabled
              placeholder="T5"
              value={actualTargetT1}
              // onChange={(e) => setQuantity(e.target.value)}
            />
            <StyledTextField
              required
              placeholder="Revised Target 5"
              onChange={(e) => handleChange(e.target.value, 'quantity', 'T5')
                // (e) => setQuantity(e.target.value)
                }
                disabled={deviationData?.topTarget?.split(",")?.includes("t5")?false:true}
              type="number"
            />


            <StyledTextField
              required
              placeholder="Revised Payout 5"
              onChange={(e) => handleChange(e.target.value, 'amount', 'T5')
                // (e) => setAmount(e.target.value)
                }
                disabled={deviationData?.topTarget?.split(",")?.includes("t5")?false:true}
              type="number"
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <StyledTextField
              disabled
              placeholder="T6"
              value={actualTargetT1}
              // onChange={(e) => setQuantity(e.target.value)}
            />
            <StyledTextField
              required
              placeholder="Revised Target 6"
              onChange={(e) => handleChange(e.target.value, 'quantity', 'T6')
                // (e) => setQuantity(e.target.value)
                }
                disabled={deviationData?.topTarget?.split(",")?.includes("t6")?false:true}
              type="number"
            />

            <StyledTextField
              required
              placeholder="Revised Payout 6"
              onChange={(e) => handleChange(e.target.value, 'amount', 'T6')
                // (e) => setAmount(e.target.value)
                }
                disabled={deviationData?.topTarget?.split(",")?.includes("t6")?false:true}
              type="number"
            />
          </Box>
          </div>) }
          {( schemeType ===3) &&(
        <div>
          <Box display="flex" justifyContent="space-between">
            <StyledTextField
              disabled
              placeholder="T1"
              value={actualTargetT1}
              // onChange={(e) => setQuantity(e.target.value)}
            />
            <StyledTextField
              required
              placeholder="Revised Target 1"
              onChange={(e) => handleChange(e.target.value, 'quantity', 'T1')
                // (e) => setQuantity(e.target.value)
                }
              disabled={targetTypeValue !== 'T1' && targetTypeValue !== ''}
              type="number"
            />

            <StyledTextField
              required
              placeholder="Revised Payout 1"
              onChange={(e) => handleChange(e.target.value, 'amount', 'T1')
                // (e) => setAmount(e.target.value)
                }
              disabled={targetTypeValue !== 'T1' && targetTypeValue !== ''}
              type="number"
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <StyledTextField
              disabled
              placeholder="T2"
              value={actualTargetT2}
              // onChange={(e) => setQuantity(e.target.value)}
            />
            <StyledTextField
              required
              placeholder="Revised Target 2"
              // value={quantity}
              onChange={
                (e) => handleChange(e.target.value, 'quantity', 'T2')
                // (e) => setQuantity(e.target.value)
                }
              disabled={targetTypeValue !== 'T2' && targetTypeValue !== ''}
              type="number"
            />

            <StyledTextField
              required
              placeholder="Revised Payout 2"
              // value={amount}
              onChange={
                (e) => handleChange(e.target.value, 'amount', 'T2')
                // (e) => setAmount(e.target.value)
                }
              disabled={targetTypeValue !== 'T2' && targetTypeValue !== ''}
              type="number"
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <StyledTextField
              disabled
              placeholder="T3"
              value={actualTargetT3}
              // onChange={(e) => setQuantity(e.target.value)}
            />
            <StyledTextField
              required
              placeholder="Revised Target 3"
              // value={quantity}
              onChange={
                (e) => handleChange(e.target.value, 'quantity', 'T3')
                // (e) => setQuantity(e.target.value)
                }
              disabled={targetTypeValue !== 'T3' && targetTypeValue !== ''}
              type="number"
            />

            <StyledTextField
              required
              placeholder="Revised Payout 3"
              // value={amount}
              onChange={
                // (e) => setAmount(e.target.value)
                (e) => handleChange(e.target.value, 'amount', 'T3')
              }
              disabled={targetTypeValue !== 'T3' && targetTypeValue !== ''}
              type="number"
            />
          </Box>
         
        
          </div>) }
          {( schemeType ===8 || schemeType ===4 || schemeType ===5 ) &&(
        <div>
          <Box display="flex" justifyContent="space-between">
            <StyledTextField
              disabled
              placeholder="Revised Installation Target"
              value={actualTargetT1}
              // onChange={(e) => setQuantity(e.target.value)}
            />
            <StyledTextField
              required
              placeholder="Revised  Installation Target "
              onChange={(e) => handleChange(e.target.value, 'quantity', 'T1')
                // (e) => setQuantity(e.target.value)
                }
              disabled={targetTypeValue !== 'T1' && targetTypeValue !== ''}
              type="number"
            />

            <StyledTextField
              required
              placeholder="Revised Payout "
              onChange={(e) => handleChange(e.target.value, 'amount', 'T1')
                // (e) => setAmount(e.target.value)
                }
              disabled={targetTypeValue !== 'T1' && targetTypeValue !== ''}
              type="number"
            />
          </Box>
          </div>) }
          <div className="modal_textarea">
            <TextArea
              placeholder="Remarks"
              value={remarks}
              autoSize={{
                minRows: 6,
                maxRows: 6,
              }}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeviationModal;
