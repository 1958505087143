import {  Row, DatePicker, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import XLSX from "xlsx-js-style";
import moment from "moment";
import ActionButton from "../../../components/actionButton/actionButton.js";
import CollectionTable from "../../../components/table/CollectionTables";
import SlabBased from "../../../components/table/SlabBased";
import SingleTarget from "../../../components/table/SingleTarget";
import SingleTargetWithUpperCap from "../../../components/table/SingleTargetWithUpperCap";
import UnconditionalInstallation from "../../../components/table/UnconditionalInstallation";
import Status from "../../../components/StatusButton/Status";
import { STATE_WISE_DASHBOARD_LABEL } from "../../../config";
import { getDashboardDetailsApi } from "../../../redux/dashboard/dashboardDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentFiscalYear } from "../../../utils/dateFormatter";
import ToastifyShow, { TextLocalString } from "../../../components/ToastifyShow";
import { decryptData } from "../../../utils/localStorageEncodeDecode";

const ZonalTable = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const { dashboardType, dateValue } = props;
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedSchemeType, setSelectedSchemeType] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [startMonth, setStartMonth] = useState(dayjs());
  const [endMonth, setEndMonth] = useState(dayjs());
  const [enforcedMonth, setEnforcedMonth] = useState();
  const [monthlyFilters, setMonthlyFilters] = useState({
    month: new Date().getMonth() + 1,
    monthEnd: new Date().getMonth() + 1,
    enforcedMonth: 0,
    year: new Date().getFullYear()
  });


  useEffect(() => {
    if (dateValue) {

      setStartMonth(dayjs().year(dateValue) || dayjs())
      setEndMonth(dayjs().year(dateValue) || dayjs())
      setMonthlyFilters((prev) => ({
        ...prev,
        month: dayjs(dayjs().year(dateValue)).format("MM"),
        monthEnd: dayjs(dayjs().year(dateValue)).format("MM"),
      }));
      
    }
  }, [dateValue])
 
  const userData = sessionStorage.getItem("userInfo")
    ? decryptData(sessionStorage.getItem("userInfo"))
    : {};
  const userId = userData?.userId;
  const fetchGeneratedScheme = (demoParams) => {
    setSelectedSchemeType(demoParams.schemeType);
    props?.sendDataToParent(demoParams.schemeType);
    props?.SetTotalCountShow(true);
  };


  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const userInfo = decryptData(sessionStorage.getItem("userInfo"));
  const userRole = userInfo.userRole;


  const getSchemeType = (schemeType) => {
    if (schemeType == 1) {
      return "Collection - With Delivery Plan";
    }
    if (schemeType == 2) {
      return "Collection - Without Delivery Plan";
    }
    if (schemeType == 3) {
      return "Installation - Slab Based";
    }
    if (schemeType == 8) {
      return "Installation - Single Target";
    }
    if (schemeType == 4) {
      return "Installation - Single Target With Upper Cap";
    }
    if (schemeType == 5) {
      return "Installation - Unconditional Installation";
    }
  };

  const handleMonthChange = (key, date) => {
    switch (key) {
      case "month":
        setStartMonth(date);
        break;
      case "monthEnd":
        setEndMonth(date);
        break;
      case "enforcedMonth":
        setEnforcedMonth(date);
        break;
    }
    const month = date ? new Date(date).getMonth() + 1 : 0;

    const year = date ? new Date(date).getFullYear() : 0;
    // setMonthlyFilters((monthlyFilters) => ({
    //   ...monthlyFilters,
    //   ...key=="month"&&{year:year},
    //   month: month,
    // }));
    if (key == "monthEnd") {
      setMonthlyFilters((monthlyFilters) => ({
        ...monthlyFilters,
        [key]: month,

      }));
    }if(key == "enforcedMonth"){
      setMonthlyFilters((monthlyFilters) => ({
        ...monthlyFilters,
        [key]: month,

      }));
    }
     else {
      setMonthlyFilters((monthlyFilters) => ({
        ...monthlyFilters,
        ...key == "month" && { year: year },
        month: month,
      }));
    }
  };
  const handleMonthChange1 = (key, date) => {
    switch (key) {
      case "month":
        setStartMonth(date);
        break;
      case "monthEnd":
        setEndMonth(date);
        break;
      case "enforcedMonth":
        setEnforcedMonth(date);
        break;
    }
    const month = date ? new Date(date).getMonth() + 1 : 0;

    const year = date ? new Date(date).getFullYear() : 0;
    // setMonthlyFilters((monthlyFilters) => ({
    //   ...monthlyFilters,
    //   ...key=="month"&&{year:year},
    //   month: month,
    // }));
    if (key == "monthEnd") {
      setMonthlyFilters((monthlyFilters) => ({
        ...monthlyFilters,
        [key]: month,

      }));
    } else {
      setMonthlyFilters((monthlyFilters) => ({
        ...monthlyFilters,
        ...key == "month" && { year: year },
        month: month,
      }));
    }
  };
  useEffect(() => {
    if (userRole == "ZADM") {
      dispatch(
        getDashboardDetailsApi({
          fyear: props.fyear,
          schemeType: selectedSchemeType,
          userid: userId,
          stateId: props.selectedStateId,
          status: selectedStatus || 0,
          userRole: userData?.userRole,
          enforcedMonth: monthlyFilters?.enforcedMonth,
          month: monthlyFilters?.month,
          endMonth: monthlyFilters?.monthEnd,
          pageNumber: 1,
          pageSize: 150,
        })
      );
    }
  }, [selectedSchemeType]);


  const captalizeObjKeys = (obj) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key.toUpperCase(), value])
    );
  const  handleDownload = () => {

    const TotalStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
    };

    if (selectedSchemeType == 5) {
      if (dataSource?.length == 0) {
        ToastifyShow("Something went wrong", "error");
        return false;
      }
      const data = dataSource
        .map((rec) => rec.scheme)
        .map((item) => {
          let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
          let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
          return captalizeObjKeys({
            SCHEME_NO: item.schemeNo,
            DEALER_CODE: item.dealerCode,
            DEALER_NAME: item.dealerName,
            STATE: item.stateName,
            DEALER_LOCATION: item.dealerLocation,
            TERRITORY_MANAGER: item.territoryManager,
            START_MONTH:
              formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
            END_MONTH: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
            ACTUAL_INST_LAST_YEAR_MONTH: item.actual_Installation_Last_Year_Month || "",
            INSTALLATION_EXPECTED: item.installationTarget,
            INC_PER_TRACTOR: item.incPerTarget,
            PAYOUT: item.p1,
             REMARKS: item.reason||"",
             HOLDREMARKS: item.holdReason ?? "",
            ACTUALINSTALLATION:item.actualInstallation,
            PAYOUT1:item.payout,
            PAYOUT2:item.payout2,
            PAYOUT3:item.payout3,
            PAYOUT4:item.payout4
          });
        });
      
 let tab_text = (userRole == "PMIS" || userRole == "NSH" || userRole == "Finance" || userRole == "COSM"||userRole == "AMGR" || userRole == "ZADM" || userRole == "SADM" ) ?
    `<table>
  <thead>
  <tr>
        <th >SCHEME NO</th>
            <th >DEALER CODE</th>
            <th >DEALER NAME</th>
            <th >STATE</th>
            <th >DEALER LOCATION</th>
            <th >TERRITORY MANAGER</th>
            <th >START MONTH</th>
            <th >END MONTH</th>
            <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
            <th >INSTALLATION EXPECTED</th>
            <th >INC. PER TRACTOR</th>
            <th >PAYOUT</th>
            <th >REMARKS</th>
            <th > HOLD REMARKS</th>
      <th >Actual Installation</th>
      <th >PAYOUT 1</th>
      <th >PAYOUT 2</th>
      <th >PAYOUT 3</th>
      <th> PAYOUT 4</th>
      </tr>

  </thead>`:

    `<table>
    <thead>
    <tr>
         <th >SCHEME NO</th>
            <th >DEALER CODE</th>
            <th >DEALER NAME</th>
            <th >STATE</th>
            <th >DEALER LOCATION</th>
            <th >TERRITORY MANAGER</th>
            <th >START MONTH</th>
            <th >END MONTH</th>
            <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
            <th >INSTALLATION EXPECTED</th>
            <th >INC. PER TRACTOR</th>
            <th >PAYOUT</th>
            <th >REMARKS</th>
             <th > HOLD REMARKS</th>
     
        </tr>

    </thead>`;

      




 if (userRole == "PMIS" || userRole == "NSH" || userRole == "Finance" || userRole == "COSM"||userRole == "AMGR" || userRole == "ZADM" || userRole == "SADM" ) {
  data?.forEach((x) => {
    tab_text += `<tr >
<td>${x?.SCHEME_NO}</td>
  <td>${x?.DEALER_CODE}</td>
  <td>${x?.DEALER_NAME}</td>
  <td>${x?.STATE}</td>
  <td>${x?.DEALER_LOCATION}</td>
  <td>${x?.TERRITORY_MANAGER}</td>
  <td>${x?.START_MONTH}</td>
  <td>${x?.END_MONTH}</td>
  <td>${x?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
  <td>${x?.INSTALLATION_EXPECTED}</td>

  <td>${x?.INC_PER_TRACTOR}</td>
  <td>${x?.PAYOUT}</td>
  <td>${x?.REMARKS||""}</td>
  <td>${x?.HOLDREMARKS||""}</td>
  <td>${x?.ACTUALINSTALLATION||0}</td>
  <td>${x?.PAYOUT1}</td>
  <td>${x?.PAYOUT2}</td>
  <td>${x?.PAYOUT3}</td>
  <td>${x?.PAYOUT4}</td>
  </tr>`;
  });

} else {
  data?.forEach((x) => {
    tab_text += `<tr >
<td>${x?.SCHEME_NO}</td>
  <td>${x?.DEALER_CODE}</td>
  <td>${x?.DEALER_NAME}</td>
  <td>${x?.STATE}</td>
  <td>${x?.DEALER_LOCATION}</td>
  <td>${x?.TERRITORY_MANAGER}</td>
  <td>${x?.START_MONTH}</td>
  <td>${x?.END_MONTH}</td>
  <td>${x?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
  <td>${x?.INSTALLATION_EXPECTED}</td>

  <td>${x?.INC_PER_TRACTOR}</td>
  <td>${x?.PAYOUT}</td>
  <td>${x?.REMARKS||""}</td>
  <td>${x?.HOLDREMARKS||""}</td>
 
  
  </tr>`;
  });
}

let totals = {
 installationTarget: 0,
  incPerTarget: 0,
  p1: 0,
  reason: "",
  holdReason:""


  };
  const total1 = {
    installationTarget: 0,
  incPerTarget: 0,
  p1: 0,
  reason: "",
  holdReason:"",
    actualInstallation: 0,
    payout: 0,
    payout2: 0,
    payout3: 0,
    payout4: 0,


  }
  let selectedTotals;
  if (userRole === 'ZADM') {
    selectedTotals = totals;
  }
  else {
    selectedTotals = total1;
  }

  dataSource.forEach((item) => {
    for (let key in selectedTotals) {
      selectedTotals[key] += parseInt(item.scheme[key]) || 0;
    }
  });

  selectedTotals.incPerTarget = selectedTotals.installationTarget !== 0 ? Math.round(selectedTotals.p1 / selectedTotals.installationTarget) : 0;
  

  let totalsRow = "<tr><td colspan='9'>Total</td>";
  for (let key in selectedTotals) {
    if (key != 'reason' && key !='holdReason' ) {
      if (key.startsWith('in')) {
        totalsRow += `<td>${selectedTotals[key]}</td>`;
      } else {
        totalsRow += `<td>${selectedTotals[key]}</td>`;
      }
    } else {
      totalsRow += `<td></td>`;
    }
  }




      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";

      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);

      const allColumns = data.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {

            columns.push(key);
          }
        });
        return columns;
      }, []);




      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) { return "Invalid input"; }
        return String.fromCharCode(number + 64);
      }
      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(data.length + 2).toString()}`
        excelArr.push(ele||0);

      }

      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });



      // generate file
      XLSX.writeFile(wb, `Unconditional_Installation_${new Date().toJSON().slice(0, 10)}.xlsx`);

    }
    else if (selectedSchemeType == 4) {
      const data = dataSource
        .map((rec) => rec.scheme)
        .map((item) => {
          let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
          let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
          return captalizeObjKeys({
            SCHEME_NO: item.schemeNo,
            DEALER_CODE: item.dealerCode,
            DEALER_NAME: item.dealerName,
            STATE: item.stateName,
            DEALER_LOCATION: item.dealerLocation,
            TERRITORY_MANAGER: item.territoryManager,
            START_MONTH:
              formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
            END_MONTH: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
            ACTUAL_INST_LAST_YEAR_MONTH: item.actual_Installation_Last_Year_Month || "",
            INSTALLATION_TARGET: item.installationTarget,
            UPPER_CAP: item.upperCap,
            INC_PER_TRACTOR: item.incPerTarget,
            PAYOUT: item.p1,
             REMARKS: item.reason||"",
             HOLDREMARKS: item.holdReason ?? "",
            ACTUALINSTALLATION:item.actualInstallation,
            PAYOUT1:item.payout,
            PAYOUT2:item.payout2,
            PAYOUT3:item.payout3,
            PAYOUT4:item.payout4

          });
        });
      if (data?.length == 0) {
        ToastifyShow("Something went wrong", "error");
        return false;
      }

      let tab_text = (userRole == "PMIS" || userRole == "NSH" || userRole == "Finance" || userRole == "COSM"||userRole == "AMGR" || userRole == "ZADM" || userRole == "SADM" ) ? 
      `<table>
    <thead>
    <tr>
           <th >SCHEME NO</th>
        <th >DEALER CODE</th>
        <th >DEALER NAME</th>
        <th >STATE</th>
        <th >DEALER LOCATION</th>
        <th >TERRITORY MANAGER</th>
        <th >START MONTH</th>
        <th >END MONTH</th>
        <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th >INSTALLATION TARGET</th>
        <th>UPPER CAP</th>
        <th >INC. PER TRACTOR</th>
        <th >PAYOUT</th>
        <th >REMARKS</th>
        <th > HOLD REMARKS</th>

        <th >Actual Installation</th>
        <th >PAYOUT 1</th>
        <th >PAYOUT 2</th>
        <th >PAYOUT 3</th>
        <th> PAYOUT 4</th>
        </tr>

    </thead>`:

      `<table>
      <thead>
      <tr>
            <th >SCHEME NO</th>
        <th >DEALER CODE</th>
        <th >DEALER NAME</th>
        <th >STATE</th>
        <th >DEALER LOCATION</th>
        <th >TERRITORY MANAGER</th>
        <th >START MONTH</th>
        <th >END MONTH</th>
        <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th >INSTALLATION TARGET</th>
        <th>UPPER CAP</th>
        <th >INC. PER TRACTOR</th>
        <th >PAYOUT</th>
        <th >REMARKS</th>
        <th >HOLD REMARKS</th>

       
          </tr>

      </thead>`;

     

 if (userRole == "PMIS" || userRole == "NSH" || userRole == "Finance" || userRole == "COSM"||userRole == "AMGR" || userRole == "ZADM" || userRole == "SADM" ) {
        data?.forEach((x) => {
          tab_text += `<tr >
       <td>${x?.SCHEME_NO}</td>
    <td>${x?.DEALER_CODE}</td>
    <td>${x?.DEALER_NAME}</td>
    <td>${x?.STATE}</td>
    <td>${x?.DEALER_LOCATION}</td>
    <td>${x?.TERRITORY_MANAGER}</td>
    <td>${x?.START_MONTH}</td>
    <td>${x?.END_MONTH}</td>
    <td>${x?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
    <td>${x?.INSTALLATION_TARGET}</td>
    <td>${x?.UPPER_CAP}</td>
    <td>${x?.INC_PER_TRACTOR}</td>
    <td>${x?.PAYOUT}</td>
    <td>${x?.REMARKS||""}</td>
    <td>${x?.HOLDREMARKS||""}</td>

        <td>${x?.ACTUALINSTALLATION||0}</td>
        <td>${x?.PAYOUT1}</td>
        <td>${x?.PAYOUT2}</td>
        <td>${x?.PAYOUT3}</td>
        <td>${x?.PAYOUT4}</td>
        </tr>`;
        });

      } else {
        data?.forEach((x) => {
          tab_text += `<tr >
       <td>${x?.SCHEME_NO}</td>
    <td>${x?.DEALER_CODE}</td>
    <td>${x?.DEALER_NAME}</td>
    <td>${x?.STATE}</td>
    <td>${x?.DEALER_LOCATION}</td>
    <td>${x?.TERRITORY_MANAGER}</td>
    <td>${x?.START_MONTH}</td>
    <td>${x?.END_MONTH}</td>
    <td>${x?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
    <td>${x?.INSTALLATION_TARGET}</td>
    <td>${x?.UPPER_CAP}</td>
    <td>${x?.INC_PER_TRACTOR}</td>
    <td>${x?.PAYOUT}</td>
    <td>${x?.REMARKS||""}</td>
    <td>${x?.HOLDREMARKS||""}</td>

        
        </tr>`;
        });
      }

      let totals = {
        installationTarget: 0,
         upperCap: 0,
         incPerTarget: 0,
         p1: 0,
         reason: "",
         holdReason:""
  
  
        };
        const total1 = {
          installationTarget: 0,
         upperCap: 0,
         incPerTarget: 0,
         p1: 0,
         reason: "",
         holdReason:"",
          actualInstallation: 0,
          payout1: 0,
          payout2: 0,
          payout3: 0,
          payout4: 0,
  
  
        }
        let selectedTotals;
        if (userRole === 'ZADM') {
          selectedTotals = totals;
        }
        else {
          selectedTotals = total1;
        }
  
        dataSource.forEach((item) => {
          for (let key in selectedTotals) {
            selectedTotals[key] += parseInt(item.scheme[key]) || 0;
          }
        });
  
        selectedTotals.incPerTarget = selectedTotals.installationTarget !== 0 ? Math.round(selectedTotals.p1 / selectedTotals.installationTarget) : 0;
        
  
        let totalsRow = "<tr><td colspan='9'>Total</td>";
        for (let key in selectedTotals) {
          if (key != 'reason' && key != 'holdReason') {
            if (key.startsWith('inc')) {
              totalsRow += `<td>${selectedTotals[key]}</td>`;
            } else {
              totalsRow += `<td>${selectedTotals[key]}</td>`;
            }
          } else {
            totalsRow += `<td></td>`;
          }
        }
        
 
      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";
      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);

      // Add style to total row
      const allColumns = data.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {

            columns.push(key);
          }
        });
        return columns;
      }, []);
      console.log(allColumns,"allColumns")
      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) { return "Invalid input"; }
        return String.fromCharCode(number + 64);
      }
      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(data?.length + 2)?.toString()}`
        excelArr.push(ele||0);

      }

      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });


      // generate file
      XLSX.writeFile(wb, `Installation_Single_Target_With_Upper_Cap${new Date().toJSON().slice(0, 10)}.xlsx`);
    }
    else if (selectedSchemeType == 8) {
      const data = dataSource
        .map((rec) => rec.scheme)
        .map((item) => {
          let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
          let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
          return captalizeObjKeys({
            SCHEME_NO: item.schemeNo,
            DEALER_CODE: item.dealerCode,
            DEALER_NAME: item.dealerName,
            STATE: item.stateName,
            DEALER_LOCATION: item.dealerLocation,
            TERRITORY_MANAGER: item.territoryManager,
            START_MONTH: formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
            END_MONTH: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
            ACTUAL_INST_LAST_YEAR_MONTH: item.actual_Installation_Last_Year_Month || "",
            INSTALLATION_TARGET: item.installationTarget,
            INC_PER_TRACTOR: item.incPerTarget,
            PAYOUT: item.p1,
             REMARKS: item.reason||"",
             HOLDREMARKS: item.holdReason ?? "",
            ActualInstallation: item.actualInstallation,
            Payout1: item.payout,
            Payout2: item.payout2,
            Payout3: item.payout3,
            Payout4: item.payout4,
          });
        });
      if (data?.length == 0) {
        ToastifyShow("Something went wrong", "error");
        return false;
      }

      let tab_text = (userRole == "PMIS" || userRole == "NSH" || userRole == "Finance" || userRole == "COSM"||userRole == "AMGR" || userRole == "ZADM" || userRole == "SADM" )  ?

        `<table>
      <thead>
      <tr>
            <th >SCHEME NO</th>
          <th >DEALER CODE</th>
          <th >DEALER NAME</th>
          <th >STATE</th>
          <th >DEALER LOCATION</th>
          <th >TERRITORY MANAGER</th>
          <th >START MONTH</th>
          <th >END MONTH</th>
          <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th >INSTALLATION TARGET</th>
          <th >INC. PER TRACTOR</th>
          <th >PAYOUT</th>
          <th >REMARKS</th>
          <th > HOLD REMARKS</th>
          <th >Actual Installation</th>
          <th >PAYOUT 1</th>
          <th >PAYOUT 2</th>
          <th >PAYOUT 3</th>
          <th>PAYOUT 4</th>
          </tr>
  
      </thead>`:

        `<table>
        <thead>
        <tr>
         <th >SCHEME NO</th>
        <th >DEALER CODE</th>
        <th >DEALER NAME</th>
        <th >STATE</th>
        <th >DEALER LOCATION</th>
        <th >TERRITORY MANAGER</th>
        <th >START MONTH</th>
        <th >END MONTH</th>
        <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th >INSTALLATION TARGET</th>
        <th >INC. PER TRACTOR</th>
        <th >PAYOUT</th>
        <th >REMARKS</th>
        <th > HOLD REMARKS</th>

         
            </tr>

        </thead>`;
      if (userRole == "PMIS" || userRole == "NSH" || userRole == "Finance" || userRole == "COSM"||userRole == "AMGR" || userRole == "ZADM" || userRole == "SADM" ) {
        data?.forEach((x) => {
          tab_text += `<tr >
        <td>${x?.SCHEME_NO}</td>
            <td>${x?.DEALER_CODE}</td>
            <td>${x?.DEALER_NAME}</td>
            <td>${x?.STATE}</td>
            <td>${x?.DEALER_LOCATION}</td>
            <td>${x?.TERRITORY_MANAGER}</td>
            <td>${x?.START_MONTH}</td>
            <td>${x?.END_MONTH}</td>
            <td>${x?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
            <td>${x?.INSTALLATION_TARGET}</td>
            <td>${x?.INC_PER_TRACTOR}</td>
            <td>${x?.PAYOUT}</td>
            <td>${x?.REMARKS||""}</td>
            <td>${x?.HOLDREMARKS||""}</td>
        <td>${x?.ACTUALINSTALLATION||0}</td>
        <td>${x?.PAYOUT1}</td>
        <td>${x?.PAYOUT2}</td>
        <td>${x?.PAYOUT3}</td>
        <td>${x?.PAYOUT4}</td>
        </tr>`;
        });

      } else {
        data?.forEach((x) => {
          tab_text += `<tr >
        <td>${x?.SCHEME_NO}</td>
            <td>${x?.DEALER_CODE}</td>
            <td>${x?.DEALER_NAME}</td>
            <td>${x?.STATE}</td>
            <td>${x?.DEALER_LOCATION}</td>
            <td>${x?.TERRITORY_MANAGER}</td>
            <td>${x?.START_MONTH}</td>
            <td>${x?.END_MONTH}</td>
            <td>${x?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
            <td>${x?.INSTALLATION_TARGET}</td>
            <td>${x?.INC_PER_TRACTOR}</td>
            <td>${x?.PAYOUT}</td>
            <td>${x?.REMARKS||""}</td>
            <td>${x?.HOLDREMARKS||""}</td>

        </tr>`;
        });
      }



      let totals = {
        installationTarget: 0,
         incPerTarget: 0,
         p1: 0,
         reason: "",
         holdReason:""
 
 
       };
       const total1 = {
         installationTarget: 0,
         incPerTarget: 0,
         p1: 0,
         reason: "",
         holdReason:"",
         actualInstallation: 0,
         payout: 0,
         payout2: 0,
         payout3: 0,
         payout4: 0,
 
 
       }
       let selectedTotals;
       if (userRole === 'ZADM') {
         selectedTotals = totals;
       }
       else {
         selectedTotals = total1;
       }
 
       dataSource.forEach((item) => {
         for (let key in selectedTotals) {
           selectedTotals[key] += parseInt(item.scheme[key]) || 0;
         }
       });
 
       selectedTotals.incPerTarget = selectedTotals.installationTarget !== 0 ? Math.round(selectedTotals.p1 / selectedTotals.installationTarget) : 0;
       
 
       let totalsRow = "<tr><td colspan='9'>Total</td>";
       for (let key in selectedTotals) {
         if (key != "reason" && key != "holdReason") {
           if (key.startsWith("inc")) {
             totalsRow += `<td>${selectedTotals[key]}</td>`;
           } else {
             totalsRow += `<td>${selectedTotals[key]}</td>`;
           }
         } else {
           totalsRow += `<td></td>`;
         }
       }

      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";

      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);

      // Add style to total row color
      const allColumns = dataSource.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {

            columns.push(key);
          }
        });
        return columns;
      }, []);

      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) { return "Invalid input"; }
        return String.fromCharCode(number + 64);
      }
      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(data?.length + 2)?.toString()}`
        excelArr.push(ele||0);

      }

      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });

      // generate file
      XLSX.writeFile(wb, `Installation_Single_Target_${new Date().toJSON().slice(0, 10)}.xlsx`);      // const url = window.URL.createObjectURL(
    }
    else if (selectedSchemeType == 3) {
      const data = dataSource.map(rec => rec.scheme).map(item => {
        let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
        let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
        return captalizeObjKeys({
          Scheme_No: item.schemeNo,
          Dealer_Code: item.dealerCode,
          Dealer_Name: item.dealerName,
          State: item.stateName,
          Dealer_Location: item.dealerLocation,
          Territory_Manager: item.territoryManager,
          Start_Month: formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
          End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
          Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month || "",
          Target_T1: item.t1,
          Target_T2: item.t2,
          Target_T3: item.t3,
          Inc_Per_Tractor_R1: item.r1,
          Inc_Per_Tractor_R2: item.r2,
          Inc_Per_Tractor_R3: item.r3,
          Payout_P1: item.p1,
          Payout_P2: item.p2,
          Payout_P3: item.p3,
           REMARKS: item.reason||"",
           HOLDREMARKS: item.holdReason ?? "",
          ACTUALINSTALLATION: item.actualInstallation,
          PAYOUT1: item.payout,
          PAYOUT2: item.payout2,
          PAYOUT3: item.payout3,
          PAYOUT4: item.payout4,
          Achievedslab: item.achievedSlab

        })
      })
      if (data?.length == 0) {
        ToastifyShow("Something went wrong", "error")
        return false
      }


      let tab_text = (userRole == "PMIS" || userRole == "NSH" || userRole == "Finance" || userRole == "COSM"||userRole == "AMGR" || userRole == "ZADM" || userRole == "SADM" ) ?
        `<table>
      <thead>
      <tr>
          <th rowspan="2">SCHEME NO</th>
          <th rowspan="2">DEALER CODE</th>
          <th rowspan="2">DEALER NAME</th>
          <th rowspan="2">STATE</th>
          <th rowspan="2">DEALER LOCATION</th>
          <th rowspan="2">TERRITORY MANAGER</th>
          <th rowspan="2">START MONTH</th>
          <th rowspan="2">END MONTH</th>
          <th rowspan="2">ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th colspan="3">TARGETS</th>
          <th colspan="3">INC. PER TRACTOR</th>
          <th colspan="3">PAYOUT</th>
          <th rowspan="2">REMARKS</th>
          <th rowspan="2"> HOLD REMARKS</th>
          <th rowspan="2">ACTUAL INSTALLATION</th>
          <th rowspan="2">Achieved Slab</th>
          <th rowspan="2">PAYOUT 1</th>
          <th rowspan="2">PAYOUT 2</th>
          <th rowspan="2">PAYOUT 3</th>
          <th rowspan="2">PAYOUT 4</th>
          </tr>
      <tr>
         <th>T1</th>
         <th>T2</th>
         <th>T3</th>
         <th>R1</th>
         <th>R2</th>
         <th>R3</th>
         <th>P1</th>
         <th>P2</th>
         <th>P3</th>

        </tr>
      </thead>`:

        `<table>
        <thead>
        <tr>
            <th rowspan="2">SCHEME NO</th>
            <th rowspan="2">DEALER CODE</th>
            <th rowspan="2">DEALER NAME</th>
            <th rowspan="2">STATE</th>
            <th rowspan="2">DEALER LOCATION</th>
            <th rowspan="2">TERRITORY MANAGER</th>
            <th rowspan="2">START MONTH</th>
            <th rowspan="2">END MONTH</th>
            <th rowspan="2">ACTUAL INSTALLATION LAST YEAR MONTH</th>
            <th colspan="3">TARGETS</th>
            <th colspan="3">INC. PER TRACTOR</th>
            <th colspan="3">PAYOUT</th>
            <th rowspan="2">REMARKS</th>
            <th rowspan="2"> HOLD REMARKS</th>

         
            </tr>
        <tr>
           <th>T1</th>
           <th>T2</th>
           <th>T3</th>
           <th>R1</th>
           <th>R2</th>
           <th>R3</th>
           <th>P1</th>
           <th>P2</th>
           <th>P3</th>

          </tr>
        </thead>`;



      if (userRole == "PMIS" || userRole == "NSH" || userRole == "Finance" || userRole == "COSM"||userRole == "AMGR" || userRole == "ZADM" || userRole == "SADM" ) {
        data?.forEach((header) => {
          tab_text += `<tr >
<td>${header?.SCHEME_NO}</td>
<td>${header?.DEALER_CODE}</td>
<td>${header?.DEALER_NAME}</td>
<td>${header?.STATE}</td>
<td>${header?.DEALER_LOCATION}</td>
<td>${header?.TERRITORY_MANAGER}</td>
<td>${header?.START_MONTH}</td>
<td>${header?.END_MONTH}</td>
<td>${header?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
<td>${header?.TARGET_T1}</td>
<td>${header?.TARGET_T2}</td>
<td>${header?.TARGET_T3}</td>
<td>${header?.INC_PER_TRACTOR_R1}</td>
<td>${header?.INC_PER_TRACTOR_R2}</td>
<td>${header?.INC_PER_TRACTOR_R3}</td>
<td>${header?.PAYOUT_P1}</td>
<td>${header?.PAYOUT_P2}</td>
<td>${header?.PAYOUT_P3}</td>
<td>${header?.REMARKS}</td>
<td>${header?.HOLDREMARKS}</td>
<td>${header?.ACTUALINSTALLATION}</td>
<td>${header?.ACHIEVEDSLAB || "No Slab"}</td>
<td>${header?.PAYOUT1}</td>
<td>${header?.PAYOUT2}</td>
<td>${header?.PAYOUT3}</td>
<td>${header?.PAYOUT4}</td>
</tr>`;
        });

      } else {
        data?.forEach((header) => {
          tab_text += `<tr >
<td>${header?.SCHEME_NO}</td>
<td>${header?.DEALER_CODE}</td>
<td>${header?.DEALER_NAME}</td>
<td>${header?.STATE}</td>
<td>${header?.DEALER_LOCATION}</td>
<td>${header?.TERRITORY_MANAGER}</td>
<td>${header?.START_MONTH}</td>
<td>${header?.END_MONTH}</td>
<td>${header?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
<td>${Math?.round(header?.TARGET_T1)}</td>
<td>${Math?.round(header?.TARGET_T2)}</td>
<td>${Math?.round(header?.TARGET_T3)}</td>
<td>${Math?.round(header?.INC_PER_TRACTOR_R1)}</td>
<td>${Math?.round(header?.INC_PER_TRACTOR_R2)}</td>
<td>${Math?.round(header?.INC_PER_TRACTOR_R3)}</td>
<td>${Math?.round(header?.PAYOUT_P1)}</td>
<td>${Math?.round(header?.PAYOUT_P2)}</td>
<td>${Math?.round(header?.PAYOUT_P3)}</td>
<td>${header?.REMARKS}</td>
<td>${header?.HOLDREMARKS}</td>



</tr>`;
        });
      }
      let totals = {
        t1: 0,
        t2: 0,
        t3: 0,
        r1: 0,
        r2: 0,
        r3: 0,
        p1: 0,
        p2: 0,
        p3: 0,
        reason: "",
        holdReason:""


      };
      const total1 = {
        t1: 0,
        t2: 0,
        t3: 0,
        r1: 0,
        r2: 0,
        r3: 0,
        p1: 0,
        p2: 0,
        p3: 0,
        reason: "",
        holdReason:"",
        actualInstallation: 0,
        achievedSlab: "",
        payout: 0,
        payout2: 0,
        payout3: 0,
        payout4: 0,


      }
      let selectedTotals;
      if (userRole === 'ZADM') {
        selectedTotals = totals;
      }
      else {
        selectedTotals = total1;
      }

      dataSource.forEach((item) => {

        for (let key in selectedTotals) {
          selectedTotals[key] += parseInt(item.scheme[key]) || 0;
        }
      });

      selectedTotals.r1 = selectedTotals.t1 !== 0 ? Math.round(selectedTotals.p1 / selectedTotals.t1) : 0;
      selectedTotals.r2 = selectedTotals.t2 !== 0 ? Math.round(selectedTotals.p2 / selectedTotals.t2) : 0;
      selectedTotals.r3 = selectedTotals.t3 !== 0 ? Math.round(selectedTotals.p3 / selectedTotals.t3) : 0;

      let totalsRow = "<tr><td colspan='9'>Total</td>";
      for (let key in selectedTotals) {
        if (key != "reason" && key != "achievedSlab" && key != "holdReason") {
          if (key.startsWith('r')) {
            totalsRow += `<td>${selectedTotals[key]}</td>`;
          } else {
            totalsRow += `<td>${selectedTotals[key]}</td>`;
          }
        } else {
          totalsRow += `<td></td>`;
        }
      }

      totalsRow += "</tr>";

      tab_text += totalsRow;
      tab_text += "</table>";

      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);
      // Add style to total row
      const allColumns = dataSource.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {

            columns.push(key);
          }
        });
        return columns;
      }, []);

      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) { return "Invalid input"; }
        return String.fromCharCode(number + 64);
      }

      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(dataSource?.length + 3)?.toString()}`
        excelArr.push(ele||0);

      }

      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });

      // generate file
      XLSX.writeFile(wb, `Slab_based_scheme_${new Date().toJSON().slice(0, 10)}.xlsx`);
    } 
    else if ([1, 2].includes(selectedSchemeType)) {
      const data = dataSource.map(rec => rec.scheme).map(item => {

        let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
        let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
        return captalizeObjKeys({
          Scheme_No: item.schemeNo,
          Dealer_Code: item.dealerCode,
          Dealer_Location: item.dealerLocation,
          Dealer_Name: item.dealerName,
          State: item.stateName,
          Territory_Manager: item.territoryManager,
          Start_Month: formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
          End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
          Industry: item.industry,
          B: item.oldBillingQty ? item.oldBillingQty : 0,
          I: Math.round(item.oldCollection) || "",
          C: item.oldInstallation ? item.oldInstallation : 0,
          Opening_Stock_Nos: item.oldOpeningStock,
          Opening_Stock_Days: item.openingStockDays,
          Last_Third_Month: item.closingOutStandingMonth_1,
          Last_Two_Month: item.closingOutStandingMonth_2,
          Last_One_Month: item.closingOutStandingMonth_3,
          CLOSING_STOCK:item?.closingStock,
          CLOSING_OUTSTANDING:item?.closingOutStanding,
          Days: item.outStandingDays,
          BG_Lacs: item.bgLacsCurrentYear || item.bGLacsCurrentYear || 0,
          Installation_Plan_Nos: item.delayPlan,
          Billing_Details_B: item.billing,
          Billing_Details_i: item.installation,
          Billing_Details_c: Math.round(item.collection),
          Books_Collection_Target_T1: item.t1,
          Books_Collection_Target_T2: item.t2,
          Books_Collection_Target_T2T1: item.t2_T1,
          Books_Collection_Target_T3: item.t3,
          Books_Collection_Target_T3T2: item.t3_T2,
          Books_Collection_Target_T4: item.t4,
          Books_Collection_Target_T4T3: item.t4_T3,
          Books_Collection_Target_T5: item.t5,
          Books_Collection_Target_T5T4: item.t5_T4,
          Books_Collection_Target_T6: item.t6,
          Books_Collection_Target_T6T5: item.t6_T5,
          Payout_Rate_P1: item.r1,
          Payout_Rate_P2: item.r2,
          Payout_Rate_P3: item.r3,
          Payout_Rate_P4: item.r4,
          Payout_Rate_P5: item.r5,
          Payout_Rate_P6: item.r6,
           REMARKS: item.reason||"",
           HOLDREMARKS: item.holdReason ?? "",
          Payout_R1: item.p1,
          Payout_R2: item.p2,
          Payout_R3: item.p3,
          Payout_R4: item.p4,
          Payout_R5: item.p5,
          Payout_R6: item.p6,
          TEPvol: item.volume,
          TEPpertr: item.perTr,
          TEPamount: item.amount,
          ACTUAL_BILLING_AMOUNT: Math?.round(item.actualBillingAmount) || "0",
          ACTUAL_BILLING_QTY: Math?.round(item.actualBillingOty) || "0",
          BILLING_FACTOR: (Number.isFinite(item?.actualBillingAmount / item?.actualBillingQty) 
          ? Math.round(item?.actualBillingAmount / item?.actualBillingQty) 
          : 0),
          ACTUAL_COLLECTION: Math.round(item?.actualCollection),
          COLLECTION_QUANTITY: Math.round(item?.collectionQuantity),
          achievedSlab: item.achievedSlab || "No Slab",
          Payout_P1: item.payout,
          Payout_P2: item.payout2,
          Payout_P3: item.payout3,
          Payout_P4: item.payout4,
        })
      })
      if (data?.length == 0) {
        ToastifyShow("Something went wrong", "error")
        return false
      }



      let tab_text =(userRole == "PMIS" || userRole == "NSH" || userRole == "Finance" || userRole == "COSM"||userRole == "AMGR" || userRole == "ZADM" || userRole == "SADM" ) ? `<table>
        <thead>
        <tr>
            <th rowspan="2">SCHEME NO</th>
            <th rowspan="2">DEALER CODE</th>
            <th rowspan="2">DEALER LOCATION</th>
            <th rowspan="2">DEALER NAME</th>
            <th rowspan="2">STATE</th>
            <th rowspan="2">TERRITORY MANAGER</th>
            <th rowspan="2">START MONTH</th>
            <th rowspan="2">END MONTH</th>
            <th rowspan="2">Industry</th>
            <th rowspan="2">B</th>
            <th rowspan="2">I</th>
            <th rowspan="2">C</th>
     <th colspan="2">OPENING STOCK</th>
        <th colspan="4">CLOSING OS.(IN LACS)</th>
            <th>BG</th>
            <th>INSTALLATION PLAN</th>
            <th colspan="3">BILLING DETAILS</th>
            <th rowspan="2">CLOSING STOCK</th>
            <th rowspan="2">CLOSING OUTSTANDING (IN LACS)</th>
            <th colspan="11">BOOKING COLLECTION TARGETS(NO. OF TRS)</th>
            <th colspan="6">PAYOUT RATE</th>
            <th rowspan="2">REMARKS</th>
             <th rowspan="2">HOLD REMARKS</th>
            <th colspan="6">PAYOUT</th>
            <th colspan="3">TOTAL EXPECTED PAYOUT</th>
            <th rowspan="2">Actual Billing Amount</th>
            <th rowspan="2">Actual Billing Quantity</th>
            <th rowspan="2">Billing Factor</th>
            <th rowspan="2">Actual Collection</th>
            <th rowspan="2">Collection Quantity</th>
            <th rowspan="2">Achieved Slab</th>
            <th rowspan="2">PAYOUT 1</th>
            <th rowspan="2">PAYOUT 2</th>
            <th rowspan="2">PAYOUT 3</th>
            <th rowspan="2">PAYOUT 4</th>
            </tr>
        <tr>
           <th>NOS</th>
           <th>Days</th>
           <th>LAST 3rd MONTH</th>
           <th>LAST 2nd MONTH</th>
           <th>LAST 1st MONTH</th>
             <th>LAST 1st MONTH DAYS</th>
           <th>LACS</th>
           <th>NOS</th>
           <th>B</th>
           <th>I</th>
           <th>C</th>
           <th>T1</th>
           <th>T2</th>
           <th>T2-T1</th>
           <th>T3</th>
           <th>T3-T2</th>
           <th>T4</th>
           <th>T4-T3</th>
           <th>T5</th>
           <th>T4-T5</th>
           <th>T6</th>
           <th>T6-T5</th>
           <th>R1</th>
           <th>R2</th>
           <th>R3</th>
           <th>R4</th>
           <th>R5</th>
           <th>R6</th>
           <th>P1</th>
           <th>P2</th>
           <th>P3</th>  
           <th>P4</th>
           <th>P5</th>
           <th>P6</th>  
           <th>Expected Volume</th>
           <th>PER TR.</th>
           <th>AMOUNT</th>  
          </tr>
        </thead>`:
        `<table>
        <thead>
        <tr>
            <th rowspan="2">SCHEME NO</th>
            <th rowspan="2">DEALER CODE</th>
            <th rowspan="2">DEALER LOCATION</th>
            <th rowspan="2">DEALER NAME</th>
            <th rowspan="2">STATE</th>
            <th rowspan="2">TERRITORY MANAGER</th>
            <th rowspan="2">START MONTH</th>
            <th rowspan="2">END MONTH</th>
            <th rowspan="2">Industry</th>
            <th rowspan="2">B</th>
            <th rowspan="2">I</th>
            <th rowspan="2">C</th>
     <th colspan="2">OPENING STOCK</th>
        <th colspan="4">CLOSING OS.(IN LACS)</th>
            <th>BG</th>
            <th>INSTALLATION PLAN</th>
            <th colspan="3">BILLING DETAILS</th>
            <th rowspan="2">CLOSING STOCK</th>
            <th rowspan="2">CLOSING OUTSTANDING (IN LACS)</th>
            <th colspan="11">BOOKING COLLECTION TARGETS(NO. OF TRS)</th>
            <th colspan="6">PAYOUT RATE</th>
            <th rowspan="2">REMARKS</th>
            <th rowspan="2">HOLD REMARKS</th>
            <th colspan="6">PAYOUT</th>
            <th colspan="3">TOTAL EXPECTED PAYOUT</th>
            </tr>
        <tr>
           <th>NOS</th>
           <th>Days</th>
           <th>LAST 3rd MONTH</th>
           <th>LAST 2nd MONTH</th>
           <th>LAST 1st MONTH</th>
         <th>LAST 1st MONTH DAYS</th>
           <th>LACS</th>
           <th>NOS</th>
           <th>B</th>
           <th>I</th>
           <th>C</th>
           <th>T1</th>
           <th>T2</th>
           <th>T2-T1</th>
           <th>T3</th>
           <th>T3-T2</th>
           <th>T4</th>
           <th>T4-T3</th>
           <th>T5</th>
           <th>T4-T5</th>
           <th>T6</th>
           <th>T6-T5</th>
           <th>R1</th>
           <th>R2</th>
           <th>R3</th>
           <th>R4</th>
           <th>R5</th>
           <th>R6</th>
           <th>P1</th>
           <th>P2</th>
           <th>P3</th>  
           <th>P4</th>
           <th>P5</th>
           <th>P6</th>  
           <th>Expected Volume</th>
           <th>PER TR.</th>
           <th>AMOUNT</th>  
          </tr>
        </thead>`;
      if (userRole == "PMIS" || userRole == "NSH" || userRole == "Finance" || userRole == "COSM"||userRole == "AMGR" || userRole == "ZADM" || userRole == "SADM" ) {
        dataSource?.forEach((x) => {
          tab_text += `<tr ><td>${x?.scheme?.schemeNo}</td>
      <td>${x?.scheme?.dealerCode}</td>
      <td>${x?.scheme?.dealerLocation || ""}</td>
      <td>${x?.scheme?.dealerName || ""}</td>
      <td>${x?.scheme?.stateName}</td>
      <td>${x?.scheme?.territoryManager}</td>
      <td>${moment(x?.scheme?.startMonth).format("MMM-YY") || ""}</td>
      <td>${moment(x?.scheme?.endMonth).format("MMM-YY") || ""}</td>
      <td>${x?.scheme?.industry}</td>
      <td>${x?.scheme?.oldBillingQty}</td>
      <td>${x?.scheme?.oldInstallation}</td>
      <td>${x?.scheme?.oldCollection || 0}</td>
      <td>${x?.scheme?.oldOpeningStock}</td>
      <td>${x?.scheme?.openingStockDays}</td>
      <td>${x?.scheme?.closingOutStandingMonth_3}</td>
      <td>${x?.scheme?.closingOutStandingMonth_2}</td>
      <td>${x?.scheme?.closingOutStandingMonth_1}</td>
      <td>${x?.scheme?.outStandingDays}</td>
      <td>${x?.scheme?.bGLacsCurrentYear || x?.scheme?.bgLacsCurrentYear || 0}</td>
      <td>${x?.scheme?.delayPlan}</td>
      <td>${x?.scheme?.billing}</td>
      <td>${x?.scheme?.installation}</td>
      <td>${x?.scheme?.collection}</td>
      <td>${x?.scheme?.closingStock}</td>
      <td>${x?.scheme?.closingOutStanding}</td>
      <td>${x?.scheme?.t1}</td>
      <td>${x?.scheme?.t2}</td>
      <td>${ x?.scheme?.t2_T1 && x?.scheme?.topTarget?.includes("t2")?(x?.scheme?.t2_T1) :0}</td>
      <td>${x?.scheme?.t3}</td>
      <td>${x?.scheme?.t3_T2 && x?.scheme?.topTarget?.includes("t3")?(x?.scheme?.t3_T2) :0}</td>
      <td>${x?.scheme?.t4}</td>
      <td>${ x?.scheme?.t4_T3 && x?.scheme?.topTarget?.includes("t4") ? (x?.scheme?.t4_T3)  :0}</td>
      <td>${x?.scheme?.t5}</td>
      <td>${x?.scheme?.t5_T4 && x?.scheme?.topTarget?.includes("t5") ? (x?.scheme?.t5_T4)  :0}</td>
      <td>${x?.scheme?.t6}</td>
      <td>${x?.scheme?.t6_T5 && x?.scheme?.topTarget?.includes("t6") ? (x?.scheme?.t6_T5)  :0}</td>
      <td>${x?.scheme?.r1}</td>
      <td>${x?.scheme?.r2}</td>
      <td>${x?.scheme?.r3}</td>
      <td>${x?.scheme?.r4}</td>
      <td>${x?.scheme?.r5}</td>
      <td>${x?.scheme?.r6}</td>
      <td>${x?.scheme?.reason||''}</td>
      <td>${x?.scheme?.holdReason}</td>
      <td>${x?.scheme?.p1}</td>
      <td>${x?.scheme?.p2}</td>
      <td>${x?.scheme?.p3}</td>
      <td>${x?.scheme?.p4}</td>
      <td>${x?.scheme?.p5}</td>
      <td>${x?.scheme?.p6}</td>
      <td>${x?.scheme?.volume}</td>
      <td>${x?.scheme?.perTr}</td>
      <td>${x?.scheme?.amount}</td>
      <td>${Math?.round(x?.scheme?.actualBillingAmount)}</td>
      <td>${Math.round(x?.scheme?.actualBillingOty)}</td>
      <td>${TextLocalString(x?.scheme.billingFactor)}</td>
      <td>${Math.round(x?.scheme?.actualCollection)}</td>
      <td>${Math.round(x?.scheme?.collectionQuantity)}</td>
      <td>${x?.scheme?.achievedSlab || "No Slab"}</td>
      <td>${x?.scheme?.payout}</td>
      <td>${x?.scheme?.payout2}</td>
      <td>${x?.scheme?.payout3}</td>
      <td>${x?.scheme?.payout4}</td>
      </tr>`;
        });

        // <td>${Math?.round(totalValues?.actualBillingAmount)}</td>
        // <td>${Math.round(totalValues?.actualBillingQuantity)}</td>
        // <td>${Math.round(totalValues?.billingFactor) || 0}</td>
        // <td>${Math.round(totalValues?.actualCollection)}</td>
        // <td>${Math.round(totalValues?.collectionQuantity)}</td>

      } else {
        dataSource?.forEach((x) => {

          tab_text += `<tr ><td>${x?.scheme?.schemeNo}</td>
      <td>${x?.scheme?.dealerCode}</td>
      <td>${x?.scheme?.dealerLocation}</td>
      <td>${x?.scheme?.dealerName}</td>
      <td>${x?.scheme?.stateName}</td>
      <td>${x?.scheme?.territoryManager}</td>
      <td>${moment(x?.scheme?.startMonth).format("MMM-YY") || ""}</td>
      <td>${moment(x?.scheme?.endMonth).format("MMM-YY") || ""}</td>
      <td>${x?.scheme?.industry}</td>
      <td>${x?.scheme?.oldBillingQty}</td>
      <td>${x?.scheme?.oldInstallation}</td>
      <td>${x?.scheme?.oldCollection}</td>
      <td>${x?.scheme?.oldOpeningStock}</td>
      <td>${x?.scheme?.openingStockDays}</td>
      <td>${x?.scheme?.closingOutStandingMonth_3}</td>
      <td>${x?.scheme?.closingOutStandingMonth_2}</td>
      <td>${x?.scheme?.closingOutStandingMonth_1}</td>
      <td>${x?.scheme?.outStandingDays}</td>
      <td>${x?.scheme?.bGLacsCurrentYear}</td>
      <td>${x?.scheme?.delayPlan}</td>
      <td>${x?.scheme?.billing}</td>
      <td>${x?.scheme?.installation}</td>
      <td>${x?.scheme?.collection}</td>
      <td>${x?.scheme?.closingStock}</td>
      <td>${x?.scheme?.closingOutStanding}</td>
      <td>${x?.scheme?.t1}</td>
      <td>${x?.scheme?.t2}</td>
      <td>${ x?.scheme?.t2_T1 && x?.scheme?.topTarget?.includes("t2")?(x?.scheme?.t2_T1) :0}</td>
      <td>${x?.scheme?.t3}</td>
      <td>${x?.scheme?.t3_T2 && x?.scheme?.topTarget?.includes("t3")?(x?.scheme?.t3_T2) :0}</td>
      <td>${x?.scheme?.t4}</td>
      <td>${ x?.scheme?.t4_T3 && x?.scheme?.topTarget?.includes("t4") ? (x?.scheme?.t4_T3)  :0}</td>
      <td>${x?.scheme?.t5}</td>
      <td>${x?.scheme?.t5_T4 && x?.scheme?.topTarget?.includes("t5") ? (x?.scheme?.t5_T4)  :0}</td>
      <td>${x?.scheme?.t6}</td>
      <td>${x?.scheme?.t6_T5 && x?.scheme?.topTarget?.includes("t6") ? (x?.scheme?.t6_T5)  :0}</td>
      <td>${x?.scheme?.r1}</td>
      <td>${x?.scheme?.r2}</td>
      <td>${x?.scheme?.r3}</td>
      <td>${x?.scheme?.r4}</td>
      <td>${x?.scheme?.r5}</td>
      <td>${x?.scheme?.r6}</td>
      <td>${x?.scheme?.reason||''}</td>
      <td>${x?.scheme?.holdReason}</td>
      <td>${x?.scheme?.p1}</td>
      <td>${x?.scheme?.p2}</td>
      <td>${x?.scheme?.p3}</td>
      <td>${x?.scheme?.p4}</td>
      <td>${x?.scheme?.p5}</td>
      <td>${x?.scheme?.p6}</td>
      <td>${x?.scheme?.volume}</td>
      <td>${x?.scheme?.perTr}</td>
      <td>${x?.scheme?.amount}</td>
      </tr>`;
        });
      }

      const totals = {
        delayPlan: 0,
        billing: 0,
        installation: 0,
        collection: 0,
        closingStock:0,
        closingOutStanding:0,
        t1: 0,
        t2: 0,
        t2_T1: 0,
        t3: 0,
        t3_T2: 0,
        t4: 0,
        t4_T3: 0,
        t5: 0,
        t5_T4: 0,
        t6: 0,
        t6_T5: 0,
        r1: 0,
        r2: 0,
        r3: 0,
        r4: 0,
        r5: 0,
        r6: 0,
        reason: "",
        holdReason:"",
        p1: 0,
        p2: 0,
        p3: 0,
        p4: 0,
        p5: 0,
        p6: 0,
        volume: 0,
        perTr: 0,
        amount: 0,
        actualBillingAmount: 0,
        actualBillingQuantity: 0,
        billingFactor: 0,
        actualCollection: 0,
        collectionQuantity: 0,
        achievedSlab: "",
        payout: 0,
        payout2: 0,
        payout3: 0,
        payout4: 0
      };
      const total1 = {
        delayPlan: 0,
        billing: 0,
        installation: 0,
        collection: 0,
        closingStock:0,
        closingOutStanding:0,
        t1: 0,
        t2: 0,
        t2_T1: 0,
        t3: 0,
        t3_T2: 0,
        t4: 0,
        t4_T3: 0,
        t5: 0,
        t5_T4: 0,
        t6: 0,
        t6_T5: 0,
        r1: 0,
        r2: 0,
        r3: 0,
        r4: 0,
        r5: 0,
        r6: 0,
        reason: "",
        holdReason:"",
        p1: 0,
        p2: 0,
        p3: 0,
        p4: 0,
        p5: 0,
        p6: 0,
        volume: 0,
        perTr: 0,
        amount: 0,
      }

      let selectedTotals;
      if (userRole == 'ZADM') {
        selectedTotals = total1;
      }
      else {
        selectedTotals = totals;
      } 
    

      dataSource.forEach((item) => {
        for (let key in selectedTotals) {
          if (key === "t2_T1" && item.scheme.topTarget.includes("t2")) {
            selectedTotals[key] += parseInt(item.scheme[key]) || 0;
          } else if (key === "t3_T2" && item.scheme.topTarget.includes("t3")) {
            selectedTotals[key] += parseInt(item.scheme[key]) || 0;
          } else if (key === "t4_T3" && item.scheme.topTarget.includes("t4")) {
            selectedTotals[key] += parseInt(item.scheme[key]) || 0;
          } else if (key === "t5_T4" && item.scheme.topTarget.includes("t5")) {
            selectedTotals[key] += parseInt(item.scheme[key]) || 0;
          } else if (key === "t6_T5" && item.scheme.topTarget.includes("t6")) {
            selectedTotals[key] += parseInt(item.scheme[key]) || 0;
          } else if (!["t2_T1", "t3_T2", "t4_T3", "t5_T4", "t6_T5"].includes(key)) {
            selectedTotals[key] += parseInt(item.scheme[key]) || 0;
          }
        }
      });

      selectedTotals.r1 = selectedTotals.t1 !== 0 ? Math.round(selectedTotals.p1 / selectedTotals.t1) : 0;
      selectedTotals.r2 = selectedTotals.t2 !== 0 ? Math.round(selectedTotals.p2 / selectedTotals.t2) : 0;
      selectedTotals.r3 = selectedTotals.t3 !== 0 ? Math.round(selectedTotals.p3 / selectedTotals.t3) : 0;
      selectedTotals.r4 = selectedTotals.t4 !== 0 ? Math.round(selectedTotals.p4 / selectedTotals.t4) : 0;
      selectedTotals.r5 = selectedTotals.t5 !== 0 ? Math.round(selectedTotals.p5 / selectedTotals.t5) : 0;
      selectedTotals.r6 = selectedTotals.t6 !== 0 ? Math.round(selectedTotals.p6 / selectedTotals.t6) : 0;
      selectedTotals.perTr = selectedTotals.volume !== 0 ? Math.round(selectedTotals.amount / selectedTotals.volume) : 0;
      if(userRole != 'ZADM'){
        selectedTotals.billingFactor = selectedTotals.actualBillingQuantity !== 0 ? Math.round(selectedTotals.actualBillingAmount / selectedTotals.actualBillingQuantity) : 0
      }
      const totalValues = dataSource
      ?.filter(data => data?.scheme?.status == 1)
      .reduce((acc, curr) => {
        acc.billingFactor += curr.billingFactor || 0;
        acc.actualCollection += curr.actualCollection || 0;
        acc.collectionQuantity+=curr.collectionQuantity||0;
        acc.actualBillingQuantity+=curr.actualBillingQuantity||0;
        acc.actualBillingAmount+=curr.actualBillingAmount||0;
        return acc;
      }, { billingFactor: 0, actualCollection: 0,collectionQuantity:0,actualBillingQuantity:0,actualBillingAmount:0 });


      const fieldSum = dataSource.reduce(
        (acc, { payoutR1,BooksCollectionTargetT1,BooksCollectionTargetT2,BooksCollectionTargetT3,BooksCollectionTargetT4,BooksCollectionTargetT5,BooksCollectionTargetT6, payoutR2, payoutR3, payoutR4, payoutR5, payoutR6, scheme,
          BooksCollectionTargetT2T1,BooksCollectionTargetT3T2,BooksCollectionTargetT4T3,BooksCollectionTargetT5T4,BooksCollectionTargetT6T5


         }) => {
          acc.totalR1 += payoutR1 && scheme?.topTarget?.includes("t1") ? parseInt(payoutR1 || 0) : 0;
          acc.totalR2 += payoutR2 && scheme?.topTarget?.includes("t2") ? parseInt(payoutR2 || 0) : 0;
          acc.totalR3 += payoutR3 && scheme?.topTarget?.includes("t3") ? parseInt(payoutR3 || 0) : 0;
          acc.totalR4 += payoutR4 && scheme?.topTarget?.includes("t4") ? parseInt(payoutR4 || 0) : 0;
          acc.totalR5 += payoutR5 && scheme?.topTarget?.includes("t5") ? parseInt(payoutR5 || 0) : 0;
          acc.totalR6 += payoutR6 && scheme?.topTarget?.includes("t6") ? parseInt(payoutR6 || 0) : 0;
          acc.t1 += BooksCollectionTargetT1 && scheme?.topTarget?.includes("t1") ? parseInt(BooksCollectionTargetT1 || 0) : 0;
          acc.t2 += BooksCollectionTargetT2 && scheme?.topTarget?.includes("t2") ? parseInt(BooksCollectionTargetT2 || 0) : 0;
          acc.t3 += BooksCollectionTargetT3 && scheme?.topTarget?.includes("t3") ? parseInt(BooksCollectionTargetT3 || 0) : 0;
          acc.t4 += BooksCollectionTargetT4 && scheme?.topTarget?.includes("t4") ? parseInt(BooksCollectionTargetT4 || 0) : 0;
          acc.t5 += BooksCollectionTargetT5 && scheme?.topTarget?.includes("t5") ? parseInt(BooksCollectionTargetT5 || 0) : 0;
          acc.t6 += BooksCollectionTargetT6 && scheme?.topTarget?.includes("t6") ? parseInt(BooksCollectionTargetT6 || 0) : 0;

          acc.totalT1 += BooksCollectionTargetT1 && scheme?.topTarget?.includes("t1") ? parseInt(BooksCollectionTargetT1 || 0) : 0;
          acc.totalT2 += BooksCollectionTargetT2 && scheme?.topTarget?.includes("t2") ? parseInt(BooksCollectionTargetT2 || 0) : 0;
          acc.totalT3 += BooksCollectionTargetT3 && scheme?.topTarget?.includes("t3") ? parseInt(BooksCollectionTargetT3 || 0) : 0;
          acc.totalT4 += BooksCollectionTargetT4 && scheme?.topTarget?.includes("t4") ? parseInt(BooksCollectionTargetT4 || 0) : 0;
          acc.totalT5 += BooksCollectionTargetT5 && scheme?.topTarget?.includes("t5") ? parseInt(BooksCollectionTargetT5 || 0) : 0;
          acc.totalT6 += BooksCollectionTargetT6 && scheme?.topTarget?.includes("t6") ? parseInt(BooksCollectionTargetT6 || 0) : 0;
          acc.T2_T1 +=
                BooksCollectionTargetT2T1 && scheme?.topTarget?.includes("t2")
                  ? parseInt(BooksCollectionTargetT2T1 || 0)
                  : 0;
                  acc.T3_T2 +=
                BooksCollectionTargetT3T2 && scheme?.topTarget?.includes("t3")
                  ? parseInt(BooksCollectionTargetT3T2 || 0)
                  : 0;
                  acc.T4_T3 +=
                BooksCollectionTargetT4T3 && scheme?.topTarget?.includes("t4")
                  ? parseInt(BooksCollectionTargetT4T3 || 0)
                  : 0;
                  acc.T5_T4 +=
                BooksCollectionTargetT5T4 && scheme?.topTarget?.includes("t5")
                  ? parseInt(BooksCollectionTargetT5T4 || 0)
                  : 0;
                  acc.T6_T5 +=
                BooksCollectionTargetT6T5 && scheme?.topTarget?.includes("t6")
                  ? parseInt(BooksCollectionTargetT6T5 || 0)
                  : 0;
          return acc;
        },
        { totalR1: 0, totalR2: 0, totalR3: 0, totalR4: 0, totalR5: 0, totalR6: 0,totalT1:0,totalT2:0,totalT3:0,totalT4:0,totalT5:0,totalT6:0,
          t1:0,t2:0,t3:0,t4:0,t5:0,t6:0,T2_T1:0,T3_T2:0,T4_T3:0,T5_T4:0,T6_T5:0
         }
      );
      const totalp1=Math.round(fieldSum?.totalR1/(fieldSum.totalT1||1))
      const totalp2=Math.round(fieldSum?.totalR2/(fieldSum.totalT2||1))
      const totalp3=Math.round(fieldSum?.totalR3/(fieldSum.totalT3||1))
      const totalp4=Math.round(fieldSum?.totalR4/(fieldSum.totalT4||1))
      const totalp5=Math.round(fieldSum?.totalR5/(fieldSum.totalT5||1))
      const totalp6=Math.round(fieldSum?.totalR6/(fieldSum.totalT6||1))

      let totalsRow = "<tr><td colspan='19' >Total</td>";
      for (let key in selectedTotals) {

        if (key != 'reason' && key != 'closingStock' && key !='closingOutStanding' && key != 'holdReason' && key != 'achievedSlab') {
          if(key=="achievedSlab"||key=="reason"){
            totalsRow += `<td></td>`;
          }else  if(key=="t1"){
            totalsRow += `<td>${fieldSum?.t1}</td>`;
          }else  if(key=="t2"){
            totalsRow += `<td>${fieldSum?.t2}</td>`;
          }else  if(key=="t3"){
            totalsRow += `<td>${fieldSum?.t3}</td>`;
          }else  if(key=="t4"){
            totalsRow += `<td>${fieldSum?.t4}</td>`;
          }else  if(key=="t5"){
            totalsRow += `<td>${fieldSum?.t5}</td>`;
          }else if(key=="t6"){
            totalsRow += `<td>${fieldSum?.t6}</td>`;
          }else  if(key=="t2_T1"){
            totalsRow += `<td>${fieldSum?.T2_T1}</td>`;
          }else  if(key=="t3_T2"){
            totalsRow += `<td>${fieldSum?.T3_T2}</td>`;
          }else  if(key=="t4_T3"){
            totalsRow += `<td>${fieldSum?.T4_T3}</td>`;
          }else  if(key=="t5_T4"){
            totalsRow += `<td>${fieldSum?.T5_T4}</td>`;
          }else if(key=="t6_T5"){
            totalsRow += `<td>${fieldSum?.T6_T5}</td>`;
          }else  if(key=="r1"){
            totalsRow += `<td>${totalp1}</td>`;
          }else  if(key=="r2"){
            totalsRow += `<td>${totalp2}</td>`;
          }else  if(key=="r3"){
            totalsRow += `<td>${totalp3}</td>`;
          }else  if(key=="r4"){
            totalsRow += `<td>${totalp4}</td>`;
          }else  if(key=="r5"){
            totalsRow += `<td>${totalp5}</td>`;
          }else if(key=="r6"){
            totalsRow += `<td>${totalp6}</td>`;
          }else  if(key=="p1"){
            totalsRow += `<td>${fieldSum?.totalR1}</td>`;
          }else  if(key=="p2"){
            totalsRow += `<td>${fieldSum?.totalR2}</td>`;
          }else  if(key=="p3"){
            totalsRow += `<td>${fieldSum?.totalR3}</td>`;
          }else  if(key=="p4"){
            totalsRow += `<td>${fieldSum?.totalR4}</td>`;
          }else  if(key=="p5"){
            totalsRow += `<td>${fieldSum?.totalR5}</td>`;
          }else if(key=="p6"){
            totalsRow += `<td>${fieldSum?.totalR6}</td>`;
          }else if(key=="actualBillingAmount"){
            totalsRow += `<td>${totalValues?.actualBillingAmount}</td>`;
          }else if(key=="actualBillingQuantity"){
            totalsRow += `<td>${totalValues?.actualBillingQuantity}</td>`;
          }else if(key=="billingFactor"){
            totalsRow += `<td>${totalValues?.billingFactor}</td>`;
          }else if(key=="actualCollection"){
            totalsRow += `<td>${totalValues?.actualCollection}</td>`;
          }else if(key=="collectionQuantity"){
            totalsRow += `<td>${totalValues?.collectionQuantity}</td>`;
          }else if (key.startsWith('r') || key === 'perTr' || key === 'bill') {
            totalsRow += `<td>${selectedTotals[key]}</td>`;
          } else {
            totalsRow += `<td>${selectedTotals[key]}</td>`;
          }
        } else {
          totalsRow += `<td></td>`;
        }
      }

      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";
      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);

      // Apply styles to the table using a class
      // elt.getElementsByClassName("custom-table")[0]?.style.width = "100%";

      // generate workbook with XLSXStyle
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      // Add style to Roral row
      const allColumns = dataSource.reduce((columns, item) => {
        Object.keys(item).forEach(key => {
          if (!columns.includes(key)) {
            columns.push(key);
          }
        });
        return columns;
      }, []);

      function getAlphabetEquivalent(number) {

        if (number < 1) {
          return "Invalid input";
        } else if (number <= 26) {
          return String.fromCharCode(number + 64);
        } else {
          let firstChar = String.fromCharCode(((number - 1) / 26) + 64);
          let secondChar = String.fromCharCode(((number - 1) % 26) + 65);
          return firstChar + secondChar;
        }

      }

      let excelArr = []
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(dataSource?.length + 3)?.toString()}`
        excelArr.push(ele||0);

      }
      Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
        excelArr
          .includes(cell)).forEach(cell => {
            wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
          });

      // Dynamically set column widths
      wb.Sheets[wb.SheetNames[0]]['!cols'] = [
        { wch: 25 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 25 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 25 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 25 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 25 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 25 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 25 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 25 },
        { wch: 20 },
        { wch: 20 },
        { wch: 25 },
        { wch: 20 },
        { wch: 25 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];


      // var elt = document.createElement("div");
      // elt.innerHTML = tab_text;
      // document.body.appendChild(elt);
      // var tbl = elt.getElementsByTagName("TABLE")[0];
      // var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);
      let nameFile = selectedSchemeType == 1 ? "With_DeliveryPlan.xlsx" : "Without_Delivery_Plan.xlsx"
      // generate file
      XLSX.writeFile(wb, nameFile);
    }
  }
  return (
    <div id="table" className="onemoth-date-selector1">
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h2 style={{ fontSize: "18px", padding: "20px" }}>
            {getSchemeType(selectedSchemeType)}
          </h2>
          <div className="status-btn-col-box state-wise-filters">
            <div>
              <button
                className="action_button c-white mx-1"
                disabled={dataSource?.length == 0}
                onClick={handleDownload}
              >
                Download
              </button>
              <Status
                className="status-btn-box"
                selectedStatus={selectedStatus}
                handleStatusDropdown={(statusId) => setSelectedStatus(statusId)}
              ></Status>
             
                <ActionButton onGetData={fetchGeneratedScheme} />
              {/* )} */}
            </div>
            <div className="state-wise-monthly-filters">
              {(dashboardType === STATE_WISE_DASHBOARD_LABEL) ? (
                <MonthlyFilterComponent
                  startMonth={startMonth}
                  endMonth={endMonth}
                  enforcedMonth={enforcedMonth}
                  onMonthChange={handleMonthChange}
                  onMonthChange1={handleMonthChange1}
                  monthlyFilters={monthlyFilters}
                  dateValue={dateValue}

                />
              ) : null}
            </div>
          </div>

          {/* {userRole !== "AMGR" && <ActionButton onGetData={props.onGetData} />} */}
        </div>
        <span>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>

      {[1, 2].includes(selectedSchemeType) ? (
        <CollectionTable
          selectedStatus={selectedStatus}
          monthlyFilters={monthlyFilters}
          startMonth={startMonth}
          onSetCardData={props.onSetCardData}
          selectedSchemeType={selectedSchemeType}
          selectedStateId={props.selectedStateId}
          dashboardType={dashboardType}
          setDataSource={setDataSource}
          dataSource={dataSource}
          // yearShow={dayjs().year(dateValue)}
          yearShow={dateValue}
          fyear={dateValue}
        />
      ) : selectedSchemeType == 3 ? (
        <SlabBased
          selectedStatus={selectedStatus}
          monthlyFilters={monthlyFilters}
          onSetCardData={props.onSetCardData}
          selectedSchemeType={selectedSchemeType}
          selectedStateId={props.selectedStateId}
          dashboardType={dashboardType}
          setDataSource={setDataSource}
          dataSource={dataSource}
          // yearShow={dayjs().year(dateValue)}
          yearShow={dateValue} startMonth={startMonth}
        />
      ) : selectedSchemeType == 8 ? (
        <SingleTarget
          selectedStatus={selectedStatus}
          monthlyFilters={monthlyFilters}
          onSetCardData={props.onSetCardData}
          selectedSchemeType={selectedSchemeType}
          selectedStateId={props.selectedStateId}
          dashboardType={dashboardType}
          setDataSource={setDataSource}
          dataSource={dataSource}
          // yearShow={dayjs().year(dateValue)}
          yearShow={dateValue} startMonth={startMonth}
        />
      ) : selectedSchemeType == 4 ? (
        <SingleTargetWithUpperCap
          selectedStatus={selectedStatus}
          monthlyFilters={monthlyFilters}
          onSetCardData={props.onSetCardData}
          selectedSchemeType={selectedSchemeType}
          selectedStateId={props.selectedStateId}
          dashboardType={dashboardType}
          setDataSource={setDataSource}
          dataSource={dataSource}
          // yearShow={dayjs().year(dateValue)}
          yearShow={dateValue} startMonth={startMonth}
        />
      ) : (
        selectedSchemeType == 5 && (
          <UnconditionalInstallation
            selectedStatus={selectedStatus}
            monthlyFilters={monthlyFilters}
            onSetCardData={props.onSetCardData}
            selectedSchemeType={selectedSchemeType}
            selectedStateId={props.selectedStateId}
            dashboardType={dashboardType}
            setDataSource={setDataSource}
            dataSource={dataSource}
            // yearShow={dayjs().year(dateValue)}
            yearShow={dateValue} startMonth={startMonth}
          />
        )
      )}

      <div>
        <Row
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
          }}
        ></Row>
      </div>
    </div>
  );
};

const MonthlyFilterComponent = ({
  startMonth,
  endMonth,
  enforcedMonth,
  onMonthChange,
  onMonthChange1,
  dateValue,
  monthlyFilters
}) => {

  return (
    <><Tooltip title={"Running Month"}>
      <DatePicker
        onChange={(date) => onMonthChange("enforcedMonth", date)}
        className="start"
        placeholder="Running Month"
        picker="month"
        value={enforcedMonth}
        allowClear
        disabled = {monthlyFilters.month != 0  || monthlyFilters.monthEnd != 0 } 
      />
</Tooltip>
      <div className="month">
      <Tooltip title={"Start Month"}>
        <DatePicker
          onChange={(date) => onMonthChange("month", date || null)}
          className="start"
          placeholder="Start Month"
          picker="month"
          value={startMonth}
          disabledDate={(current) => {
            if (!startMonth || startMonth === 0) {
              const currentYear = dayjs()?.year(dateValue);
              const fiscalYearStartMonth = 3; // April
              const fiscalYearEndMonth = 2;   // March
              const isBeforeFiscalYearStart = current.month() < fiscalYearStartMonth;
              const isAfterFiscalYearEnd = current.month() > fiscalYearEndMonth;

              const isFiscalYear = (current.year() === currentYear.year()) && !isBeforeFiscalYearStart || (current.year() === currentYear.year() - 1) && !isAfterFiscalYearEnd;

              return isFiscalYear;
            }


            const today = new Date(startMonth) || new Date(dateValue);
            const currentYear = today.getFullYear() && today.getMonth() < 2 ? today.getFullYear() - 1 : today.getFullYear();
            const fiscalYearStartMonth = 3;

            return current && (
              (current.year() < currentYear || (current.year() === currentYear && current.month() < fiscalYearStartMonth)) ||
              (current.year() > currentYear + 1 || (current.year() === currentYear + 1 && current.month() >= fiscalYearStartMonth))
            );
          }}
          disabled = {monthlyFilters.enforcedMonth != 0  } 

        />
        </Tooltip>
        <Tooltip title={"End Month"}>
        <DatePicker
          onChange={(date) => onMonthChange1("monthEnd", date || null)}
          className="end"
          placeholder="End Month"
          picker="month"
          value={endMonth}
          disabledDate={(current) => {


            if (!endMonth || endMonth === 0) {
              const currentYear = dayjs()?.year(dateValue);
              const fiscalYearStartMonth = 3; // April
              const fiscalYearEndMonth = 2;   // March
              const isBeforeFiscalYearStart = current.month() < fiscalYearStartMonth;
              const isAfterFiscalYearEnd = current.month() > fiscalYearEndMonth;
              const isFiscalYear = (current.year() === currentYear.year()) && !isBeforeFiscalYearStart || (current.year() === currentYear.year() - 1) && !isAfterFiscalYearEnd;
              return isFiscalYear;
            }

            const today = new Date(endMonth) || new Date(dateValue);
            const currentYear = today.getFullYear() && today.getMonth() < 3 ? today.getFullYear() - 1 : today.getFullYear();
            const fiscalYearStartMonth = 3;
            return current && (
              (current.year() < currentYear || (current.year() === currentYear && current.month() < fiscalYearStartMonth)) ||
              (current.year() > currentYear + 1 || (current.year() === currentYear + 1 && current.month() >= fiscalYearStartMonth))
            );
          }}
          disabled = {monthlyFilters.enforcedMonth != 0  } 

        />
        </Tooltip>
      </div>
    </>
  );
};

export default ZonalTable;
