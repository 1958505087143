const ReportColorDetails = () => {
  return (
    <div className="All_scheme_status1">
      <div className="Schemes_status">
        <div
          className="Status_color"
          style={{ backgroundColor: "#ffecd2" }}
        ></div>
        {"<=90% of State"}
      </div>
      <div className="Schemes_status">
        <div
          className="Status_color"
          style={{ backgroundColor: "#ff9696" }}
        ></div>
        {">=110% of State"}
      </div>
    </div>
  );
};

export default ReportColorDetails;
